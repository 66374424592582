<template>
  <!-- -->
  <list-page class="defaultViewStyle">
    <page-header>
      <page-button el-type="primary" @click="resetSearch">查询</page-button>
      <!--        <page-button el-type="primary" @click="queryErpCustomer">同步erp客户</page-button>-->
      <page-button el-type="success" type="write" e @click="$routerUtil.push(`/customer/member/detail`)">新建
      </page-button>
    </page-header>
    <search-condition @search="resetSearch">
      <template #default>
        <el-input v-model="searchInfo.searchKey" placeholder="姓名/手机号"/>
      </template>
    </search-condition>
    <table-list>
      <el-table stripe :data="list" border size="small" height="100%">
        <table-setting page-name="customer/member">
        </table-setting>
        <el-table-column fixed="right" label="操作" :align="'center'" width="100px">
          <template v-slot="{row}">
            <table-button el-type="primary" :disabled="row.id === '10000'" @click="$routerUtil.push(`/customer/member/detail?id=${row.id}`)">编辑</table-button>
            <table-button el-type="danger" :disabled="row.id === '10000'" message="是否确认删除数据"
                          @click="toDelete(row)">删除
            </table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
               :total-count="searchInfo.totalCount" @onLoad="queryList"/>
  </list-page>
</template>

<script>
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import TableButton from "@/components/h5/button/TableButton";
import PageButton from "@/components/h5/button/PageButton";
import ListPage from "@/components/h5/layout/ListPage";
import memberApi from "@/api/main/customer/memberApi";
import DatePicker from "@/components/h5/date/DatePicker";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import {ElLoading} from "element-plus";
import TableSetting from "@/components/h5/column/TableSetting.vue";

export default {
  name: "",
  components: {
    SearchCondition, TableList, TableButton, FormPage, PageButton, ListPage, DatePicker, PageHeader, TableSetting
  },
  data() {
    return {
      list: [],
      searchInfo: {},
      showEditCustomer: false,
      editCustomer: {
        birthday: "",
        customerName: "",
        customerPhone: "",
        gender: "",
      },
      loading: null,
    };
  },
  created() {
  },
  methods: {
    queryErpCustomer() {
      this.loading = ElLoading.service({
        lock: true,
        text: `正在同步商品中,请耐心等待...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      memberApi.queryErpCustomer().then(() => {
        this.loading.close();
        this.resetSearch();
        this.$message.success("同步成功！");
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      memberApi.queryList(this.searchInfo).then(res => {
        this.list = this.handlerColumn(res.records);
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    // 使用列配置,需要处理数据信息
    handlerColumn(list){
      if(list.length === 0){
        return list;
      }
      for (let i = 0; i < list.length; i++) {
        // 性别
        list[i].gender = list[i].gender === 1 ? "男" : "女";
        // 联系电话
        list[i].customerPhone = list[i].customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
        // 出生日期
        list[i].birthday = list[i].birthday ? list[i].birthday.substring(0, 10) : '';
      }
      return list;
    },
    toDelete(data) {
      memberApi.delete(data.id).then(() => {
        this.queryList();
      })
    },
    toShowEditCustomer(data) {
      this.editCustomer = {...data};
      this.showEditCustomer = true;
    },
    toSaveCustomer() {
      memberApi.save(this.editCustomer).then(() => {
        this.queryList();
        this.showEditCustomer = false;
      })
    },
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-date-editor) {
  width: 100%;
}
</style>