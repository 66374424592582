import axios from "../../../util/axios";

export default {
    // 分类批量管理
    saveCategoryBatch: (ids, category) => axios.post({url: `/order/product/saveCategoryBatch?category=${category}`, data: ids}),
    // 批量上下架
    saveUpDown: (ids, status) => axios.post({url: `/order/product/saveUpDown?status=${status}`, data: ids}),
    /** 商品属性列表 */
    queryAttributeList: (merchantId) => axios.get({url: "/order/product/queryAttributeList", data: {merchantId}}),
    /** 查询属性分组*/
    queryGroups: () => axios.get({url: `/order/product/queryGroups`, data: {}}),
    /** 根据分组id查询属性,包括所有的下级*/
    queryAllAttributesByGroupId: (groupId) => axios.get({url: `/order/product/queryAllAttributesByGroupId`, data: {groupId}}),
    /** 商品列表 */
    queryList: (searchInfo) => axios.get({url: `/order/product/queryList`, data: searchInfo}),
    /** 商品详情 */
    findById: (id) => axios.get({url: `/order/product/findById?id=${id}`}),
    /** 保存商品 */
    save: (product) => axios.post({url: `/order/product/save`, data: product}),
    /** 删除商品 */
    delete: (id) => axios.del({url: `/order/product/delete?id=${id}`}),
    /** 删除字典*/
    deleteCategory: (id) => axios.del({url: "/order/product/deleteCategory", data: {id}}),
    /** 保存字典*/
    saveCategory: (data) => axios.post({url: "/order/product/saveCategory", data: data}),
    /** 删除一个sku的授权*/
    deleteGrantSku: (id, authorizeMerchantId, merchantId) => axios.del({url: "/order/product/deleteGrantSku", data: {id, authorizeMerchantId, merchantId}}),
    /** 导入商品列表，使用excel */
    uploadProductByTemplate: (data) => axios.post({url: "/order/product/uploadProductByTemplate", data: data}),
    /** 查询该商户可以使用的所有sku  */
    queryProductAuthorizeList: (searchInfo) => axios.get({url: `/order/product/queryProductAuthorizeList`, data: searchInfo}),
    /** 保存授权商品给部门 */
    saveGrant: (data) => axios.post({url: `/order/product/relateDepart/saveGrant`, data: data}),
    /** 批量删除授权商品 */
    deleteGrantBatch: (data) => axios.post({url: `/order/product/relateDepart/deleteGrantBatch`, data: data}),
    /** 批量修改授权商品 */
    updateGrantBatch: (data) => axios.post({url: `/order/product/relateDepart/updateGrantBatch`, data: data}),
    // 根据部门查询sku
    querySkuByCode: (category,stockId) => axios.get({url: `/order/product/querySkuByCode`, data: {category,stockId}}),
    // 根据部门查询商品
    queryProductByCode: (category,stockId) => axios.get({url: `/order/product/queryProductByCode`, data: {category,stockId}}),
    /** 保存商品属性 */
    saveAttribute: (attribute) => axios.post({url: `/order/product/saveAttribute`, data: attribute}),
    /** 删除商品属性 */
    deleteAttribute: (id) => axios.del({url: `/order/product/deleteAttribute?id=${id}`}),
    /** 保存商品sku */
    saveSku: (sku) => axios.post({url: `/order/product/saveSku`, data: sku}),
    /** 批量保存商品sku，只会新增不会删除 */
    saveSkuList: (list) => axios.post({url: `/order/product/saveSkuList`, data: list}),
    /** 删除商品sku */
    deleteSku: (id) => axios.del({url: `/order/product/deleteSku?id=${id}`}),
    /** 查询sku列表 */
    querySkuList: (productId) => axios.get({url: `/order/product/querySkuList`, data: {productId}}),
    /** 商品转换率 */
    queryUnitRates: (productId) => axios.get({url: `/order/product/queryUnitRates`, data: {productId}}),
    /** 保存商品转换率 */
    saveUnitRate: (data) => axios.post({url: `/order/product/saveUnitRate`, data: data}),
    /** 删除转换率 */
    deleteUnitRate: (id) => axios.del({url: `/order/product/deleteUnitRate?id=${id}`}),
    /** 查询单位 */
    queryUnits: (type = null) => axios.get({url: `/order/product/queryUnits`, data: {type}}),
    /** 保存单位 */
    saveUnit: (data) => axios.post({url: `/order/product/saveUnit`, data: data}),
    /** 删除单位 */
    deleteUnit: (id) => axios.del({url: `/order/product/deleteUnit?id=${id}`}),
    /** 按模板添加属性*/
    addAttributeByGroupModule: (param) => axios.post({url: "/order/product/addAttributeByGroupModule", data: param}),
    /** 同步商品信息*/
    syncProductFromThird: (data) => axios.post({url: "/order/product/syncProductFromThird", data: data}),
    /** 同步erp商品信息*/
    queryErpProductSync: (data) => axios.post({url: "/order/product/queryErpProductSync", data: data}),
    // 查询商品商品详情
    findByMtInfo: (skuId, enabledOriginal) => axios.get({url: `/order/product/findByMtInfo`, data: {skuId, enabledOriginal}}),
    // 保存附件
    saveFile: (data,productId) => axios.post({url: `/order/product/toSaveFile?productId=${productId}`, data: data}),
    // 查询附件信息
    queryAttachmentFile: (productId) => axios.get({url: `/order/product/queryAttachmentFile`, data: {productId}})

}