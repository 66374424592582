<template>
  <!-- -->
  <div class="defaultViewStyle">
<!--    <page-header/>-->
    <el-tabs v-model="activeName">
      <el-tab-pane name="test1" label="服务商品" lazy>
        <product-inventory :stock-type="1" :stock-id="stockId"/>
      </el-tab-pane>
      <el-tab-pane name="test2" label="组合商品" lazy>
        <product-inventory :stock-type="2" :stock-id="stockId"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PageHeader from "@/components/h5/layout/PageHeader";
import ProductInventory from "@/views/main/stock/productInventory/ProductInventory.vue";
import {watch} from "vue";

export default {
  props: {
    stockId: {default: ""},
  },
  name: "RestockType",
  components: {
    ProductInventory, PageHeader
  },
  data() {
    return {
      activeName: "test1",
    };
  },
  watch: {},
  created() {
    watch(() => this.stockId, () => {
      debugger;
      console.log("111111111111111");
    })
  },

  methods: {}
};
</script>

<style lang="scss" scoped>

</style>