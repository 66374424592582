import axios from "axios";
import util from "./util";

import {ElMessage} from 'element-plus';
import router from '../router';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 请求超时时间
axios.defaults.timeout = 1800000;
let cancel = null;
let progressInt = 0;

function startProgress() {
    // 0.1秒以内不显示进度条
    progressInt++;
    setTimeout(() => {
        if (progressInt > 0) {
            NProgress.start();
        }
    }, 100);

}

function stopProgress() {
    progressInt--;
    NProgress.done();
}

// axios请求拦截器
axios.interceptors.request.use(
    config => {
        let header = {};
        startProgress();
        if (util.findSessionHeader()) {
            header = util.findSessionHeader();
        } else {
            header = util.findCookieHeader();
        }
        if (header.token) {
            config.headers.token = header.token;
            config.headers.systemCode = header.systemCode;
            config.headers.merchantUuid = header.merchantUuid;
        }
        if (header.grantedToken) {
            config.headers.grantedToken = header.grantedToken;
        }
        config.cancelToken = new axios.CancelToken(function (c) {
            cancel = c;
        });
        return config;
    },
    error => {

        return Promise.reject(error);
    }
);

// axios响应拦截器
axios.interceptors.response.use(
    res => {
        stopProgress();
        // 过滤的白名单
        if (res.config.url.indexOf('/api') !== -1) {
            return Promise.resolve(res);
        } else if (!res.config.url.startsWith(process.env.VUE_APP_BASE_URL)) {
            // 访问其它网站
            return Promise.resolve(res);
        } else {
            // 如果是导出
            if (res.config.responseType === "blob") {
                return Promise.resolve(res.data);
            }
            if (res.data.code === '2000') {
                let uri = res.config.url.split("/");
                let lastPath = uri[uri.length - 1];
                // 操作成功
                if (lastPath.endsWith("save") || lastPath.endsWith("Save") || lastPath.startsWith("save")) {
                    ElMessage.success({
                        message: '保存成功',
                        type: 'success',
                        duration: 2000,
                    });
                }
                if (lastPath.endsWith("delete") || lastPath.endsWith("Delete") || lastPath.startsWith("delete")) {
                    ElMessage.success({
                        message: '删除成功',
                        type: 'success',
                        duration: 2000,
                    });
                }
                return Promise.resolve(res.data);
            }
            if (!res || res.data.code !== '2000') {
                cancel && cancel()
                if (res.data.code === '3000') {
                    // 未登录或已过期，重新登录
                    let context = util.getContextPath();
                    // 清空登录信息
                    util.clearSession();
                    if (context === 4) {
                        // pda
                        router.push("/pda/login");
                    } else if (context === 2 || context === 3) {
                        let origin = window.location.origin + "/mobile/login";
                        let query = window.location.href;
                        // 企业微信打开
                        window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_QYWX_ID}&redirect_uri=${encodeURIComponent(origin)}&response_type=code&scope=snsapi_base&state=${query}#wechat_redirect`;
                    } else {
                        // web打开
                        router.push("/");
                    }
                    return;
                }
                ElMessage.error({
                    message: res.data.message,  //'请求异常:' +
                    type: 'warning',
                    duration: 2000,
                });
                return Promise.reject(res.data);
            }
        }
    },
    error => {
        stopProgress();
        cancel && cancel()
        ElMessage.warning({
            message: '请求异常:' + JSON.stringify(error.message),
            type: 'warning',
            duration: 2000,
        });
    }
);

// 下载二进制文件
function downloadBinaryFile(binFile, fileName, blobType) {
    // 创建 Blob 对象，包含二进制文件流和文件类型
    const blobObj = new Blob([binFile], {type: blobType});

    // 创建下载链接元素
    const downloadLink = document.createElement('a');

    // 处理不同浏览器的 URL 兼容性
    let url = window.URL || window.webkitURL;
    url = url.createObjectURL(blobObj); // 创建文件 URL

    // 设置下载链接属性
    downloadLink.href = url;
    downloadLink.download = fileName;

    // 将下载链接添加到文档中并触发点击事件
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // 移除下载链接并释放 URL 对象
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
}


export default {
    get({url, data, baseUrl, responseType}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: url.startsWith("http") || url.startsWith("/api") ? url : (baseUrl ? baseUrl + url : process.env.VUE_APP_BASE_URL + url),
                params: data,
                responseType: responseType,
            }).then(res => {
                if (res) {
                    resolve(res.data);
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getBlob({url, data, baseUrl, responseType, blobType, fileName}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: url.startsWith("http") || url.startsWith("/api") ? url : (baseUrl ? baseUrl + url : process.env.VUE_APP_BASE_URL + url),
                params: data,
                responseType: responseType,
                onDownloadProgress: (progress) => {
                    console.log(progress);
                }
            }).then(res => {
                if (res && responseType && responseType === 'blob') {
                    downloadBinaryFile(res, fileName ? fileName : "溯源码生成", blobType);
                    resolve();
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    post({url, data, baseUrl}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: url.startsWith("http") ? url : (baseUrl ? baseUrl + url : process.env.VUE_APP_BASE_URL + url),
                data: data,
            }).then(res => {
                if (res) resolve(res.data)
            }).catch(err => {
                reject(err)
            });
        })
    },
    del({url, data, baseUrl}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: url.startsWith("http") ? url : (baseUrl ? baseUrl + url : process.env.VUE_APP_BASE_URL + url),
                params: data,
            }).then(res => {
                if (res) resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
};