<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <div>
          <el-descriptions :column="3" border size="small">
            <el-descriptions-item label="客户" label-class-name="require">
              <span>{{ dataDetail.customerName }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="客户电话" label-class-name="require">
              <span>{{
                  dataDetail.customerPhone ? dataDetail.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2') : '空'
                }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="经办人" label-class-name="require">
              <span v-if="status">{{ dataDetail.employeeName }}</span>
              <employee-select v-else v-model="dataDetail.employeeId"/>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="部门" label-class-name="require">
              <dictionary v-model="dataDetail.departCode" :disabled="true"
                          :dictionaries="departList" dict-code="departCode" dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="仓库" label-class-name="require">
              <span>{{ dataDetail.stockName }}</span>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="单据日期" label-class-name="require">
              <span>{{ dataDetail.orderTime }}</span>
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="&nbsp;&nbsp; 单据编号">
              {{ dataDetail.id }}
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="&nbsp;&nbsp; 创建人">
              {{ dataDetail.createdName }}
            </el-descriptions-item>

            <el-descriptions-item v-show="showAll" label="&nbsp;&nbsp; 收款人">
              {{ dataDetail.payeeName }}
            </el-descriptions-item>
          </el-descriptions>

          <div style="display: flex;justify-content: space-between; height: 42px">
            <table-button @click="showAll = !showAll" style="margin-top: 8px">
              <el-icon :style="{transform: showAll? 'rotateZ(180deg)': 'rotateZ(0deg)'}">
                <el-icon-arrow-down/>
              </el-icon>
              {{ showAll ? "收起" : "展开" }}
            </table-button>
            <div style="display: inline-block;width: 500px;padding-bottom: 10px" v-if="!status">
              <product-list-select @select="selectProduct"
                                   :selected-product-list="dataDetail.productList" :is-stock="true"
                                   :stock-id="dataDetail.stockId" :show-price="true" :is-depart="true"
                                   :show-cost="false"/>
            </div>
          </div>
        </div>
        <div>
          <el-table stripe :data="dataDetail.productList" height="100%">
            <el-table-column :align="'center'" label="序号" width="60px" type="index" fixed/>
            <el-table-column :align="'center'" show-overflow-tooltip min-width="120px" prop="skuCode" label="商品编号"
                             fixed/>
            <el-table-column :align="'center'" show-overflow-tooltip min-width="120px" prop="skuName" label="商品名称"
                             fixed/>
            <el-table-column :align="'center'" show-overflow-tooltip min-width="180px" prop="skuStandard" label="规格"/>
            <el-table-column :align="'center'" show-overflow-tooltip min-width="180px" prop="skuType" label="型号"/>
            <el-table-column :align="'center'" show-overflow-tooltip min-width="180px" prop="barCode" label="条码"/>
            <el-table-column prop="skuUnitName" label="单位" :align="'center'" width="60px"/>
            <el-table-column label="零售价" :align="'right'" width="130px">
              <template v-slot="{row}">
                {{ row.skuOriginalPrice }}
              </template>
            </el-table-column>
            <el-table-column prop="qty" label="销售数量" :align="'center'" min-width="160px" fixed="right">
              <template v-slot="{row, $index}">
                <span v-if="status">{{ row.qty }}</span>
                <el-input-number v-model="row.qty" :min="1" v-else
                                 :max="row.stockType === 1 ? row.stockQty : 99999999999"
                                 :onkeyup="row.qty=Number((row.qty.toString()).match(/\d+/))"
                                 :onafterpaste="row.qty=Number((row.qty.toString()).match(/\d+/))"
                                 style="width: 120px"/>
              </template>
            </el-table-column>
            <el-table-column label="折扣(%)" width="130px" fixed="right">
              <template v-slot="{row, $index}">
                <span v-if="status">{{ row.rate }}</span>
                <el-input v-else v-model="row.rate" @change="changeRate($index)" :disabled="dataDetail.status !== 0"/>
              </template>
            </el-table-column>
            <el-table-column label="销售单价" :align="'center'" width="130px" fixed="right">
              <template v-slot="{row, $index}">
                <span v-if="status">{{ row.skuSalePrice }}</span>
                <el-input v-else v-model="row.skuSalePrice" @change="changeSalePrice($index)"/>
              </template>
            </el-table-column>
            <el-table-column label="销售金额" :align="'right'" width="130px" show-overflow-tooltip fixed="right">
              <template v-slot="{row}">
                <money :value="row.skuSalePrice * row.qty"/>
              </template>
            </el-table-column>

            <el-table-column label="操作" :align="'center'" width="80px" fixed="right" v-if="!status">
              <template v-slot="{row,$index}">
                <table-button message="是否确认删除此数据" el-type="delete" @click="deleteCurrentRow(row,$index)">删除
                </table-button>
              </template>
            </el-table-column>
          </el-table>

          <div style="padding: 10px 0">
            <el-text type="primary">原订单：</el-text>
          </div>

          <el-table stripe :data="dataDetail.refundProductList" height="100%">
            <el-table-column label="序号" width="60px" type="index" fixed/>

            <el-table-column align="center" show-overflow-tooltip min-width="150px" prop="skuName" label="商品名称"
                             fixed/>

            <el-table-column align="center" show-overflow-tooltip prop="barCode" label="条码"/>

            <el-table-column prop="skuUnitName" label="单位" :align="'center'" width="60px"/>

            <el-table-column label="销售价" :align="'center'" width="100px" v-if="!status">
              <template v-slot="{row}">
                {{ row.skuSalePrice }}
              </template>
            </el-table-column>

            <el-table-column label="订单数量" :align="'center'" width="100px" v-if="!status">
              <template v-slot="{row}">
                {{ row.skuAllQty }}
              </template>
            </el-table-column>

            <el-table-column label="退货价" :align="'center'" width="100px">
              <template v-slot="{row}">
                <span v-if="status">{{ row.skuRefundPrice }}</span>
                <el-input v-else v-model="row.skuRefundPrice"/>
              </template>
            </el-table-column>

            <el-table-column label="退货数量" :align="'center'" width="150px">
              <template v-slot="{row}">
                <span v-if="status">{{ row.qty }}</span>
                <el-input-number v-else style="width: 120px" v-model="row.qty" :disabled="dataDetail.payStatus >= 50"
                                 :min="0" :max="row.skuAllQty ?row.skuAllQty - row.refundQty : 999999999999999 "/>
              </template>
            </el-table-column>

            <el-table-column label="已退数量" :align="'center'" width="100px" v-if="!status">
              <template v-slot="{row}">
                <el-input v-model="row.refundQty" disabled type="number"/>
              </template>
            </el-table-column>

            <el-table-column label="退货金额" :align="'right'" width="120px">
              <template v-slot="{row}">
                <money :value="row.skuRefundPrice * row.qty"/>
              </template>
            </el-table-column>

            <el-table-column label="操作" :align="'center'" width="80px" fixed="right" v-if="!status">
              <template v-slot="{row,$index}">
                <table-button message="是否确认删除此数据" el-type="delete" @click="deleteRow(row,$index)">删除
                </table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <template #left>
          <div class="custom-info">
            <div>
              <span style="margin-right: 20px">补差金额：<money :value="bottomInfo.amount" :show-color="true"/></span>
            </div>
          </div>
        </template>
        <template #default>
          <div style="display: flex;flex-direction: row;line-height: 32px;font-size: 14px;">
            <page-button v-if="bottomInfo.amount > 0 && !status" type="write" el-type="primary" @click="saveSubmit">结算
            </page-button>
            <page-button v-else-if="bottomInfo.amount < 0 && !status" type="write" el-type="primary" @click="toShowRefund">结算</page-button>
            <!--            <page-button type="write" el-type="success" @click="saveDraft">存草稿</page-button>-->
          </div>
        </template>
      </form-page>
    </list-page>

    <el-dialog v-model="showOrder" width="30%" height="310px" @opened="$refs.dialogFocus.initInput()"
               :close-on-click-modal="false" title="订单结算">
      <sale-balance :sale-info="currentOrder" :disable-to-click-pay="disableToClickPay" :prepare-pay="preparePay"
                    :is-pay="true" :is-batch-pay="true" :is-refresh-pay="isRefreshPay" ref="dialogFocus"
                    @find-sale-status="toFindPayStatus" @close-page="showOrder = false"
                    @pay="payReceipt" @batch-submit="batchSubmit" v-loading.fullscreen.lock="fullscreenPayLoading">
        <div style="display: flex;align-items: center">
          <!--          <el-checkbox v-model="isAutoPrint" label="打印凭证" size="large"/>-->
        </div>
      </sale-balance>
    </el-dialog>

    <el-dialog v-model="showRefund" :close-on-click-modal="false" width="40%" height="390px"
               title="保存单据">
      <dialog-table :auto-height="true">
        <el-form label-width="70px">
          <div style="height: 100%">
            <el-form-item label="应退合计">
              <el-input v-model="getAbsAmount"/>
            </el-form-item>
            <div>
              <el-form-item :label="index === 0 ? '退款方式' : ''" v-for="(item,index) in accountRefundList"
                            :key="item.payTypeId + new Date()">
                <div style="display: flex;flex-direction: row;width: 100%;">
                  <el-select style="width: 47%" v-model="item.payTypeId" placeholder="请选择支付方式"
                             clearable :disabled="dataDetail.payStatus >= 50">
                    <el-option v-for="(st, i) in payTypeList" :key="i + 'p'" :label="st.payName" :value="st.id"/>
                  </el-select>
                  <el-input style="width: 47%" v-model="item.refundCurrent" :disabled="dataDetail.payStatus >= 50"/>
                  <page-button el-type="success" v-if="index === this.accountRefundList.length - 1" @click="addAccount"
                               style="width: 6%">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                  </page-button>
                  <page-button el-type="danger" v-else @click="reduceAccount(index)" style="width: 6%">
                    <el-icon>
                      <Minus/>
                    </el-icon>
                  </page-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </dialog-table>
      <div style="display: flex;justify-content: flex-end">
        <!--        <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large"/>-->
        <div>
          <page-button @click="showRefund = false">取消</page-button>
          <page-button el-type="primary" :loading="preparePay" @click="submitRefund" :disabled="disableToClickPay">
            确认
          </page-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableList from "@/components/h5/layout/TableList.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import RefundOrderApi from "@/api/main/order/online/RefundOrderApi";
import refundOrderApi from "@/api/main/order/online/RefundOrderApi";
import util from "@/util/util";
import money from "@/components/h5/Money.vue";
import commonApi from "@/api/commonApi";
import changeDocumentApi from "@/api/main/order/changeDocumentApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import SaleBalance from "@/components/h5/sale/saleBalance.vue";
import {ElLoading} from "element-plus";
import selfFunction from "@/selfFunction";
import systemPayApi from "@/api/main/sys/systemPayApi";
import receiptApi from "@/api/main/order/receiptApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import {Minus, Plus} from "@element-plus/icons-vue";

export default {
  name: "",
  components: {
    Minus,
    Plus,
    DialogTable,
    SaleBalance,
    FormPage, PageButton,
    ProductListSelect, TableButton,
    EmployeeSelect, CustomerSelect, Dictionary,
    ListPage, TableList, PageHeader, money
  },
  computed: {
    getAbsAmount() {
      return Math.abs(this.bottomInfo.amount);
    }
  },
  data() {
    return {
      isAutoPrint: true,
      fullscreenPayLoading: false,
      isRefreshPay: false,
      preparePay: false,
      disableToClickPay: false,
      loading: null,
      myTimer: null,
      showOrder: false,
      showAll: false,
      timerToPayResult: null,
      showRefund: false,
      dataDetail: {
        productList: [],
        refundProductList: [],
      },
      originalDetail: {},
      // 底部金额信息
      bottomInfo: {},
      // 部门
      departList: [],
      // 职员
      employeeList: [],
      // 草稿信息
      draftInfo: {},
      // 当前支付信息
      currentOrder: {},
      //
      accountRefundList: [],
      //
      payTypeList: [],
      // 状态
      status: false,
    };
  },
  watch: {
    // 监听数据变化
    dataDetail: {
      handler() {
        this.orderChange();
      },
      deep: true
    }
  },
  created() {
    // 初始化信息
    this.init();
    // 换货时传入原订单
    if (this.$routerUtil.query.id) {
      this.addRefund({id: this.$routerUtil.query.id});
    }
    // 草稿原订单
    if (this.$routerUtil.query.draftId) {
      changeDocumentApi.findById(this.$routerUtil.query.draftId).then(data => {
        this.draftInfo = data;
        this.findInfo();
      });
    }
    // 状态
    if (this.$routerUtil.query.status){
      this.status = true;
    }
  },
  methods: {
    /**
     * todo：添加一条
     */
    addAccount() {
      this.accountRefundList.push({payTypeId: "", refundCurrent: 0});
    },
    /**
     * todo：减少一条
     */
    reduceAccount(index) {
      this.accountRefundList.splice(index, 1)
    },
    /**
     * todo：展示退单
     */
    toShowRefund() {
      if (this.accountRefundList.length === 0) {
        // 获取原订单的最后一次支付方式
        offlineOrderApi.findFinalPayType(this.originalDetail.payId).then(res => {
          this.accountRefundList.push({payTypeId: res, refundCurrent: 0});
          this.showRefund = true;
        }).catch(() => {
          systemPayApi.queryAllList().then(res => {
            this.$message.error("未查询到对应支付,请手动选择")
            this.accountRefundList.push({payTypeId: res[0].id, refundCurrent: 0});
            this.showRefund = true;
          })
        })
      } else {
        this.showRefund = true;
      }
    },
    /**
     * todo：退单数据处理
     */
    handleData(refundData) {
      //设置退货单的来源单据
      if (refundData.orderType === 1) {
        refundData.originalId = refundData.id;
      }
      return this.handlerAccountDraft(refundData);
    },
    /**
     * todo：退款方式处理
     */
    handlerAccountDraft(refundData) {
      // 最终上传后端的数据
      let acutalAccount = refundData.payList;
      // 客户id
      const customerId = acutalAccount[0].customerId;
      // 商户id
      const merchantId = acutalAccount[0].merchantId;
      // 订单id
      const orderId = refundData.originalId;
      // 退款的支付方式及金额
      let refundAccount = this.accountRefundList;
      // 将相同的支付方式的数据合并，放入到map中，保证payTypeId唯一
      let myAccountMap = new Map();
      // 过滤掉退款金额等于0的数据
      // refundAccount = refundAccount.filter(item => {
      //   return Number(item.refundCurrent) !== 0 && item.payTypeId;
      // })
      for (let i = 0; i < refundAccount.length; i++) {
        // 校验map中是否已存在当前支付方式
        if (myAccountMap.has(refundAccount[i].payTypeId)) {
          let account = myAccountMap.get(refundAccount[i].payTypeId);
          account.refundCurrent = Number(account.refundCurrent) + Number(refundAccount[i].refundCurrent);
          myAccountMap.set(refundAccount[i].payTypeId, account);
          // 移除账户数据中的重复账户
          refundAccount.splice(i, 1);
          // i--;
        } else {
          myAccountMap.set(refundAccount[i].payTypeId, refundAccount[i]);
        }
      }
      // 将账户数据与原始数据进行整合
      for (let i = 0; i < acutalAccount.length; i++) {
        let account = myAccountMap.get(acutalAccount[i].payTypeId);
        if (account) {
          acutalAccount[i].refundCurrent = account.refundCurrent;
          // 移除
          myAccountMap.delete(acutalAccount[i].payTypeId)
        } else {
          acutalAccount.splice(i, 1);
          i--;
        }
      }
      // 将未找到原始数据的账户直接添加到原始数据中
      for (const value of myAccountMap.values()) {
        let data = {
          payTypeId: value.payTypeId,
          refundCurrent: value.refundCurrent,
          orderId: orderId,
          payTime: new Date(),
          payStatus: 99,
          amount: 0,
          currentAmount: 0,
        }
        data.customerId = customerId;
        data.merchantId = merchantId;
        this.payTypeList.forEach(item => {
          if (value.payTypeId === item.id) {
            data.payType = item.payCode;
          }
        })
        acutalAccount.push(data)
      }
      refundData.payList = acutalAccount;
      return refundData;
    },
    /**
     * todo：退单结算
     */
    async submitRefund() {
      ;
      // 添加一笔支付-支付方式为变更单
      let payData = await this.addPay();
      let refundData = this.dataDetail;
      refundData.productList = this.dataDetail.refundProductList;
      this.preparePay = true;
      this.disableToClickPay = true;
      refundData = this.handleData(refundData);
      // 原訂單
      refundData.originalId = this.originalDetail.id;
      this.isActual = false;
      RefundOrderApi.saveDraft(refundData).then(data => {
        changeDocumentApi.addDoc({
          originalId: data.id,
          newId: payData.id,
          customerId: data.customerId,
          status: 99,
        })
        this.toSubmit(data);
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      })
    },
    /**
     * todo：退单查询id
     */
    async findById(id) {
      const data = RefundOrderApi.findRefundById(id);
      // 过滤掉支付记录未成功的记录
      data.payList = data.payList.filter(item => item.payStatus >= 50);
      // 初始化账户
      this.accountRefundList = [];
      this.handlerRefundAccount(data.payList);
    },
    /**
     * todo：处理退款账户
     */
    handlerRefundAccount(list) {
      this.$nextTick(() => {
        for (let i = 0; i < list.length; i++) {
          if (list[i].refundCurrent && list[i].refundCurrent > 0) {
            this.accountRefundList.push({...list[i]});
          }
        }
      })
    },
    /**
     * todo：退单提交
     */
    toSubmit(data) {
      RefundOrderApi.refund(data.id).then(res => {
        this.preparePay = false;
        this.disableToClickPay = false;
        if (res === "退款失败") {
          this.$message.error(res);
        } else {
          this.$message.success(res);
        }
        this.toOrderRouter();
      }).catch(() => {
        this.preparePay = false;
        this.disableToClickPay = false;
      });
    },
    /**
     * todo：支付信息
     */
    async addPay() {
      let payData = this.dataDetail;
      // 金额信息
      let qty = 0, discount = 0, salePrice = 0, totalPrice = 0;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        qty += this.dataDetail.productList[i].qty;
        for (let j = 0; j < this.dataDetail.productList[i].qty; j++) {
          discount += this.dataDetail.productList[i].skuOriginalPrice - this.dataDetail.productList[i].skuSalePrice;
        }
        salePrice += this.dataDetail.productList[i].skuSalePrice * this.dataDetail.productList[i].qty;
        totalPrice += this.dataDetail.productList[i].skuOriginalPrice * this.dataDetail.productList[i].qty;
      }
      payData.amountTotal = totalPrice;
      payData.qty = qty;
      payData.amountDiscount = discount;
      payData.amount = salePrice;
      payData.amountCollect = salePrice;
      // 获取支付方式信息
      const payInfo = await systemPayApi.findByType(3);
      payData.payType = 3;
      payData.payTypeId = payInfo.id;
      payData.paymentType = 8;
      // 原订单设置为空
      payData.originalId = null;
      return await offlineOrderApi.preparePay(payData);
    },
    /**
     * todo：查询支付结果
     */
    toFindPayStatus(data) {
      if (this.isRefreshPay) this.$message.warning("正在努力获取结果，请耐心等待！！！")
      this.isRefreshPay = true;
      // 开始查询
      offlineOrderApi.findPayStatus(data.id, data.payId).then(res => {
        offlineOrderApi.findById(data.id).then(newData => {
          if (newData.payStatus >= 50) {
            // 成功时修改状态信息
            this.successResult();
            // 判断是否需要进行打印
            if (this.isAutoPrint) this.toPrint();
            // 添加一条支付信息
            this.addPaydetail();
            // 语音
            selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio);
            clearTimeout(this.timerToPayResult);
            return;
          }
          if (this.dataDetail.payStatus === 30) {
            this.successResult();
            this.$message.error(res);
            clearTimeout(this.timerToPayResult);
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          this.loading.close();
          clearTimeout(this.timerToPayResult);
          return;
        }
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus(data);
        }, 1000);
        this.isRefreshPay = false;
      }).catch(() => {
        this.loading.close();
        this.isRefreshPay = false;
      })
    },
    /**
     * todo：支付
     */
    async payReceipt(data) {
      // 处理商品信息
      this.handlerProduct();
      // 先存储一下草稿信息
      const draft = await this.saveDraft();
      // 开启计时器
      this.enabledTimer();
      // 查询最新的信息
      let id, refundId;
      if (draft) {
        id = draft.newId;
        refundId = draft.originalId;
        // 赋值
        this.draftInfo = draft;
      } else {
        id = this.draftInfo.newId;
        refundId = this.draftInfo.originalId;
      }
      // 最新订单信息
      const currentOrder = await offlineOrderApi.findById(id);
      // 替换data
      currentOrder.amountCollect = data.amountCollect;
      currentOrder.payType = data.payType;
      currentOrder.payTypeId = data.payTypeId;
      currentOrder.qrCode = data.qrCode;
      currentOrder.paymentType = 1;
      // 准备支付
      offlineOrderApi.preparePay(currentOrder).then(order => {
        // 存储退单信息
        this.saveRefund(refundId);
        // 更新状态
        changeDocumentApi.updateStatus(this.draftInfo.id);
        // 重新查询信息
        offlineOrderApi.findById(order.id).then(newData => {
          if (newData.payStatus >= 50) {
            // 支付成功
            this.successResult();
            // 添加一条支付信息
            this.addPaydetail();
            // 语音播放
            selfFunction.createAudio(require("@/assets/video/result.mp3"), this.$refs.audio);
            // 跳转路由
            this.toOrderRouter();
            // 判断是否需要打印
            // if (this.isAutoPrint) this.toPrint();
          } else if (newData.payStatus === 20) {
            // 支付中，轮询
            this.toFindPayStatus(newData);
          }
        })
      }).catch(() => this.successResult());
    },
    /**
     * todo：存储退单信息
     */
    saveRefund(refundId) {
      // 开始存储
      refundOrderApi.cdRefund(refundId);
    },
    /**
     * todo：添加一条支付信息
     */
    async addPaydetail() {
      const newData = await offlineOrderApi.findById(this.draftInfo.newId);
      // 查询原单据信息
      const originalData = await offlineOrderApi.findById(this.draftInfo.originalId);
      let pay = originalData.payList[0];
      // 更换金额信息
      pay.amount = newData.amount;
      pay.currentAmount = originalData.amount;
      pay.orderId = this.draftInfo.newId;
      // 获取支付方式
      const payInfo = await systemPayApi.findByType(3);
      // 换货调账
      pay.isPay = 1;
      pay.paymentType = 8;
      pay.payStatus = 99;
      pay.payType = payInfo.payType;
      pay.payTypeId = payInfo.id;
      pay.payTime = new Date();
      pay.payeeId = originalData.employeeId;
      // 存储支付信息
      await receiptApi.savePaydetail(pay);
    },
    /**
     * todo：开启计时器
     */
    enabledTimer() {
      // 赋值
      let myCount = 60;
      this.myTimer = setInterval(() => {
        if (myCount > 0) {
          myCount--;
          this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
        } else {
          clearInterval(this.myTimer);
          this.loading.close();
        }
      }, 1000)
      this.loading = ElLoading.service({
        lock: true,
        text: `获取支付结果中，${myCount}秒后自动更新...`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 锁定操作状态
      if (this.preparePay) this.$message.error("正在支付中，请稍等！！！");
      this.preparePay = true;
      this.disableToClickPay = true;
    },
    /**
     * todo：支付成功修改状态
     */
    successResult() {
      this.showOrder = false;
      this.disableToClickPay = false;
      this.preparePay = false;
      this.loading.close();
    },
    /**
     * todo：移除不存在数量的商品
     */
    handlerProduct() {
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        if (this.dataDetail.productList[i].qty === 0) {
          this.dataDetail.productList.splice(i, 1);
          i--;
        }
      }
    },
    /**
     * todo：提交支付信息
     */
    async batchSubmit(data) {
      this.handlerProduct();
      // 先存储一下草稿信息
      const draft = await this.saveDraft();
      // ;
      // 查询最新的信息
      let id;
      if (draft) {
        id = draft.newId;
        this.draftInfo = draft;
      } else {
        id = this.draftInfo.newId;
      }
      // 最新订单信息
      const currentOrder = await offlineOrderApi.findById(id);
      // 保存
      this.preparePay = true;
      this.disableToClickPay = true;
      currentOrder.amountCurrent = data.amountCollect;
      currentOrder.status = 50; // 设置为未完成
      currentOrder.payStatus = 0;
      currentOrder.amountCollect = null;
      currentOrder.payType = data.payType;
      currentOrder.payTypeId = data.payTypeId;
      offlineOrderApi.saveDraft(currentOrder).then(res => {
        this.preparePay = false;
        this.disableToClickPay = false;
        // 添加一条支付信息
        this.addPaydetail();
        // 存储退单信息
        this.saveRefund(this.draftInfo.originalId);
        // 更新状态
        changeDocumentApi.updateStatus(this.draftInfo.id);
        // 跳转路由
        this.toOrderRouter();
      });
    },
    /**
     * 跳转订单路由
     */
    toOrderRouter(){
      this.$routerUtil.push("/report/offline-order");
    },
    /**
     * todo：变更单列表查询回显
     */
    findInfo() {
      this.findRefundId(this.draftInfo.originalId).then(() => {
        // 新订单
        offlineOrderApi.findById(this.draftInfo.newId).then(data => {
          this.dataDetail.productList = data.productList;
        })
      });
    },
    /**
     * todo：退款回显
     */
    findRefundId(id) {
      return RefundOrderApi.findRefundById(id).then(data => {
        this.dataDetail = data;
        this.dataDetail.refundProductList = data.productList;
        // 过滤掉支付记录未成功的记录
        // this.dataDetail.payList = data.payList.filter(item => item.payStatus >= 50);
      });
    },
    /**
     * todo：初始化
     */
    init() {
      // 职员
      commonApi.queryAllEmployee().then(list => this.employeeList = list);
      // 部门
      commonApi.queryDepartList().then(list => this.departList = list);
      // 支付方式
      systemPayApi.queryAllList().then(res => this.payTypeList = res);
    },
    /**
     * todo：保存草稿
     */
    saveDraft() {
      if (Object.keys(this.draftInfo).length > 0) {
        this.dataDetail.changeDocumentId = this.draftInfo.id;
        this.dataDetail.changeOriginalId = this.draftInfo.originalId;
        this.dataDetail.changeCurrentId = this.draftInfo.newId;
      }
      return changeDocumentApi.saveDraft(this.dataDetail).then(data => {
        if (data !== null) {
          this.draftInfo = data;
        }
      });
    },
    /**
     * todo：保存单据
     */
    saveSubmit() {
      // 拼凑支付信息
      let amount = 0, qty = 0;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        amount += this.dataDetail.productList[i].qty * this.dataDetail.productList[i].skuSalePrice;
        qty += this.dataDetail.productList[i].qty;
      }
      this.currentOrder.amount = amount;
      this.currentOrder.qty = qty;
      this.currentOrder.amountCollect = this.bottomInfo.amount;
      this.currentOrder.amountShould = 0;
      // 保存一下草稿信息
      this.showOrder = true;
    },
    /**
     * todo：删除当前行数据-退货单
     */
    deleteRow(row, idx) {
      this.dataDetail.refundProductList.splice(idx, 1);
      this.$message.success("删除成功！");
    },
    /**
     * todo：删除当前行数据-当前单
     */
    deleteCurrentRow(row, idx) {
      this.dataDetail.productList.splice(idx, 1);
      this.$message.success("删除成功！");
    },
    /**
     * todo：商品选中
     */
    selectProduct(list) {
      // 排除库存为0的商品
      let strMsg = '';
      for (let i = 0; i < list.length; i++) {
        if (list[i].stockQty === 0 && list[i].stockType === 1) {
          strMsg += list[i].skuName + ' ,';
          list.splice(i, 1);
          i--;
        }
      }
      if (strMsg !== '') {
        this.$message.warning(strMsg.substring(0, strMsg.length - 1) + "等商品暂无库存");
      }
      if (list.length === 0) {
        return;
      }
      let arr = [];
      if (this.dataDetail.productList) {
        arr = [...this.dataDetail.productList];
      }
      // 有哪些商品，看是否存在，不存在就添加
      for (let i = 0; i < list.length; i++) {
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === list[i].id) {
            // 存在，更新数量
            contains = true
            break;
          }
        }
        // 不存在，添加
        if (!contains) {
          arr.push(this.createOrderDetail(list[i]));
        }
      }
      this.dataDetail.productList = arr;
    },
    /**
     * todo：创建订单信息
     */
    createOrderDetail(data) {
      return {
        skuId: data.id,
        skuName: data.skuName,
        skuCode: data.skuCode,
        barCode: data.barCode,
        skuUnitId: data.skuUnitId,
        productId: data.productId,
        qty: 1,
        skuUnitName: data.skuUnitName,
        skuSalePrice: data.skuSalePrice,
        skuBuyPrice: data.skuBuyPrice,
        skuOriginalPrice: data.skuSalePrice,
        stockQty: data.stockQty,
        stockType: data.stockType,
        skuStandard: data.skuStandard,
        skuType: data.skuType,
        productBrade: data.productBrade,
        rate: 100,
      }
    },
    /**
     * todo：调入退款订单
     */
    addRefund(row) {
      RefundOrderApi.findRefundById(row.id).then(data => {
        // 调入订单并初始化当前订单为一个草稿状态
        this.originalDetail = data;
        //初始化退款数量;
        let arr = [];
        for (let i = 0; i < data.productList.length; i++) {
          let p = data.productList[i];
          if (p.qty === p.refundQty) continue;
          arr.push({
            ...p,
            payStatus: 0,
            qty: p.skuAllQty - p.refundQty,
            allQty: p.qty - p.refundQty,
            // 初始退款价、默认为销售价格
            skuRefundPrice: p.skuSalePrice,
            id: "",
            orderId: "",
            payType: data.payType,
            payTypeId: data.payTypeId,
          })
        }
        this.dataDetail = {
          refundProductList: arr,
          customerId: data.customerId,
          customerName: data.customerName,
          customerPhone: data.customerPhone,
          employeeId: data.employeeId,
          employeeName: data.employeeName,
          stockId: data.stockId,
          stockName: data.stockName,
          createdName: data.createdName,
          payeeName: data.payeeName,
          departCode: data.departCode,
          payTypeId: data.payTypeId,
          payType: data.payType,
          originalId: data.id,
          amount: data.amount,
          amountDiscountZero: data.amountDiscountZero,
          status: 0,
        }
        // 处理支付记录数据，排除掉支付未成功的支付记录
        this.dataDetail.payList = data.payList.filter(item => item.payStatus >= 50);
        // 设置默认的经手人和制单人
        util.getUserInfo().then(res => {
          this.dataDetail.employeeName = res.employeeName
          this.dataDetail.createdName = res.employeeName
        })
        // 设置订单时间为当前时间
        this.dataDetail.orderTime = new Date().format("yyyy-MM-dd hh:mm:ss")
      });
    },
    /**
     * todo：订单信息修改
     */
    orderChange() {
      let originalAmount = 0, currentAmount = 0;
      // 计算补差金额信息
      let rpl = this.dataDetail.refundProductList;
      if (rpl.length !== 0) {
        for (let i = 0; i < rpl.length; i++) {
          originalAmount += rpl[i].qty * rpl[i].skuRefundPrice;
        }
      }
      let pl = this.dataDetail.productList;
      if (pl && pl.length !== 0) {
        for (let i = 0; i < pl.length; i++) {
          currentAmount += pl[i].qty * pl[i].skuSalePrice;
        }
      }
      this.bottomInfo.amount = currentAmount - originalAmount;
    },
    /**
     * todo：更改折扣
     */
    changeRate(i) {
      let data = this.dataDetail.productList[i];
      if (data.rate > 100) {
        this.$message.error("折扣不能大于100%！");
        data.rate = 100;
        return;
      }
      data.skuSalePrice = (data.skuOriginalPrice * data.rate / 100).toFixed(2);
    },
    /**
     * todo：变更售价
     */
    changeSalePrice(i) {
      let data = this.dataDetail.productList[i];
      if (data.skuSalePrice > data.skuOriginalPrice) {
        this.$message.error("商品售价不能大于商品原价！");
        data.skuSalePrice = data.skuOriginalPrice;
        return;
      }
      data.rate = (data.skuSalePrice / data.skuOriginalPrice * 100).toFixed(0);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>