<template>
  <!-- -->
  <span style="margin-right: 10px">
    <table-button @click="toShowCodeList">{{
      disabled ? "溯源码" : "添加溯源码"
    }}</table-button>
    <el-drawer
      v-model="showOriginalDialog"
      :title="skuName + '溯源码'"
      dir="ltr"
      size="500px"
      :close-on-click-modal="false"
      append-to-body
      class="drawer-class-default"
      @open="handleDrawerOpen"
    >
      <!--    自动生成溯源码-->
      <div style="height: calc(100vh - 180px)">
        <div style="display: flex; justify-content: space-between">
          <table-button v-if="!disabled" @click="toCreate"
            >自动生成溯源码</table-button
          >
          <table-button v-if="!disabled" @click="toUpload"
            >导入溯源码</table-button
          >
        </div>
        <el-table stripe :id="id" :data="skuOriginalList" height="100%">
          <el-table-column
            label="序号"
            type="index"
            width="60px"
            :align="'center'"
          />
          <el-table-column
            label="外溯源码"
            prop="originalCode"
            v-if="disabled"
            :align="'center'"
          ></el-table-column>
          <el-table-column
            label="内溯源码"
            prop="inOriginalCode"
            v-if="inCode && disabled"
            :align="'center'"
          ></el-table-column>
          <el-table-column
            label="内溯源码"
            prop="inOriginalCode"
            v-if="showMerchantCode && disabled"
            :align="'center'"
          ></el-table-column>
          <el-table-column label="外溯源码" v-if="!disabled">
            <template v-slot="{ row, $index }">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <el-input
                  ref="firstInput"
                  v-model="row.originalCode"
                  placeholder="请输入外溯源码"
                  @change="addTableOriginalCode('out-code', $index)"
                />
              </div>
            </template>
          </el-table-column>

          <el-table-column label="内溯源码" v-if="inCode && !disabled">
            <template v-slot="{ row, $index }">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <el-input
                  v-model="row.inOriginalCode"
                  placeholder="请输入内溯源码"
                  @change="addTableOriginalCode('in-code', $index)"
                />
              </div>
            </template>
          </el-table-column>

          <el-table-column label="商家码" v-if="showMerchantCode && !disabled">
            <template v-slot="{ row, $index }">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <el-input
                  v-model="row.inOriginalCode"
                  placeholder="请输入内溯源码"
                  @change="addTableOriginalCode('in-code', $index)"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right; padding-top: 10px; margin-top: 20px">
        <page-button @click="showOriginalDialog = false">返回</page-button>
        <page-button
          v-if="!disabled"
          el-type="primary"
          @click="addOriginalCode2List"
          >确定</page-button
        >
      </div>
    </el-drawer>

    <!--  生成溯源码-->
    <el-drawer
      v-model="createOriginalDialog"
      size="500px"
      append-to-body
      :close-on-click-modal="false"
      title="按编号自动添加连接的编码"
    >
      <div>
        <table-list v-if="originalCodeType !== 'SHJX'">
          <el-descriptions :column="1" border>
            <el-descriptions-item label="开始编码值">
              <el-input v-model="startValue" placeholder="请输入开始编码的值" />
            </el-descriptions-item>
            <el-descriptions-item label="截至编码值">
              <el-input v-model="endValue" placeholder="请输入截至编码的值" />
            </el-descriptions-item>
          </el-descriptions>
        </table-list>

        <table-list v-if="originalCodeType === 'SHJX'">
          <el-descriptions :column="1" border>
            <el-descriptions-item label="外码-开始编码值">
              <el-input
                v-model="shjx.startValue"
                placeholder="请输入开始编码的值"
              />
            </el-descriptions-item>
            <el-descriptions-item label="外码-截至编码值">
              <el-input
                v-model="shjx.endValue"
                placeholder="请输入截至编码的值"
              />
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="1" border>
            <el-descriptions-item label="内码-开始编码值">
              <el-input
                v-model="shjx.startValue_in"
                placeholder="请输入开始编码的值"
              />
            </el-descriptions-item>
            <el-descriptions-item label="内码-截至编码值">
              <el-input
                v-model="shjx.endValue_in"
                placeholder="请输入截至编码的值"
              />
            </el-descriptions-item>
          </el-descriptions>
        </table-list>
        <page-button
          v-if="!disabled"
          el-type="primary"
          @click="toShengOriginalCode"
          >生成</page-button
        >
        <div class="warn-title">
          <p>注意：</p>
          <p>1、每次批量生成的序列码必须相同</p>
          <p>2、序列码 = 源码的2-3位 + 6-7位</p>
          <p>3、内码和外码每次生成为单双数,即内码+1为外码,+2为内码(外码同理)</p>
        </div>
      </div>
    </el-drawer>
    <import-component
      v-model="showExport"
      title="批量导入商品"
      @exportTemp="exportTemp"
      :importByExcel="importByExcel"
    />
  </span>
</template>

<script>
import TableButton from "@/components/h5/button/TableButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import { watch } from "vue";
import ImportComponent from "@/components/h5/ImportComponent.vue";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import mtLegderApi from "@/api/main/material/mtLegderApi";

export default {
  emits: ["addOriginalCode"],
  props: {
    disabled: { default: false },
    // 仓库id
    stockId: { default: "" },
    // 溯源码类型
    originalCodeType: { default: "ALPINE" },
    skuName: { default: "" },
    codeList: { default: [] },
    // 开启外码功能
    inCode: { default: false },
    // 开启内外码互查功能
    enabledSelect: { default: true },
    // 是否需要进行解析
    isNeedTransfer: { default: true },
    // 是否显示商家绑定的码
    showMerchantCode: { default: false },
  },
  name: "",
  components: {
    ImportComponent,
    PageButton,
    TableList,
    TableButton,
  },
  data() {
    return {
      showExport: false,
      createOriginalDialog: false,
      showOriginalDialog: false,
      skuOriginalList: [],
      startValue: "",
      endValue: "",
      id: "",
      exportTemp: null,
      readTempData: null,
      shjx: {
        recordNo: "",
        startValue: "",
        endValue: "",
        startValue_in: "",
        endValue_in: "",
      },
    };
  },
  created() {
    this.id = "skuOriginalTable" + (Math.random() * 1000).toFixed(0);
    this.initList();
    // 导入和导出
    this.exportTemp = this.downloadTemp;
    this.readTempData = this.importByExcel;
    watch(
      () => this.codeList,
      () => {
        this.initList();
      }
    );
  },
  methods: {
    initList() {
      this.skuOriginalList = [...this.codeList, {}];
    },
    toCreate() {
      this.createOriginalDialog = true;
    },
    addTableOriginalCode(type, idx, isFind = true) {
      // 判断是否需要进行解析
      if (this.showMerchantCode) {
        // 商品使用
        mtLegderApi
          .convertCode(
            this.skuOriginalList[idx][
              type === "out-code" ? "originalCode" : "inOriginalCode"
            ]
          )
          .then((code) => {
            if (code) {
              this.skuOriginalList[idx][
                type === "out-code" ? "inOriginalCode" : "originalCode"
              ] = code;
            }
            this.handlerOriginalCode(type, idx, isFind);
          });
      } else if (this.isNeedTransfer) {
        commonApi
          .transferUrl(
            this.skuOriginalList[idx][
              type === "out-code" ? "originalCode" : "inOriginalCode"
            ],
            this.originalCodeType
          )
          .then((code) => {
            if (code) {
              this.skuOriginalList[idx][
                type === "out-code" ? "originalCode" : "inOriginalCode"
              ] = code;
              this.handlerOriginalCode(type, idx, isFind);
            }
          });
      } else {
        this.handlerOriginalCode(type, idx, isFind);
      }
    },
    handlerOriginalCode(type, idx, isFind) {
      // 如果没有空白行，添加一个溯源码
      let blank = false;
      for (let i = 0; i < this.skuOriginalList.length; i++) {
        if (this.inCode) {
          if (
            !this.skuOriginalList[i].originalCode ||
            !this.skuOriginalList[i].inOriginalCode
          ) {
            blank = true;
            break;
          }
        } else {
          if (!this.skuOriginalList[i].originalCode) {
            blank = true;
            break;
          }
        }
      }
      if (!blank) {
        this.skuOriginalList.push({});
      }
      // 根据一个码查询另外一个码
      if (isFind && this.enabledSelect) {
        this.findOtherCode(type, idx);
      }
      this.$nextTick().then(() => {
        if (this.inCode) {
          let trArr = document.querySelectorAll(
            `#${this.id} tr:last-child input`
          );
          if (!this.skuOriginalList[idx].originalCode) {
            trArr[0].focus();
          }
          if (!this.skuOriginalList[idx].inOriginalCode) {
            trArr[1].focus();
          }
          if (Object.keys(this.skuOriginalList[idx + 1]).length === 0) {
            trArr[0].focus();
          }
        } else {
          // 让新增的input进行聚焦
          let input = document.querySelector(
            `#${this.id} tr:last-child input:last-child`
          );
          input.focus();
        }
      });
    },
    findOtherCode(type, idx) {
      if (!this.stockId && this.inCode) {
        this.$message.error("请选择仓库信息！");
        return;
      }
      if (type === "in-code") {
        // 内码查询外码
        commonApi
          .findCode(this.stockId, this.skuOriginalList[idx].inOriginalCode)
          .then((code) => {
            this.skuOriginalList[idx].originalCode = code;
            this.addTableOriginalCode("out-cod", idx, false);
          });
      } else if (type === "out-code") {
        // 外码查询内码
        commonApi
          .findCode(this.stockId, this.skuOriginalList[idx].originalCode)
          .then((code) => {
            this.skuOriginalList[idx].inOriginalCode = code;
            this.addTableOriginalCode("in-code", idx, false);
          });
      }
    },
    addOriginalCode2List() {
      // 没有码的数据不要
      let res = [];
      for (let i = 0; i < this.skuOriginalList.length; i++) {
        if (this.skuOriginalList[i].originalCode) {
          res.push(this.skuOriginalList[i]);
        }
      }
      this.$emit("addOriginalCode", res);
      this.showOriginalDialog = false;
    },
    toShengOriginalCode() {
      if (this.originalCodeType === "XYYQGJ") {
        this.toGenerateOriginalCodeXYYQGJ();
      } else if (this.originalCodeType === "SHJX") {
        this.toGenerateOriginalCode_SHJX();
      }
    },
    // 盛和久讯自动生成
    toGenerateOriginalCode_SHJX() {
      // 获取原来的码-去除掉序列码后
      let resultArr = [];
      // 循环添加外码
      resultArr.push({
        originalCode: this.shjx.startValue,
        inOriginalCode: this.shjx.startValue_in,
      });
      let s = this.convertCode(this.shjx.startValue, "SHJX", true);
      let e = this.convertCode(this.shjx.endValue, "SHJX", true);
      for (let i = s; i < e; i += BigInt(1)) {
        if ((e - s) / BigInt(2) + s === i) {
          break;
        }
        let cc = i + BigInt(2) + BigInt(i - s);
        let code = this.convertCode(cc, "SHJX", false);
        resultArr.push({
          originalCode: code,
        });
      }
      let start = this.convertCode(this.shjx.startValue_in, "SHJX", true);
      let end = this.convertCode(this.shjx.endValue_in, "SHJX", true);
      for (let i = start; i < end; i += BigInt(1)) {
        if ((end - start + 1) / BigInt(2) + s === i) {
          break;
        }
        let cc = i + BigInt(2) + BigInt(i - start);
        resultArr[i - start + BigInt(1)].inOriginalCode = this.convertCode(
          cc,
          "SHJX",
          false
        );
      }
      this.skuOriginalList.pushAll(resultArr);
      this.createOriginalDialog = false;
      this.addOriginalCode2List();
    },
    toGenerateOriginalCodeXYYQGJ() {
      if (this.startValue.length < 15 || this.endValue.length < 15) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      if (this.startValue.length > 19 || this.endValue.length > 20) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      let start = this.startValue.split("-")[1];
      if (isNaN(Number(start))) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      let end = this.endValue.split("-")[1];
      if (isNaN(Number(end))) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // 生成编号
      let list = [];
      for (let i = 0; i <= Number(end) - Number(start); i++) {
        let count = "";
        if (i.toString().length < 5) {
          for (let j = 0; j < 5 - (i + 1).toString().length; j++) {
            count += "0";
          }
        }
        count += (i + 1).toString();
        list.push({ originalCode: this.startValue.substring(0, 14) + count });
      }
      this.skuOriginalList.pushAll(list);
      this.createOriginalDialog = false;
      this.addOriginalCode2List();
    },
    //自动生成溯源码
    toGenertOriginalCode() {
      let start = this.startValue.substring(0, this.startValue.length - 2);
      let end = this.endValue.substring(0, this.endValue.length - 2);
      let startYear = this.startValue.substring(this.startValue.length - 2);
      let endYear = this.endValue.substring(this.endValue.length - 2);
      if (startYear !== endYear) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // 获取编号
      let strStart = this.getCode(start);
      let strEnd = this.getCode(end);
      if (strStart !== strEnd) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // ALHZ0470PPF000124
      // 开始和结束，除code外其它应该一样
      let startValue = parseInt(start.replace(strStart, ""));
      let endValue = parseInt(end.replace(strEnd, ""));
      let org = [];
      for (let o = startValue; o <= endValue; o++) {
        const codeObject = {
          originalCode: `${strStart}${o
            .toString()
            .padStart(4, "0")}${startYear}`,
        };
        org.push(codeObject);
      }
      this.skuOriginalList = org;
      this.createOriginalDialog = false;
      this.addOriginalCode2List();
    },
    toShowCodeList() {
      this.showOriginalDialog = true;
    },
    getCode(str) {
      let i = parseInt(str.charAt(str.length - 1));
      if (isNaN(i)) {
        return str;
      }
      return this.getCode(str.substring(0, str.length - 1));
    },
    toUpload() {
      // 显示导入功能
      this.showExport = true;
    },
    downloadTemp() {
      util.exportExcel("/order/common/downOriginalCodeTemp", {});
    },
    // 读取模板数据
    importByExcel(formData) {
      return new Promise((resolve) => {
        commonApi.readOriginalCodeTempData(formData).then((list) => {
          let org = [];
          for (let i = 0; i < list.length; i++) {
            org.push({
              originalCode: list[i],
            });
          }
          this.skuOriginalList = org;
          this.createOriginalDialog = false;
          this.addOriginalCode2List();
          resolve();
        });
      });
    },
    // 互相转换
    convertCode(code, type, isCom) {
      if (typeof code !== "string") {
        code = code.toString();
      }
      if (type === "SHJX") {
        // isCom == true时 转为正常的无序列号码
        if (isCom) {
          return BigInt(
            code.substring(0, 2) + code.substring(4, 6) + code.substring(8)
          );
        } else {
          // 加上序列码
          let resultStr = "";
          resultStr += code.substring(0, 2);
          resultStr += this.shjx.startValue.substring(2, 4);
          resultStr += code.substring(2, 4);
          resultStr += this.shjx.startValue.substring(6, 8);
          resultStr += code.substring(4);
          return resultStr;
        }
      }
    },
    handleDrawerOpen() {
      this.$nextTick(() => {
        setTimeout(() => {
          // 判断是否有数据
          const hasData = this.skuOriginalList.some(
            (item) => item.originalCode || item.inOriginalCode
          );
          if (hasData) {
            // 找到最后一个有数据的行的索引
            let lastDataIndex = this.skuOriginalList.length - 1;
            while (lastDataIndex >= 0) {
              if (
                this.skuOriginalList[lastDataIndex].originalCode ||
                this.skuOriginalList[lastDataIndex].inOriginalCode
              ) {
                break;
              }
              lastDataIndex--;
            }

            // 获取对应的行元素
            const rows = document.querySelectorAll(`#${this.id} tr`);
            // 注意：表格的第一行是表头，所以实际数据行需要+1
            const lastDataRow = rows[lastDataIndex + 1];

            if (lastDataRow) {
              const inputs = lastDataRow.querySelectorAll(".el-input input");
              if (inputs.length > 0) {
                // 如果有内码和外码，则选择第一个空的输入框
                for (let input of inputs) {
                  if (!input.value) {
                    input.focus();
                    return;
                  }
                }
                // 如果都有值，则聚焦最后一个
                inputs[inputs.length - 1].focus();
              }
            }
          } else {
            // 如果没有数据，聚焦到第一个input
            const firstRow = document.querySelector(
              `#${this.id} tr:first-child`
            );
            if (firstRow) {
              const input = firstRow.querySelector(".el-input input");
              if (input) {
                input.focus();
              } else {
                // 如果还没找到，再次尝试
                setTimeout(() => {
                  const retryInput = document.querySelector(
                    `#${this.id} tr:first-child .el-input input`
                  );
                  if (retryInput) {
                    retryInput.focus();
                  }
                }, 10);
              }
            }
          }
        }, 300);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";

:deep(.el-drawer__header) {
  margin-bottom: 5px !important;
}

:deep(.drawer-class-default) {
  color: red;

  .el-drawer__header {
    margin-bottom: 0;
    white-space: nowrap;
  }
}

:deep(.el-drawer__header > :first-child) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.warn-title {
  p:nth-child(1) {
    font-weight: 600;
    color: #606266;
    padding-left: 0px;
  }
  p {
    font-size: 14px;
    padding-top: 5px;
    padding-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #909399;
  }
}
</style>