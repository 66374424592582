<template>
  <!-- 该页面盛和久讯专用 -->
  <div class="defaultViewStyle">
    <long-form>
      <long-form-panel label="基本信息">
        <el-descriptions :column="3" border>
          <el-descriptions-item label="商品编号">
            <el-input
                v-model="detail.productCode"
                placeholder="请输入商品编号"
            />
          </el-descriptions-item>

          <el-descriptions-item label="商品名称">
            <el-input
                v-model="detail.productName"
                placeholder="请输入商品名称"
                @input="getPinyinCode"
            />
          </el-descriptions-item>
          <el-descriptions-item label="商品简名">
            <el-input
                v-model="detail.productName"
                placeholder="请输入商品简名"
                disabled
            />
          </el-descriptions-item>

          <el-descriptions-item label="拼音码">
            <el-input v-model="detail.pinyinCode" placeholder="请输入拼音码"/>
          </el-descriptions-item>

          <el-descriptions-item label="商品分类">
            <dictionary
                v-model="detail.category"
                :dictionaries="productCategories"
                place-holder="请选择商品类目"
                style="display: inline-block; width: 100%"
            />
          </el-descriptions-item>

          <el-descriptions-item label="规格">
            <el-input
                v-model="detail.productStandard"
                placeholder="请输入商品规格"
            />
          </el-descriptions-item>
          <el-descriptions-item label="型号">
            <el-input
                v-model="detail.productType"
                placeholder="请输入商品型号"
            />
          </el-descriptions-item>

          <!--          <el-descriptions-item label="产地">-->
          <!--            <el-input v-model="detail.originPlace" placeholder="请输入商品产地"/>-->
          <!--          </el-descriptions-item>-->

          <el-descriptions-item label="品牌">
            <dictionary
                v-model="detail.productBrade"
                @clearData="clearDic"
                :place-holder="'请选择商品品牌'"
                :dictionaries="productBrades"
                dict-code="dictCode"
                dict-name="dictName"
            />
          </el-descriptions-item>

          <el-descriptions-item label="质保期限(天)" v-if="isWarrantyPeriod">
            <el-input
                v-model="detail.warrantyPeriod"
                placeholder="请输入质保期限 / 单位(天)"
            />
          </el-descriptions-item>

          <el-descriptions-item
              label="报废日期"
              v-if="sysOriginalCode === 'MATERIAL'"
          >
            <el-date-picker
                v-model="detail.scrapTime"
                type="datetime"
                placeholder="请填写报废日期"
                format="YYYY/MM/DD"
                style="width: 100%"
                clearable
            />
            <!--            <el-input v-model="" placeholder="请输入报废日期"/>-->
          </el-descriptions-item>

          <el-descriptions-item label="存货类型">
            <el-select placeholder="请选择商品属性" v-model="detail.stockType">
              <el-option :value="1" label="库存商品"/>
              <el-option :value="0" label="服务商品"/>
              <el-option :value="2" label="组合商品"/>
            </el-select>
          </el-descriptions-item>

          <el-descriptions-item label="状态">
            <el-select
                v-model="detail.status"
                placeholder="请选择商品状态"
                size="default"
                style="width: 100%"
            >
              <el-option label="上架" :value="0"/>
              <el-option label="下架" :value="-1"/>
              <el-option label="停用" :value="-2"/>
            </el-select>
          </el-descriptions-item>

          <el-descriptions-item label="参考成本">
            <el-input v-model="detail.referenceCost" placeholder="请输入参考成本"/>
          </el-descriptions-item>

          <el-descriptions-item
              label="维保单位"
              v-if="sysOriginalCode === 'MATERIAL'"
          >
            <el-select
                v-model="detail.mtUnitId"
                placeholder="请选择维保单位"
                size="default"
                style="width: 100%"
            >
              <el-option
                  v-for="it in mtUnitList"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
              />
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item
              :span="2"
              label="维保设置"
              v-if="sysOriginalCode === 'MATERIAL'"
          >
            <el-select
                v-model="detail.mtSettingId"
                placeholder="请选择维保设置"
                size="default"
                style="width: 100%"
            >
              <el-option
                  v-for="it in mtSettingList"
                  :key="it.id"
                  :value="it.id"
                  :label="it.frequency + '次 / ' + it.year + '年'"
              />
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item :span="3" label="备注">
            <el-input v-model="detail.summary" type="textarea" :rows="2"/>
          </el-descriptions-item>
        </el-descriptions>
      </long-form-panel>

      <long-form-panel label="单位及价格">
        <el-table stripe :data="unitRateList">
          <el-table-column
              align="center"
              label="序号"
              type="index"
              width="60px"
          />
          <el-table-column
              align="center"
              width="120px"
              label="单位类型"
              prop="level"
          >
            <template v-slot="{ row }">
              <span v-if="row.level === 1"
              >基本单位<span style="color: red">*</span></span
              >
              <span v-else-if="row.level === 2">计量单位1</span>
              <span v-else-if="row.level === 3">计量单位2</span>
            </template>
          </el-table-column>
          <el-table-column min-width="70px" label="单位名称">
            <template v-slot="{ row }">
              <el-select
                  ref="unitSelect"
                  @input="handleSearchInput"
                  @change="checkBasicUnit(row)"
                  v-if="row.level === 1"
                  v-model="row.unitId"
                  filterable
                  clearable
                  placeholder="请输入名称搜索"
                  :loading="loading"
                  style="width: 100%"
              >
                <template #empty>
                  <div>
                    <span style="margin-top: 5px">无搜索结果</span>
                    <br/>
                    <div style="display: flex; justify-content: flex-end">
                      <table-button
                          style="margin-right: 10px"
                          @click="addUnit(row.level)"
                      ><span>添加单位</span>
                      </table-button>
                    </div>
                  </div>
                </template>
                <el-option
                    v-for="item in unitList.basicUnit"
                    :key="item.id"
                    :label="item.unitName"
                    :value="item.id"
                />
              </el-select>
              <el-select
                  ref="unitSelect"
                  @input="handleSearchInput"
                  @change="checkBasicUnit(row)"
                  v-if="row.level === 2"
                  v-model="row.unitId"
                  filterable
                  clearable
                  placeholder="请输入名称搜索"
                  :loading="loading"
                  style="width: 100%"
              >
                <template #empty>
                  <div>
                    <span style="margin-top: 5px">无搜索结果</span>
                    <br/>
                    <div style="display: flex; justify-content: flex-end">
                      <table-button
                          style="margin-right: 10px"
                          @click="addUnit(row.level)"
                      ><span>添加单位</span>
                      </table-button>
                    </div>
                  </div>
                </template>
                <el-option
                    v-for="item in unitList.basicUnit"
                    :key="item.id"
                    :label="item.unitName"
                    :value="item.id"
                />
              </el-select>
              <el-select
                  ref="unitSelect"
                  @input="handleSearchInput"
                  @change="checkBasicUnit(row)"
                  v-if="row.level === 3"
                  v-model="row.unitId"
                  filterable
                  clearable
                  placeholder="请输入名称搜索"
                  :loading="loading"
                  style="width: 100%"
              >
                <template #empty>
                  <div>
                    <span style="margin-top: 5px">无搜索结果</span>
                    <br/>
                    <div style="display: flex; justify-content: flex-end">
                      <table-button
                          style="margin-right: 10px"
                          @click="addUnit(row.level)"
                      ><span>添加单位</span>
                      </table-button>
                    </div>
                  </div>
                </template>
                <el-option
                    v-for="item in unitList.basicUnit"
                    :key="item.id"
                    :label="item.unitName"
                    :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column align="center" label="换算关系" prop="rate">
            <template v-slot="{ row }">
              <span v-if="row.level === 1">{{ row.rate }}</span>
              <el-input
                  v-else
                  v-model="row.rate"
                  @input="checkBasicUnit(row)"
              />
            </template>
          </el-table-column>
          <el-table-column label="条码" prop="productUnitCode">
            <template v-slot="{ row }">
              <el-input
                  v-model="row.productUnitCode"
                  @input="checkBasicUnit(row)"
              />
            </template>
          </el-table-column>

          <el-table-column label="预设进价" prop="buyPrice">
            <!--            <el-table-column align="center" label="单价">-->
            <!--              <template v-slot="{ row, $index }">-->
            <!--                <el-input v-model="row.buyPrice"/>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="含税单价">-->
            <template v-slot="{ row }">
              <el-input v-model="row.buyPrice"/>
            </template>
            <!--            </el-table-column>-->
          </el-table-column>

          <el-table-column label="预设售价" prop="salePrice">
            <!--            <el-table-column align="center" label="单价">-->
            <!--              <template v-slot="{ row, $index }">-->
            <!--                <el-input v-model="row.salePrice" @input="changeSalePrice($index, 1)"/>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="含税单价">-->
            <template v-slot="{ row }">
              <el-input v-model="row.salePrice"/>
            </template>
            <!--            </el-table-column>-->
          </el-table-column>

          <!--          <el-table-column label="操作" :align="'center'" width="120px">-->
          <!--            <template v-slot="{ row }">-->
          <!--              <table-button @click="saveUnitRate(row)">保存</table-button>-->
          <!--              <table-button type="danger" @click="deleteUnitRate(row)">删除</table-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
      </long-form-panel>

      <long-form-panel label="商品描述">
        <el-form label-width="100px">
          <el-form-item label="商品主图">
            <image-upload
                v-model="detail.titleImage"
                :limit="1"
                width="80px"
                height="80px"
                :upload-tips="'600px * 600px'"
            />
          </el-form-item>
          <el-form-item label="轮播图片">
            <image-upload
                v-model="detail.bannerImage"
                :limit="5"
                width="80px"
                height="80px"
            />
          </el-form-item>
          <el-form-item
              label="操作文档"
              v-if="sysOriginalCode === 'MATERIAL'"
          >
            <div class="upload-container">
              <div class="upload-list">
                <div v-for="(file, index) in docList" :key="index" class="file-item">
                  <div class="file-info">
                    <el-icon><Document /></el-icon>
                    <span class="file-name">{{ file.name }}</span>
                  </div>
                  <div class="file-actions">
                    <el-button type="primary" link @click="previewDoc(file)">
                      <el-icon><View /></el-icon>
                      预览
                    </el-button>
                    <el-button type="danger" link @click="removeDoc(index)">
                      <el-icon><Delete /></el-icon>
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
              <el-upload
                class="doc-uploader"
                :show-file-list="false"
                :action="uploadUrl"
                :headers="headers"
                :on-success="handleDocSuccess"
                :on-error="handleUploadError"
                :before-upload="beforeDocUpload"
                accept=".pdf"
              >
                <el-button type="primary">
                  <el-icon><Upload /></el-icon>
                  上传文档
                </el-button>
                <template #tip>
                  <div class="upload-tip">目前只支持 PDF格式，单个文件不超过10MB</div>
                </template>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item
              label="操作视频"
              v-if="sysOriginalCode === 'MATERIAL'"
          >
            <div class="upload-container">
              <div class="upload-list">
                <div v-for="(video, index) in videoList" :key="index" class="file-item">
                  <div class="file-info">
                    <el-icon><VideoCamera /></el-icon>
                    <span class="file-name">{{ video.name }}</span>
                  </div>
                  <div class="file-actions">
                    <el-button type="primary" link @click="previewVideo(video)">
                      <el-icon><VideoPlay /></el-icon>
                      播放
                    </el-button>
                    <el-button type="danger" link @click="removeVideo(index)">
                      <el-icon><Delete /></el-icon>
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
              <el-upload
                class="video-uploader"
                :action="uploadUrl"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleVideoSuccess"
                :on-error="handleUploadError"
                :before-upload="beforeVideoUpload"
                accept=".mp4,.webm,.ogg"
              >
                <el-button type="primary">
                  <el-icon><Upload /></el-icon>
                  上传视频
                </el-button>
                <template #tip>
                  <div class="upload-tip">支持 MP4、WebM、OGG 格式，单个文件不超过100MB</div>
                </template>
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item label="详情描述" v-if="sysOriginalCode !== 'MATERIAL'">
            <rick-text v-model="detail.productDetail" @input="getEditorValue"/>
          </el-form-item>
        </el-form>
      </long-form-panel>

      <long-form-panel label="组合商品" v-if="detail.stockType === 2">
        <product-list-select
            ref="pls"
            :show-search="false"
            :common-btn="false"
            :small-btn="false"
            :selected-product-list="detail.groupList"
            @select="selectProduct"
        />
        <el-table stripe :data="detail.groupList">
          <el-table-column
              show-overflow-tooltip
              align="center"
              label="序号"
              type="index"
              width="60px"
          />
          <el-table-column
              show-overflow-tooltip
              align="center"
              label="编号"
              prop="skuCode"
              width="120px"
          />
          <el-table-column
              show-overflow-tooltip
              align="center"
              label="商品名称"
              prop="skuName"
              min-width="150px"
          />
          <el-table-column
              show-overflow-tooltip
              align="center"
              label="单位"
              prop="skuUnitName"
              width="120px"
          />
          <el-table-column
              show-overflow-tooltip
              align="center"
              label="数量"
              prop="skuUnitName"
              width="180px"
          >
            <template v-slot="{ row }">
              <el-input-number v-model="row.qty"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              show-overflow-tooltip
              min-width="120px"
              prop="skuStandard"
              label="规格"
          />
          <el-table-column
              align="center"
              show-overflow-tooltip
              min-width="120px"
              prop="skuType"
              label="型号"
          />
          <el-table-column
              label="操作"
              align="center"
              width="80px"
              fixed="right"
          >
            <template #header>
              <table-button el-type="primary" @click="$refs.pls.toShowDialog()"
              >添加
              </table-button
              >
            </template>
            <template v-slot="{ $index }">
              <table-button
                  message="是否确认删除此数据"
                  el-type="delete"
                  @click="deleteRow($index)"
              >删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </long-form-panel>

      <long-form-panel
          style="margin-top: 15px"
          label="商品多规格"
          v-if="detail.stockType !== 2"
      >
        <div
            style="
            margin-left: 20px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
          "
        >
          <el-checkbox
              v-model="detail.productAttributeValue.addStandard"
              size="small"
          >启用商品多规格
          </el-checkbox
          >
          <table-button
              style="margin-left: 10px; display: flex"
              el-type="primary"
              @click="addAttribute"
              v-if="detail.productAttributeValue.addStandard"
          >
            <el-icon>
              <Plus/>
            </el-icon>
            <span>添加规格</span>
          </table-button>
          <table-button
              v-if="detail.productAttributeValue.addStandard"
              el-type="primary"
              @click="autoAddSku"
          >
            <el-icon>
              <Plus/>
            </el-icon>
            添加sku到列表
          </table-button>
        </div>
        <div v-if="detail.productAttributeValue.addStandard">
          <el-table
              stripe
              :data="detail.productAttributeValue.attributeList"
              size="small"
              key="attributeTable"
          >
            <el-table-column
                label="序号"
                type="index"
                :align="'center'"
                width="100px"
                :index="hIndex"
            >
              <!-- <template slot="header">
                <span style="cursor: pointer" @click="toShowAttributes">序号<i class="el-icon-plus add-in-table" /></span>
              </template> -->
            </el-table-column>
            <el-table-column
                label="规格类型"
                prop="columnName"
                width="100px"
                show-overflow-tooltip
            />
            <el-table-column label="规格名称">
              <template v-slot="{ row }">
                <el-checkbox
                    v-for="(item, idx) in row.optionList"
                    :key="idx"
                    v-model="item.checked"
                >{{ item.name }}
                </el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="操作" :align="'center'" width="60px">
              <template v-slot="{ $index }">
                <table-button
                    el-type="danger"
                    @click="deleteAttributeFromProduct($index)"
                >删除
                </table-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </long-form-panel>

      <long-form-panel
          label="商品SKU明细"
          v-if="
          detail.productAttributeValue.addStandard && detail.stockType !== 2
        "
      >
        <div>
          <div style="margin-left: 20px; padding-bottom: 10px">
            <table-button el-type="primary" @click="addSku"
            ><span
            ><el-icon><Plus/></el-icon>添加SKU</span
            >
            </table-button>
          </div>
          <el-table stripe :data="detail.skuList" key="skuTable">
            <el-table-column
                label="序号"
                type="index"
                :align="'center'"
                :index="hIndex"
                width="60px"
            >
              <!-- <span v-for="(item,index) in detail.skuList" :key="index">{{ index+1 }}</span> -->
              <!-- <template slot="header">
                <span style="cursor: pointer" @click="toAddNewSku">序号<i class="el-icon-plus add-in-table"/></span>
              </template> -->
            </el-table-column>
            <el-table-column
                label="商品名称"
                min-width="200px"
                show-overflow-tooltip
            >
              <template v-slot="{ row }">
                <el-input v-model="row.skuName"/>
              </template>
            </el-table-column>
            <el-table-column
                label="规格信息"
                min-width="120px"
                v-if="detail.productAttributeValue.addStandard"
                show-overflow-tooltip
            >
              <template v-slot="{ row, $index }">
                <span
                    v-for="(item, i) in row.skuAttributeList"
                    :key="$index + '_' + i"
                >
                  <span v-if="i > 0">,</span>
                  <span>
                    {{ item.name }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="零售价格" min-width="100px">
              <template v-slot="{ row }">
                <el-input v-model="row.skuSalePrice"/>
              </template>
            </el-table-column>
            <el-table-column label="基础进价" min-width="100px">
              <template v-slot="{ row }">
                <el-input v-model="row.skuBuyPrice"/>
              </template>
            </el-table-column>
            <el-table-column label="基本单位" min-width="100px">
              <template v-slot="{ row, $index }">
                <el-select v-model="row.skuUnitId">
                  <el-option
                      v-for="(item, i) in units"
                      :key="$index + '_' + i"
                      :value="item.id"
                      :label="item.unitName"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="规格" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.skuStandard"/>
              </template>
            </el-table-column>
            <el-table-column label="型号" min-width="150">
              <template v-slot="{ row }">
                <el-input v-model="row.skuType"/>
              </template>
            </el-table-column>
            <el-table-column label="商品编码" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.skuCode"/>
              </template>
            </el-table-column>
            <el-table-column label="条形码" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.barCode"/>
              </template>
            </el-table-column>
            <el-table-column label="适配信息" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.fitAttr" placeholder="请输入适配信息"/>
              </template>
            </el-table-column>
            <el-table-column label="拼音码" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.pinyinCode"/>
              </template>
            </el-table-column>
            <el-table-column label="税收编码" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.taxCode" placeholder="请输入税收编码"/>
              </template>
            </el-table-column>
            <el-table-column label="税收项目名称" min-width="150px">
              <template v-slot="{ row }">
                <el-input
                    v-model="row.taxCategory"
                    placeholder="请输入税收项目名"
                />
              </template>
            </el-table-column>
            <el-table-column label="税率" min-width="150px">
              <template v-slot="{ row }">
                <el-input v-model="row.taxRate" placeholder="请输入税率"/>
              </template>
            </el-table-column>
            <el-table-column label="质保期限(天)" min-width="150px">
              <template v-slot="{ row }">
                <el-input
                    v-model="row.warrantyPeriod"
                    placeholder="请输入税收编码"
                />
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                :align="'center'"
                width="100px"
                fixed="right"
            >
              <template v-slot="{ row, $index }">
                <table-button @click="toEditSku(row)">编辑</table-button>
                <table-button
                    @click="deleteSku(row, $index)"
                    el-type="danger"
                    message="您确定要删除该条sku吗？"
                    type="delete"
                >删除
                </table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </long-form-panel>

      <long-form-panel label="商品自定义">
        <el-table
            stripe
            :data="detail.productAttributeList"
            key="customerTable"
        >
          <el-table-column
              label="序号"
              type="index"
              :align="'center'"
              :index="hIndex"
              width="100px"
          >
          </el-table-column>
          <el-table-column label="属性名称" prop="columnName" width="100px">
            <template v-slot="{ row }">
              <el-input v-model="row.name"/>
            </template>
          </el-table-column>
          <el-table-column label="属性内容">
            <template v-slot="{ row }">
              <el-input v-model="row.value"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="100px">
            <template #header>
              <table-button el-type="primary" @click="addCustomerAttribute"
              ><span>添加信息</span></table-button
              >
            </template>
            <template v-slot="{ $index }">
              <table-button
                  el-type="danger"
                  @click="deleteCustomerAttribute($index)"
              >删除
              </table-button
              >
            </template>
          </el-table-column>
        </el-table>
      </long-form-panel>
    </long-form>

    <form-page :show-page="false">
      <el-checkbox
          v-model="isCopy"
          label="复制到下一条"
          size="large"
          style="margin-right: 10px"
      />
      <page-button @click="$routerUtil.go(-1)">返回</page-button>
      <page-button el-type="success" type="write" @click="saveDetail(true)"
      >保存并新建
      </page-button
      >
      <page-button el-type="success" @click="saveDetail(false)"
      >保存后返回
      </page-button
      >
    </form-page>

    <el-dialog v-model="showAttribute" title="商品多规格">
      <el-checkbox
          v-for="(item, idx) in productAttributeList"
          v-model="item.checked"
          :key="idx"
      >{{ item.columnName }}
      </el-checkbox>
      <div style="height: 400px">
        <attribute-merchant @toUpdateMerchant="updateMerchant"/>
      </div>
      <div style="text-align: right; padding-top: 10px">
        <page-button @click="showAttribute = false">取消</page-button>
        <page-button el-type="primary" @click="addAttribute2Detail"
        >确认
        </page-button
        >
      </div>
    </el-dialog>

    <el-dialog v-model="showSkuDialog" title="商品sku信息">
      <el-form label-width="100px">
        <el-form-item label="商品名称">
          <el-input v-model="currentSkuInfo.skuName"/>
        </el-form-item>
        <el-form-item label="零售价格">
          <el-input v-model="currentSkuInfo.skuSalePrice"/>
        </el-form-item>
        <el-form-item label="基础进价">
          <el-input v-model="currentSkuInfo.skuBuyPrice"/>
        </el-form-item>
        <el-form-item label="基本单位">
          <el-select v-model="currentSkuInfo.skuUnitId">
            <el-option
                v-for="(item, i) in units"
                :key="i"
                :label="item.unitName"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="currentSkuInfo.skuStandard"/>
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="currentSkuInfo.skuType"/>
        </el-form-item>
        <el-form-item label="适配信息 ">
          <el-input v-model="currentSkuInfo.fitAttr"/>
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input v-model="currentSkuInfo.skuCode"/>
        </el-form-item>
        <el-form-item label="条形码">
          <el-input v-model="currentSkuInfo.barCode"/>
        </el-form-item>
        <el-form-item label="拼音码">
          <el-input v-model="currentSkuInfo.pinyinCode"/>
        </el-form-item>
        <div v-if="currentSkuInfo.attributeObject">
          <el-form-item label="规格信息">
            <div
                v-for="(item, idx) in detail.productAttributeValue.attributeList"
                :key="idx"
                style="display: flex; align-items: center; margin-right: 15px"
            >
              <span style="font-weight: bolder">{{ item.columnName }}：</span>
              <el-radio-group
                  style="margin-left: 5px"
                  v-model="currentSkuInfo.attributeObject[item.id].code"
                  v-for="(o, i) in item.optionList"
                  :key="idx + '_' + i"
              >
                <el-radio :label="o.code" v-if="o.checked">{{
                    o.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <detail-page-btn>
        <el-button @click="showSkuDialog = false">取消</el-button>
        <el-button type="primary" @click="saveSkuSingle">保存</el-button>
      </detail-page-btn>
    </el-dialog>

    <el-dialog v-model="addProductInformation" title="添加自定义信息">
      <el-form label-width="100px">
        <el-form-item label="属性名称">
          <el-input
              v-model="productCustom.columnName"
              style="width: 340px"
              placeholder=""
          />
        </el-form-item>
        <el-form-item label="属性内容">
          <el-input
              v-model="productCustom.options"
              style="width: 340px"
              :rows="2"
              type="textarea"
              placeholder=""
          />
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 文档预览弹窗 -->
    <el-dialog
      v-model="docPreviewVisible"
      title="文档预览"
      width="80%"
      :destroy-on-close="true"
    >
      <iframe
        v-if="previewUrl"
        :src="previewUrl"
        width="100%"
        height="600px"
        frameborder="0"
      ></iframe>
    </el-dialog>

    <!-- 视频预览弹窗 -->
    <el-dialog
      v-model="videoPreviewVisible"
      title="视频预览"
      width="80%"
      :destroy-on-close="true"
    >
      <video
        v-if="previewUrl"
        :src="previewUrl"
        controls
        style="width: 100%"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import Dictionary from "@/components/h5/Dictionary";
import LongForm from "@/components/h5/layout/LongForm";
import LongFormPanel from "@/components/h5/layout/LongFormPanel";
import ImageUpload from "@/components/h5/image/ImageUpload";
import RickText from "@/components/h5/richText/RichText";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn";
import TableButton from "@/components/h5/button/TableButton";
import commonApi from "@/api/commonApi";
import productApi from "@/api/main/product/productApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import AttributeMerchant from "../attribute/AttributeMerchant.vue";
import FormPage from "@/components/h5/layout/FormPage";
import {Plus, Document, Upload, View, Delete, VideoCamera, VideoPlay} from "@element-plus/icons-vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import {pinyin} from "pinyin-pro";
import mtUnitApi from "@/api/main/material/mtUnitApi";
import mtSettingApi from "@/api/main/material/mtSettingApi";
import {ElMessage} from "element-plus";
import Util from "@/util/util";

export default {
  components: {
    Plus,
    AttributeMerchant,
    PageButton,
    ImageUpload,
    LongForm,
    LongFormPanel,
    Dictionary,
    RickText,
    DetailPageBtn,
    TableButton,
    FormPage,
    ProductListSelect,
    Document,
    Upload,
    View,
    Delete,
    VideoCamera,
    VideoPlay
  },
  //
  data() {
    return {
      // 单位搜索
      loading: false,
      // 单位搜索的关键词
      unitSearchKey: "",
      // 已添加单位集合
      unitList: {
        basicUnit: [],
        secUnit: [],
        thirdUnit: [],
      },
      // 商品详情
      detail: {
        attachmentInfo: {},
        groupList: [],
        id: "",
        barCode: "",
        productName: "",
        pinyinCode: "",
        productSupplier: "",
        productBrade: "",
        stockType: "",
        basePrice: "",
        buyPrice: "",
        productUnitId: "",
        productDetail: "",
        bannerImage: "",
        titleImage: "",
        status: null,
        attributeItems: "",
        taxCode: "",
        taxRate: 0,
        taxCategory: "",
        // 商品多规格设置
        productAttributeValue: {
          addStandard: false,
          attributeList: [],
        },
        departList: [], // 部门
        productAttributeList: [], // 商品自定义信息
        skuList: [],
        productStandard: "",
        productType: "",
        productCode: "",
      },
      addProductInformation: false,
      // 商品分类字典
      productCategories: [],
      // 商品品牌
      productBrades: [],
      productSuppliers: [],
      // 单位管理
      showUnits: false,
      // 是否允许在单位页面选择单位
      selectUnitAble: false,
      // 商品单位
      units: [{unitName: "测试单位", id: "1"}],
      // 当前编辑的商品属性
      currentProductAttribute: {},
      // 单位转换率
      unitRateList: [{unitId: ""}],
      // 显示单位详情
      showUnitDetail: false,
      // 单位详情
      currentUnit: {},
      // 要添加到商品单位的单位
      newUnits: [],
      // 属性分组
      productAttributeList: [],
      // 显示商品规格
      showAttribute: false,
      showAttributeDetail: false,
      currentSkuInfo: {}, // 编辑sku信息
      showSkuDialog: false,
      //轮播图片信息
      toSaveBannerImgJson: "",
      //是否开启了订单质保功能
      isWarrantyPeriod: false,
      // 单位模板
      unitTemp: [
        {
          level: 1,
          unitName: "",
          rate: 1,
          productUnitCode: "",
          buyPrice: 0,
          salePrice: 0,
        },
        {
          level: 2,
          unitName: "",
          rate: "",
          productUnitCode: "",
          buyPrice: 0,
          salePrice: 0,
        },
        {
          level: 3,
          unitName: "",
          rate: "",
          productUnitCode: "",
          buyPrice: 0,
          salePrice: 0,
        },
      ],
      // 是否复制
      isCopy: true,
      // 部门
      departList: [],
      // 供应商
      supplierList: [],
      // 生产企业
      productionList: [],
      // 溯源码
      sysOriginalCode: [],
      // 维保单位
      mtUnitList: [],
      // 维保设置信息
      mtSettingList: [],
      docList: [],
      videoList: [],
      uploadUrl: `${process.env.VUE_APP_BASE_URL}/basedata/common/updateImage`, // 替换为实际的上传接口
      headers: {
        token: Util.getHeaderToken(),
        folder: "wzFile"
      },
      docPreviewVisible: false,
      videoPreviewVisible: false,
      previewUrl: ''
    };
  },
  created() {
    // 查询所有的维保设置
    mtSettingApi.queryAll().then((list) => (this.mtSettingList = list));
    // 查询所有单位信息
    mtUnitApi.queryAll().then((list) => (this.mtUnitList = list));
    // 查询溯源码的类型
    commonApi
        .findSetting("STOCK_ORIGINAL_TYPE")
        .then((setting) => (this.sysOriginalCode = setting.settingValue));
    // 获取读的权限
    // util.getAccess('/product/list', "read").then(res => {
    //   new Promise((resolve, reject)=>{
    //     if(res <= "2"){
    //       util.getUserInfo().then(userRes =>{
    //         this.departList = [{departId: userRes.mainDepartId, departCode: userRes.mainDepartCode, departName: userRes.mainDepartName}];
    //         resolve();
    //       })
    //     }else{
    //       // 查询部门，查询成树结构返回
    //       commonApi.queryDepartList().then(list => {
    //         this.departList = list;
    //         resolve();
    //       });
    //     }
    //   }).then(()=>{
    //     if(this.detail.departList.length > 0){
    //       return;
    //     }
    //     // 默认选择当前部门
    //     util.getUserInfo().then(userRes =>{
    //       this.detail.departList.push(userRes.mainDepartCode);
    //       console.log(this.detail.departList);
    //     })
    //   })
    // })
    commonApi.querySupplierList().then((list) => (this.supplierList = list));
    commonApi.querySupplierList(2).then((list) => (this.productionList = list));
    // 初始化商品单位
    this.unitRateList = [...this.unitTemp];
    // 查询是否开启订单质保功能
    commonApi.findSetting("OD_WARRANTY_SWITCH", "10000").then((res) => {
      this.isWarrantyPeriod = res.settingValue === "1";
    });

    // this.unitRateList = [];
    // 查询商品分类
    commonApi
        .queryDictionaryTree("PRODUCT_CATEGORY")
        .then((list) => (this.productCategories = list));
    // // 商品品牌
    commonApi
        .queryDictionaryList("PRODUCT_BRADE")
        .then((list) => (this.productBrades = list));
    // // 商品供应商
    // productApi.queryProductSuppliers().then(list => this.productSuppliers = list)
    // 全部商品属性集
    productApi.queryAttributeList().then((list) => {
      this.productAttributeList = list;
      this.showSkuInfo();
    });
    // 查询单位
    this.queryUnits();
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
      // 查询商品单位
      // this.queryProductUnitRates(this.$routerUtil.query.id);
    }

    // 复制
    if (this.$routerUtil.query.copyInfo) {
      let data = JSON.parse(this.$routerUtil.query.copyInfo);
      this.detail = this.recursiveClear(data);
      // 如果只有一个sku则清空
      if (this.detail.skuList.length === 1) {
        this.detail.skuList = [];
      } else {
        // 不是一个sku则清空它们的id
        this.detail.skuList.forEach((item) => (item.id = ""));
      }
      this.unitRateList = this.detail.productUnits;
      this.detail.productCode = "";
      this.detail.id = "";
      // 处理单位
      // 将单位按level进行排序
      this.unitRateList.sort((a, b) => {
        return a.level - b.level;
      });
      // 如果单位的长度不足三则添加
      if (this.unitRateList.length < 3) {
        for (let i = this.unitRateList.length; i < this.unitTemp.length; i++) {
          this.unitRateList.push(this.unitTemp[i]);
        }
      }
    }
  },
  methods: {
    getPinyinCode() {
      this.detail.pinyinCode = pinyin(this.detail.productName, {
        toneType: "none",
      })
          .split(" ")
          .join("")
          .toUpperCase();
    },

    changeRate() {
      for (let i = 0; i < this.unitRateList.length; i++) {
        // this.changeBuyPrice(i, 1);
        // this.changeBuyPrice(i, 2);
        this.changeSalePrice(i, 1);
        this.changeSalePrice(i, 2);
      }
    },
    /**
     * 改变价格
     * @param idx 索引
     * @param isCount 1-未税 2-含税
     */
    changeBuyPrice(idx, isCount) {
      let taxRate = this.detail.stockTaxRate;
      if (!taxRate) {
        taxRate = 0;
      }
      if (isCount === 1) {
        // 未税算含税
        this.unitRateList[idx].includeBuyPrice = (
            this.unitRateList[idx].buyPrice *
            (1 + Number(taxRate))
        ).toFixed(2);
      } else {
        // 含税算未税
        this.unitRateList[idx].buyPrice = (
            this.unitRateList[idx].includeBuyPrice /
            (1 + Number(taxRate))
        ).toFixed(2);
      }
    },
    /**
     * 改变价格
     * @param idx 索引
     * @param isCount 1-未税 2-含税
     */
    changeSalePrice(idx, isCount) {
      let taxRate = this.detail.taxRate;
      if (!taxRate) {
        taxRate = 0;
      }
      if (isCount === 1) {
        // 未税算含税
        this.unitRateList[idx].includeSalePrice = (
            this.unitRateList[idx].salePrice *
            (1 + Number(taxRate))
        ).toFixed(2);
      } else {
        // 含税算未税
        this.unitRateList[idx].salePrice = (
            this.unitRateList[idx].includeSalePrice /
            (1 + Number(taxRate))
        ).toFixed(2);
      }
    },
    selectProduct(list) {
      if (list.length === 0) {
        return;
      }
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < this.detail.groupList.length; j++) {
          if (list[i].id === this.detail.groupList[j].skuId) {
            this.detail.groupList.splice(j, 1);
            break;
          }
        }
        this.detail.groupList.push({...this.createdDetail(list[i])});
      }
    },
    createdDetail(data) {
      return {
        skuId: data.id,
        skuName: data.skuName,
        skuCode: data.skuCode,
        barCode: data.barCode,
        unitId: data.skuUnitId,
        qty: 1,
        skuUnitName: data.skuUnitName,
        salePrice: data.skuSalePrice,
        buyPrice: data.skuBuyPrice,
        skuStandard: data.skuStandard,
        skuType: data.skuType,
      };
    },
    deleteRow(idx) {
      this.detail.groupList.splice(idx, 1);
    },
    // 复制时使用、递归清除所有的id
    recursiveClear(data) {
      if (Array.isArray(data)) {
        data.forEach((item) => this.recursiveClear(item));
      } else if (typeof data === "object" && data !== null) {
        for (const key in data) {
          if (key === "id") {
            delete data[key];
          } else if (typeof data[key] === "object" && data[key] !== null) {
            this.recursiveClear(data[key]);
          }
        }
      }
      return data;
    },
    handleSearchInput(searchKey) {
      this.unitSearchKey = searchKey.data;
    },
    // 添加单位
    addUnit(level) {
      if (!this.unitSearchKey && this.unitSearchKey === "") {
        this.$message.error("请输入要添加的单位名称！！！");
        return;
      }
      let data = {
        unitLevel: level,
        unitName: this.unitSearchKey,
      };
      productApi.saveUnit(data).then((res) => {
        // 刷新单位
        this.queryUnits().then(() => {
          // 设置当前选择的单位
          this.unitRateList[level - 1].unitId = res.id;
          this.$refs.unitSelect.expanded = false;
        });
      });
    },
    // 清除品牌
    clearDic() {
      this.detail.productBrade = "";
    },
    // 检查基本单位
    checkBasicUnit(row) {
      if (row && row.level === 1) {
        return;
      }
      let result = true;
      for (let i = 0; i < this.unitRateList.length; i++) {
        if (this.unitRateList[i].level === 1) {
          // 基本单位
          // if (!this.unitRateList[i].productUnitCode || this.unitRateList[i].productUnitCode === "") {
          //   this.$message.error("基本单位条码不能为空！！！");
          //   result = false;
          // }
          if (
              !this.unitRateList[i].unitId ||
              this.unitRateList[i].unitId === ""
          ) {
            this.$message.error("基本单位名称不能为空！！！");
            result = false;
          }
          if (!this.unitRateList[i].rate || this.unitRateList[i].rate === "") {
            this.$message.error("基本单位换算关系不能为空！！！");
            result = false;
          }
        }
      }
      if (row && !result) {
        for (let i = 0; i < this.unitRateList.length; i++) {
          if (this.unitRateList[i].level === 1) {
            continue;
          }
          this.unitRateList[i].productUnitCode = "";
          this.unitRateList[i].unitName = "";
          this.unitRateList[i].rate = "";
          this.unitRateList[i].buyPrice = 0;
          this.unitRateList[i].salePrice = 0;
        }
      } else {
        return result;
      }
    },
    createNew() {
      this.detail = {
        id: "",
        barCode: "",
        productName: "",
        pinyinCode: "",
        productSupplier: "",
        productBrade: "",
        stockType: "",
        basePrice: "",
        buyPrice: "",
        productUnitId: "",
        productDetail: "",
        bannerImage: "",
        titleImage: "",
        status: null,
        attributeItems: "",
        taxCode: "",
        taxRate: 0,
        taxCategory: "",
        // 商品多规格设置
        productAttributeValue: {
          addStandard: false,
          attributeList: [],
        },

        productAttributeList: [], // 商品自定义信息
        skuList: [],
        productStandard: "",
        productType: "",
        productCode: "",
      };
    },
    //更新规格dialog
    updateMerchant(list) {
      this.productAttributeList = list;
      this.showSkuInfo();
    },
    // 获取自增序号
    hIndex(index) {
      return index + 1;
    },
    queryUnits() {
      return productApi.queryUnits().then((list) => {
        this.units = list;
        this.unitList.basicUnit = [...list];
        // 查询分类
        // for (let i = 0; i < this.units.length; i++) {
        //   if (this.units[i].unitLevel === 1) {
        //     this.unitList.basicUnit.push(this.units[i])
        //     continue;
        //   }
        //   if (this.units[i].unitLevel === 2) {
        //     this.unitList.secUnit.push(this.units[i])
        //     continue;
        //   }
        //   if (this.units[i].unitLevel === 3) {
        //     this.unitList.thirdUnit.push(this.units[i])
        //     continue;
        //   }
        // }
      });
    },
    findById(id) {
      productApi.findById(id).then((data) => {
        // 多规格信息
        if (data.attributeItems) {
          data.productAttributeValue = JSON.parse(data.attributeItems);
        } else {
          // 没有设置
          data.productAttributeValue = {
            addStandard: true,
            attributeList: [],
          };
        }
        // 查询附件信息
        productApi.queryAttachmentFile(data.id).then(list => {
          let videoList = list.filter(it => it.type === 2);
          for (let i = 0; i < videoList.length; i++) {
            this.videoList.push({
              ...JSON.parse(videoList[i].attachment),
            })
          }
          let docList = list.filter(it => it.type === 1);
          for (let i = 0; i < docList.length; i++) {
            this.docList.push({
             ...JSON.parse(docList[i].attachment),
            })
          }
        })
        // 自定义属性
        if (data.productAttributes) {
          data.productAttributeList = JSON.parse(data.productAttributes);
        } else {
          data.productAttributeList = [];
        }

        // 单位
        this.unitRateList = [];
        this.unitRateList = [...data.productUnits];
        // 将单位按level进行排序
        this.unitRateList.sort((a, b) => {
          return a.level - b.level;
        });
        // 如果单位的长度不足三则添加
        if (this.unitRateList.length < 3) {
          for (
              let i = data.productUnits.length;
              i < this.unitTemp.length;
              i++
          ) {
            this.unitRateList.push(this.unitTemp[i]);
          }
        }

        this.detail = data;
        this.showSkuInfo();
      });
    },
    // 显示sku信息
    showSkuInfo() {
      // 获取sku规格信息
      if (
          !this.productAttributeList ||
          this.productAttributeList.length === 0
      ) {
        // 没有信息
        return;
      }
      if (!this.detail.skuList || this.detail.skuList.length === 0) {
        // 没有sku信息
        return;
      }
      let skuList = [...this.detail.skuList];
      // 找到属性集名称
      for (let i = 0; i < skuList.length; i++) {
        if (!skuList[i].skuAttributes) {
          // 没有属性
          skuList[i].skuAttributeList = [];
          continue;
        }
        let arr = JSON.parse(skuList[i].skuAttributes);
        // 找到属性集名称
        for (let j = 0; j < arr.length; j++) {
          let data = arr[j];
          for (let k = 0; k < this.productAttributeList.length; k++) {
            let attr = this.productAttributeList[k];
            if (data.id === attr.id) {
              // 找到数据
              let opList = attr.options ? JSON.parse(attr.options) : [];
              for (let l = 0; l < opList.length; l++) {
                if (data.value === opList[l].code) {
                  // 属性结果
                  data.name = opList[l].name;
                  // 属性名
                  data.columnName = attr.column;
                }
              }
              break;
            }
          }
        }
        skuList[i].skuAttributeList = arr;
      }
      this.detail.skuList = skuList;
    },
    // 商品单位转换率
    // queryProductUnitRates(id) {
    //   productApi.queryUnitRates(id).then(list => this.unitRateList = list);
    // },
    // 显示规格dialog
    addAttribute() {
      this.showAttribute = true;
    },
    addSku() {
      // 从最后一条sku复制
      let data = {};
      if (this.detail.skuList && this.detail.skuList.length > 0) {
        data = {
          ...this.detail.skuList[this.detail.skuList.length - 1],
          id: "",
        };
      }
      this.currentSkuFormat({...data, attributeObject: {}});
      this.showSkuDialog = true;
    },
    // 保存商品
    saveDetail(isTrue) {
      if (!this.detail.productName) {
        this.$message.error("请添加商品名称");
        return;
      }
      // 判断商品基本单位是否为空
      if (!this.checkBasicUnit()) {
        return;
      }
      // 设置基本单位为商品的单位，以及价格信息
      for (let i = 0; i < this.unitRateList.length; i++) {
        if (!this.unitRateList[i].unitId) {
          // 移除当前商品
          this.unitRateList.splice(i, 1);
          i--;
        }
        if (this.unitRateList[i].level === 1) {
          // 单位id
          this.detail.productUnitId = this.unitRateList[i].unitId;
          // 售价及进价
          this.detail.basePrice = this.unitRateList[i].salePrice; // 含税
          // this.detail.includeSalePrice = this.unitRateList[i].salePrice;
          this.detail.buyPrice = this.unitRateList[i].buyPrice; // 含税
          // this.detail.includeBuyPrice = this.unitRateList[i].buyPrice;
          // 条码
          this.detail.barCode = this.unitRateList[i].productUnitCode;
        }
      }

      //调用轮播图片，手动保存
      let data = {...this.detail};
      if (data.skuList) {
        for (let i = 0; i < data.skuList.length; i++) {
          if (!data.skuList[i].erpId) {
            delete data.skuList[i].erpId;
          }
        }
      }
      // 商品多规格
      if (data.productAttributeValue) {
        data.attributeItems = JSON.stringify(data.productAttributeValue);
      }
      // 商品自定义属性
      if (data.productAttributeList) {
        data.productAttributes = JSON.stringify(data.productAttributeList);
      }
      // 如果没有启用多规格
      if (!data.productAttributeValue.addStandard) {
        if (!data.skuList || data.skuList.length === 0) {
          // 没有sku信息，添加默认
          data.skuList = [
            {
              productId: this.detail.id,
              barCode: this.detail.barCode,
              // departCode: this.detail.departCode,
              pinyinCode: this.detail.pinyinCode,
              skuName: this.detail.productName,
              skuUnitId: this.detail.productUnitId,
              skuBuyPrice: this.detail.buyPrice,
              // skuIncludeBuyPrice: this.detail.buyPrice,
              skuSalePrice: this.detail.basePrice,
              // skuIncludeSalePrice: this.detail.basePrice,
              skuCode: this.detail.productCode,
              skuStandard: this.detail.productStandard,
              skuType: this.detail.productType,
              taxCode: this.detail.taxCode,
              taxCategory: this.detail.taxCategory,
              taxRate: this.detail.taxRate,
              warrantyPeriod: this.detail.warrantyPeriod,
              productBrade: this.detail.productBrade,
              fitAttr: this.detail.fitAttr,
              supplierId: this.detail.supplierId,
              scrapTime: this.detail.scrapTime,
              ownerShip: this.detail.ownerShip,
              productionId: this.detail.productionId,
              listedHolder: this.detail.listedHolder,
              validDate: this.detail.validDate,
              approvalNum: this.detail.approvalNum,
              originPlace: this.detail.originPlace,
              productionLicense: this.detail.productionLicense,
              mtUnitId: this.detail.mtUnitId,
              mtSettingId: this.detail.mtSettingId,
            },
          ];
        } else {
          // 有sku，同步更新
          data.skuList[0] = {
            ...data.skuList[0],
            productId: this.detail.id,
            barCode: this.detail.barCode,
            // departCode: this.detail.departCode,
            pinyinCode: this.detail.pinyinCode,
            skuName: this.detail.productName,
            skuUnitId: this.detail.productUnitId,
            skuBuyPrice: this.detail.buyPrice,
            skuSalePrice: this.detail.basePrice,
            skuCode: this.detail.productCode,
            skuStandard: this.detail.productStandard,
            skuType: this.detail.productType,
            taxCode: this.detail.taxCode,
            taxCategory: this.detail.taxCategory,
            taxRate: this.detail.taxRate,
            warrantyPeriod: this.detail.warrantyPeriod,
            productBrade: this.detail.productBrade,
            fitAttr: this.detail.fitAttr,
            supplierId: this.detail.supplierId,
            scrapTime: this.detail.scrapTime,
            ownerShip: this.detail.ownerShip,
            productionId: this.detail.productionId,
            listedHolder: this.detail.listedHolder,
            validDate: this.detail.validDate,
            approvalNum: this.detail.approvalNum,
            originPlace: this.detail.originPlace,
            productionLicense: this.detail.productionLicense,
            mtUnitId: this.detail.mtUnitId,
            mtSettingId: this.detail.mtSettingId,
          };
        }
      }

      // 同步更新商品信息到sku
      if (data.skuList) {
        for (let i = 0; i < data.skuList.length; i++) {
          // 需要同步的字段：品牌、规格、型号、分类、供应商
          data.skuList[i].productBrade = this.detail.productBrade;
          data.skuList[i].category = this.detail.category;
          data.skuList[i].skuStandard = this.detail.productStandard;
          data.skuList[i].skuType = this.detail.productType;
          data.skuList[i].supplierId = this.detail.supplierId;
          data.skuList[i].productionId = this.detail.productionId;
          data.skuList[i].listedHolder = this.detail.listedHolder;
          data.skuList[i].validDate = this.detail.validDate;
          data.skuList[i].approvalNum = this.detail.approvalNum;
          data.skuList[i].originPlace = this.detail.originPlace;
          data.skuList[i].productionLicense = this.detail.productionLicense;
          data.skuList[i].mtUnitId = this.detail.mtUnitId;
          data.skuList[i].mtSettingId = this.detail.mtSettingId;
        }
      }

      // 添加单位信息
      data.productUnits = [...this.unitRateList];

      const copyData = data;
      // 判断是否添加了sku
      productApi.save(data).then((data) => {
        // 保存附件
        productApi.saveFile(this.docList, data.id);
        productApi.saveFile(this.videoList, data.id);


        this.detail.id = data.id;
        if (isTrue) {
          if (this.isCopy) {
            this.$routerUtil.push({
              path: `/product/list/detail`,
              query: {
                copyInfo: JSON.stringify(copyData),
              },
            });
          } else {
            this.$routerUtil.push(`/product/list/detail`);
          }
        } else {
          this.$routerUtil.push(`/product/list`);
        }
        // this.findById(data.id);
      });
    },
    //使用v-model绑定获取editor信息报错，改用组件传参
    getEditorValue(value) {
      this.detail.productDetail = value;
    },
    addNewUnit() {
      this.currentUnit = {};
      this.showUnitDetail = true;
    },
    editUnit(data) {
      this.currentUnit = {
        ...data,
      };
      this.showUnitDetail = true;
    },
    deleteUnit(data) {
      this.$confirm(`是否确认删除单位[${data.unitName}]`).then(() => {
        productApi.deleteUnit(data.id).then(() => this.queryUnits());
      });
    },
    saveUnit() {
      productApi.saveUnit(this.currentUnit).then(() => {
        this.queryUnits();
        this.showUnitDetail = false;
      });
    },
    addUnit2Product() {
      // this.selectUnitAble = true;
      // this.showUnits = true;
    },
    selectUnit(data) {
      this.newUnits = data;
    },
    // 选中某个单位
    // unitClick(row) {
    //   util.tableSelectionRowClick("id", row, this.newUnits, this.units, this.$refs.unitDialogTable);
    // },
    // 保存转换率
    // addProductUnitRate() {
    //   if (!this.detail.id) {
    //     this.$message.error("请先保存商品");
    //     return;
    //   }
    //   let arr = [];
    //   // 转换率，找到新增的，不是新增的不处理
    //   this.newUnits.forEach(u => {
    //     let contains = false;
    //     for (let i = 0; i < this.unitRateList.length; i++) {
    //       if (this.unitRateList[i].unitId === u.id) {
    //         contains = true;
    //       }
    //     }
    //     if (!contains) {
    //       // 不存在，添加h
    //       arr.push({
    //         productId: this.detail.id,
    //         unitId: u.id,
    //         rate: 1,
    //       });
    //
    //     }
    //   });
    //   // 保存商品单位
    //   let idx = 0;
    //   for (let i = 0; i < arr.length; i++) {
    //     // 不存在，添加
    //     productApi.saveUnitRate(arr[i]).then(() => {
    //       idx++;
    //       if (idx === arr.length) {
    //         this.queryProductUnitRates(this.detail.id);
    //         this.showUnits = false;
    //       }
    //     });
    //   }
    // },
    // // 保存商品单位
    // saveUnitRate(data) {
    //   productApi.saveUnitRate(data).then(() => this.queryProductUnitRates(this.detail.id));
    // },
    // deleteUnitRate(data) {
    //   this.$confirm("是否确认删除商品单位").then(() => {
    //     productApi.deleteUnitRate(data.id).then(() => this.queryProductUnitRates(this.detail.id));
    //   })
    // },
    // 添加一条自定义属性
    addCustomerAttribute() {
      this.detail.productAttributeList.push({name: "", value: ""});
    },
    // 删除一条自定义属性
    deleteCustomerAttribute(idx) {
      this.detail.productAttributeList.splice(idx, 1);
    },
    // 显示商品规格的选择页面
    toShowAttributes() {
      this.showAttribute = true;
    },
    // 选中的商品属性集，添加到商品中
    addAttribute2Detail() {
      let arr = [];
      let old = this.detail.productAttributeValue.attributeList;
      for (let i = 0; i < this.productAttributeList.length; i++) {
        if (this.productAttributeList[i].checked) {
          // 选中了这个属性集
          // 看原来是否在商品中，如果在商品中，不进行覆盖
          let contains = false;
          for (let j = 0; j < old.length; j++) {
            if (this.productAttributeList[i].id === old[j].id) {
              // 已有这个数据
              contains = true;
              break;
            }
          }
          if (!contains) {
            arr.push({
              ...this.productAttributeList[i],
              optionList: this.productAttributeList[i].options
                  ? JSON.parse(this.productAttributeList[i].options)
                  : [], // 选项，变更为list
            });
          }
        }
      }
      // 进行拼接，把新的数据添加到老数据后面
      this.detail.productAttributeValue.attributeList = [
        ...this.detail.productAttributeValue.attributeList,
        ...arr,
      ];
      this.showAttribute = false;
    },
    // 从已选择的商品属性集中删除一条
    deleteAttributeFromProduct(idx) {
      this.detail.productAttributeValue.attributeList.splice(idx, 1);
    },
    // 添加一条新的sku
    toAddNewSku() {
      if (!this.detail.id) {
        this.$message.error("请先保存商品数据");
        return;
      }
      // // 开启多规格
      // if (this.detail.productAttributeValue.addStandard) {
      //
      // } else {
      //   // 未开启
      //
      // }
      if (!this.detail.productAttributeValue.addStandard) {
        this.currentSkuInfo.prodcutId = this.detail.id;
        this.currentSkuInfo.barCode = this.detail.barCode;
        this.currentSkuInfo.pinyinCode = this.detail.pinyinCode;
        this.currentSkuInfo.skuName = this.detail.productName;
        this.currentSkuInfo.skuUnitId = this.detail.productUnitId;
        this.currentSkuInfo.skuBuyPrice = this.detail.buyPrice;
        this.currentSkuInfo.skuSalePrice = this.detail.basePrice;
        this.currentSkuInfo.skuCode = this.detail.productCode;
        this.currentSkuInfo.skuStandard = this.detail.productStandard;
        this.currentSkuInfo.skuType = this.detail.productType;
        this.saveSkuSingle();
      }

    },
    // 添加一条新的sku
    getNewSku() {
      return {
        productId: this.detail.id,
        skuName: this.detail.productName,
        skuSalePrice: this.detail.basePrice,
        skuUnitId: this.detail.productUnitId,
        skuStandard: this.detail.productStandard,
        skuType: this.detail.productType,
        skuBuyPrice: this.detail.buyPrice,
        skuCode: this.detail.productCode,
        attributeObject: {},
      };
    },
    currentSkuFormat(data) {
      if (data.skuAttributeList && data.skuAttributeList.length > 0) {
        // 有对应的属性集
        let obj = {};
        for (let i = 0; i < data.skuAttributeList.length; i++) {
          obj[data.skuAttributeList[i].id] = {
            code: data.skuAttributeList[i].value,
          };
        }
        data.attributeObject = obj;
        this.currentSkuInfo = data;
        this.showSkuDialog = true;
        return;
      }
      // 商品已添加的属性集
      let attrs = this.detail.productAttributeValue.attributeList;
      for (let i = 0; i < attrs.length; i++) {
        data.attributeObject[attrs[i].id] = {code: ""};
      }
      this.currentSkuInfo = data;
      this.showSkuDialog = true;
    },
    // 编辑sku
    toEditSku(data) {
      // 复制sku信息，初始化显示
      this.currentSkuFormat({...data, attributeObject: {}});
    },
    // 单条sku保存
    saveSkuSingle() {
      let data = {
        ...this.currentSkuInfo,
        productId: this.detail.id,
      };
      if (data.attributeObject) {
        let arr = [];
        for (let k in data.attributeObject) {
          arr.push({id: k, value: data.attributeObject[k].code});
        }
        data.skuAttributes = JSON.stringify(arr);
      }
      if (!data.erpId) {
        delete data.erpId;
      }
      productApi.saveSku(data).then(() => {
        // 保存完成，查询sku列表
        this.showSkuDialog = false;
        productApi.querySkuList(this.detail.id).then((list) => {
          this.detail.skuList = list;
          this.showSkuInfo();
        });
      });
    },
    // 删除一个sku
    deleteSku(sku, index) {
      if (!sku.id) {
        // id不存在，这条数据还没有保存
        this.detail.skuList = this.detail.skuList.remove(index);
        return;
      }
      this.detail.skuList = this.detail.skuList.remove(index);
      productApi.deleteSku(sku.id);
      // productApi.deleteSku(sku.id).then(() => {
      //   // 移除这条数据，而不是刷新sku，否则未保存的数据将全部丢失
      //   productApi.querySkuList(this.detail.id).then(list => {
      //     this.detail.skuList = list;
      //     this.showSkuInfo();
      //   })
      // })
    },
    // 两个属性集实例是否相同
    isSkuAttributeSames(attrs, skus) {
      for (let i = 0; i < attrs.length; i++) {
        for (let j = 0; j < skus.length; j++) {
          if (attrs[i].id === skus[j].id && attrs[i].value === skus[j].value) {
            // 数据不同
            return false;
          }
        }
      }
      return true;
    },
    // 自动添加
    autoAddSku() {
      if (!this.detail.id) {
        this.$message.error("请先保存商品数据");
        return;
      }
      // 组装好的sku
      let arr = this.getAllAttributeSkus();
      let skuList = [];
      // 是否这个属性已经被添加成sku
      for (let i = 0; i < arr.length; i++) {
        let contains = true;
        for (let j = 0; j < this.detail.skuList.length; j++) {
          // 是否属性集相同
          if (
              !this.isSkuAttributeSames(
                  arr[i],
                  this.detail.skuList[j].skuAttributeList
              )
          ) {
            contains = false;
            break;
          }
        }
        // 没有这个sku
        if (!contains || this.detail.skuList.length === 1) {
          // 不相同，添加一个新的sku
          let data = this.getNewSku();
          // 只保存id和value
          let a = [];
          let names = [];
          arr[i].forEach((at) => {
            // 名称
            names.push(at.optionName);
            a.push({id: at.id, value: at.value});
          });
          data.skuName += names.join("");
          data.skuAttributeList = a;
          // 添加一个数据用于保存
          data.skuAttributes = JSON.stringify(a);
          data.skuCode = data.skuCode + skuList.length;
          skuList.push(data);
        }
      }
      this.detail.skuList = [...this.detail.skuList, ...skuList];
      this.showSkuInfo();
    },
    // 整理所有被选中的属性集，组合成属性实例
    getAllAttributeSkus() {
      let attrList = this.detail.productAttributeValue.attributeList;
      // 添加所有的sku
      let arrays = [[]];
      for (let i = 0; i < attrList.length; i++) {
        // 选项类型，每一个选项都要添加进去
        let newArr = [];
        for (let j = 0; j < attrList[i].optionList.length; j++) {
          // 深复制
          let arr = [];
          arrays.forEach((ar) => {
            let arr_ = [];
            ar.forEach((a) => arr_.push({...a}));
            arr.push(arr_);
          });
          arr.forEach((at) => {
            at.push({
              id: attrList[i].id,
              columnName: attrList[i].columnName,
              columnType: attrList[i].columnType,
              value: attrList[i].optionList[j].code,
              optionName: attrList[i].optionList[j].name,
              checked: attrList[i].optionList[j].checked,
            });
          });
          newArr = newArr.concat(arr);
        }
        arrays = newArr;
      }
      // 移除掉没有选中的数据
      let arr = [];
      for (let i = 0; i < arrays.length; i++) {
        // 是否有未选中的数据
        let checked = true;
        for (let j = 0; j < arrays[i].length; j++) {
          if (!arrays[i][j].checked) {
            checked = false;
            break;
          }
        }
        if (checked) {
          arr.push(arrays[i]);
        }
      }
      return arr;
    },
    // 文档上传前验证
    beforeDocUpload(file) {
      const isValidType = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ].includes(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isValidType) {
        ElMessage.error('请上传PDF、Word或Excel文件！')
        return false
      }
      if (!isLt10M) {
        ElMessage.error('文件大小不能超过 10MB!')
        return false
      }
      return true
    },
    // 文档上传成功
    handleDocSuccess(response, file) {
      this.docList.push({
        name: file.name,
        url: response.data,
        type: 1
      })
      ElMessage.success('文档上传成功')
    },
    // 上传失败
    handleUploadError() {
      ElMessage.error('上传失败，请重试')
    },
    // 预览文档
    previewDoc(file) {
      window.open(process.env.VUE_APP_FILE_URL + file.url);
    },
    // 删除文档
    removeDoc(index) {
      this.docList.splice(index, 1)
    },
    // 视频上传前验证
    beforeVideoUpload(file) {
      const isValidType = ['video/mp4', 'video/webm', 'video/ogg'].includes(file.type)
      const isLt100M = file.size / 1024 / 1024 < 100

      if (!isValidType) {
        ElMessage.error('请上传MP4、WebM或OGG格式的视频！')
        return false
      }
      if (!isLt100M) {
        ElMessage.error('视频大小不能超过 100MB!')
        return false
      }
      return true
    },
    // 视频上传成功
    handleVideoSuccess(response, file) {
      this.videoList.push({
        name: file.name,
        url: response.data,
        type: 2,
      })
      ElMessage.success('视频上传成功')
    },
    // 删除视频
    removeVideo(index) {
      this.videoList.splice(index, 1)
    },
    // 预览
    previewVideo(file) {
      console.log(file);
      window.open(process.env.VUE_APP_FILE_URL + file.url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/form.scss";
@import "@/assets/css/theme/handle.scss";
@import "@/assets/css/components/description.scss";
@import "@/assets/css/components/table.scss";
@import "@/assets/css/el-tables";
//.depart-select {
//  :deep(.el-descriptions__label) {
//    width: 1.2%;
//  }
//
//  :deep(.el-descriptions__label:after) {
//  }
//
//  :deep(.el-descriptions__content) {
//    width: 15%;
//    position: relative;
//  }
//
//  :deep(.el-textarea__inner) {
//    box-sizing: border-box;
//    padding: 10px !important;
//  }
//
//  :deep(.el-descriptions__label.require:before) {
//    content: "*";
//    font-size: 13px;
//    color: #F56C6C;
//    padding-right: 4px;
//  }
//}

//.add-in-table {
//  color: #409EFF;
//  font-weight: 600;
//  margin-left: 3px;
//}

.el-overlay {
  display: block !important;
}

.table-list {
  height: 480px !important;
}

.upload-container {
  .upload-list {
    margin-bottom: 16px;
    
    .file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      margin-bottom: 8px;
      background: #f8fafc;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      transition: all 0.3s ease;
      
      &:hover {
        background: #f1f5f9;
        border-color: #cbd5e1;
      }

      .file-info {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .el-icon {
          font-size: 20px;
          color: #64748b;
        }
        
        .file-name {
          color: #1e293b;
          font-size: 14px;
        }
      }

      .file-actions {
        display: flex;
        gap: 12px;
      }
    }
  }

  .upload-tip {
    font-size: 12px;
    color: #64748b;
    margin-top: 8px;
  }
}

:deep(.el-upload) {
  width: 100%;
  
  .el-button {
    width: 100%;
  }
}
</style>