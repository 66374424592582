<template>
  <!-- -->
  <div class="defaultViewStyle">

    <list-page>
      <table-list>
        <page-header>
          <template #default>
          </template>
        </page-header>
        <div>
          <el-descriptions :column="3" border size="small">
            <el-descriptions-item label="单据日期">
              <el-date-picker
                  style="width: 100%"
                  :disabled="isDisabled"
                  v-model="headInfo.createdTime"
                  type="date"
                  placeholder="选择日期时间"
              />
            </el-descriptions-item>

            <el-descriptions-item label="入库部门">
              <dictionary :disabled="isDisabled" @clearData="clearDepart" @change-data="changeDepart"
                          v-model="headInfo.departCode"
                          :dictionaries="departList" dict-code="departCode" dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item label="入库仓库">
              <el-select v-model="headInfo.stockId" :disabled="isDisabled" placeholder="选择仓库" size="default"
                         @change="toSelectStockChange">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="单据编号">
              <span>{{ headInfo.id ? headInfo.id : '请先保存' }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="出库部门">
              <dictionary :disabled="isDisabled" @change-data="changeTargetDepart" v-model="headInfo.targetDepartCode"
                          :place-holder="'请选择目标部门'"
                          :dictionaries="departList" dict-code="departCode" dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item label="出库仓库">
              <el-select v-model="headInfo.targetStockId" :disabled="isDisabled" placeholder="请先选择出库仓库"
                         size="default"
                         @change="toSelectStockChange">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="创建人">
              {{ headInfo.createdName }}
            </el-descriptions-item>

            <el-descriptions-item label="备注" :row="2">
              <el-input :disabled="isDisabled" v-model="headInfo.remark" type="textarea" :rows="1"
                        class="el-input-text"/>
            </el-descriptions-item>

          </el-descriptions>

          <div style="display: flex;justify-content: flex-end; height: 42px;">
            <div style="width: 300px">

              <product-list-select :query-list-api="queryListApi" :selected-product-list="headInfo.detailList" :query-params="{enabledOriginal: 1}"
                                   :show-stock="true" pla-title="请选择出库仓库" :is-stock="true" :stock-id="headInfo.targetStockId" @select-one="selectOne" :exists="1" product-id-column="originalCode"
                                   @select="selectProduct" @clear="deleteBatch"/>

<!--              <product-list-select :is-depart="true" :query-list-api="queryListApi" :selected-product-list="headInfo.detailList"-->
<!--                                   :stock-id="headInfo.targetStockId" :is-stock="true"-->
<!--                                   :pla-title="'请先在头部信息中选择目标仓库！'"-->
<!--                                   @select="selectProduct" @clear="deleteBatch"/>-->
            </div>
          </div>

        </div>
        <el-table stripe :data="headInfo.detailList" ref="finalTable">
          <el-table-column type="selection" width="55"/>
          <el-table-column align="center" type="index" label="序号" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="150px" show-overflow-tooltip/>
          <el-table-column align="center" prop="skuUnitName" label="基本单位" width="120px"/>
          <el-table-column align="center" prop="skuCode" label="商品条码" min-width="150px"/>

          <el-table-column align="center" label="数量" width="200">
            <template v-slot="{row}">
              {{ row.skuQty }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="buyPrice" label="成本单价">
            <template v-slot="{row}">
              <el-input :disabled="isDisabled" v-model="row.buyPrice" type="number"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="成本金额">
            <template v-slot="{row}">
              <money :value="row.skuQty * row.buyPrice"/>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{row,$index}">
              <order-original-code :show-merchant-code="true" :in-code="headInfo.originalCodeType !== 'MATERIAL'" :enabled-select="false" :stock-id="headInfo.stockId" :original-code-type="headInfo.originalCodeType"
                                   :disabled="isDisabled" @addOriginalCode="addOriginalCode2List($index, $event)"
                                   :code-list="row.originalCodeList" :sku-name="row.skuName"/>
              <table-button type="danger" message="确定移除该条数据吗？" el-type="danger" :disabled="isDisabled"
                            @click="deletedetailList(row,$index)">
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>

      <form-page :show-page="false">
        <page-button v-if="!isDisabled" type="write" el-type="success" @click="saveDraft">保存草稿</page-button>
        <page-button v-if="!isDisabled" type="write" el-type="primary" @click="saveSubmit">保存单据</page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>

import ListPage from '@/components/h5/layout/ListPage.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import elementElMessage from "@/util/elementElMessage";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import Money from "@/components/h5/Money.vue";
import restockApi from "@/api/main/stock/restockApi";
import OrderOriginalCode from "@/views/main/supplyChain/merchantOrder/components/OrderOriginalCode.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import recipientBackApi from "@/api/main/stock/recipientBackApi";
export default {
  name: "InStorageRecordDetail",
  computed: {},
  components: {
    OrderOriginalCode,
    Money, PageButton,
    TableButton,
    ListPage, TableList, ProductListSelect, Dictionary, PageHeader, FormPage
  },
  data() {
    return {
      isDisabled: false, //是否禁用部分组件
      searchInfo: {},
      showSaveProduct: false,
      productList: [],  //该商户可以入库的所有商品
      skuList: [], //入库商品对应的所有sku
      stockList: [], //仓库列表
      stockProduct: {}, //入库商品信息
      headInfo: {
        remark: "", //备注
        createdName: "", //创建
        createdTime: new Date(), //时间
        stockId: "",  //选择仓库
        status: null,
        departCode: "",
        detailList: [], //需要入库的商品
        originalCodeType: "", // 溯源码类型
      },
      queryListApi: {},
      allUnits: [],
      // 是否开启仓库挂载部门功能
      stockDepartSwitch: false,
      // 部门
      departList: [],
      // 当前选中的商品
      currentProduct: {
        skuOriginalCode: "",
      },
    };
  },
  created() {
    // 查询溯源码的类型
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.sysOriginalCode = setting.settingValue);
    // 查询单位
    commonApi.queryUnits().then(list => this.allUnits = list);
    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");
    // 查询商品的api
    this.queryListApi = stockInoutApi.queryProductAuthorizeList;
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);

  },
  mounted() {
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    } else {
      // 新建
      commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.headInfo.originalCodeType = setting.settingValue);
      util.getUserInfo().then(user => {
        this.headInfo.createdName = user.userName;
        // 查询仓库
        this.queryAllStockList();
      })
    }
  },
  methods: {
    // 溯源码数据添加到表格中
    addOriginalCode2List(idx, resList) {
      if (!this.headInfo.targetStockId) {
        this.$message.error("请先选择出库仓库！");
        return;
      }
      let arr = [...this.headInfo.detailList];
      // 此时进行检查提示
      recipientBackApi.checkoutSkuOriginalCode(resList, this.headInfo.targetStockId, arr[idx].skuId).then(() => {

        // console.log(this.skuOriginalList)
        // 没有码的数据不要
        // 找到商品
        arr[idx].originalCodeList = resList;
        arr[idx].qty = resList.length;
        arr[idx].skuQty = resList.length;
        this.headInfo.detailList = arr;
        this.showOriginalDialog = false;
      })
    },
    findById(id) {
      restockApi.findById(id).then(res => {
        this.headInfo = res;
        if (res.status !== 0) {
          this.isDisabled = true;
        }
        this.queryStockList();
        // if(res.departCode){
        //   this.changeDepart(res.departCode);
        // }
        // if(res.targetDepartCode){
        //   this.changeTargetDepart(res.targetDepartCode)
        // }
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryProductList();
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },
    deleteBatch() {
      let rows = this.$refs.finalTable.getSelectionRows();
      for (let i = 0; i < rows.length; i++) {
        this.headInfo.detailList.splice(this.headInfo.detailList.indexOf(rows[i]), 1);
      }
    },
    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          // 部门

          this.headInfo.departId = this.stockList[i].bankupId;
          break;
        }
      }
      for (let i = 0; i < this.departList.length; i++) {
        if (this.headInfo.departId === this.departList[i].id) {
          this.headInfo.departCode = this.departList[i].departCode;
        }
      }
    },
    deletedetailList(row, index) {
      this.headInfo.detailList.splice(index, 1);
    },
    saveDraft() {
      this.headInfo.status = 0;
      if (this.checkData()) {
        restockApi.saveDraft(this.headInfo).then(res => this.headInfo = res);
        // this.$routerUtil.push("/stock/instock");
      }
    },
    saveSubmit() {
      this.headInfo.status = 99;
      if (this.checkData()) {
        restockApi.saveSubmit(this.headInfo).then(res => {
          this.headInfo = res
          this.findById(res.id);
        });
      }
    },
    checkData() {
      if (!this.headInfo.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      if (!this.headInfo.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (this.headInfo.detailList.length === 0) {
        elementElMessage.toShowMessage("入库商品不能为空", "warning");
        return false;
      }
      return true;
    },
    selectOne(data) {
      // 判断是否已经存在
      if (this.headInfo.detailList.length > 0) {
        for (let i = 0; i < this.headInfo.detailList.length; i++) {
          if (this.headInfo.detailList[i].skuId === data.id) {
            for (let j = 0; j < this.headInfo.detailList[i].originalCodeList.length; j++) {
              if (this.headInfo.detailList[i].originalCodeList[j].originalCode === data.originalCode) {
                this.$message.error("该商品已经添加过了！");
                return;
              }
            }
          }
        }
      }
      if (!this.headInfo.detailList || this.headInfo.detailList === 0) {
        this.selectProduct([{...data}]);
        return;
      }
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        if (this.headInfo.detailList[i].skuId === data.id) {
          // 溯源码+1
          this.headInfo.detailList[i].originalCodeList = [...this.headInfo.detailList[i].originalCodeList, {originalCode: data.originalCode, inOriginalCode: data.inOriginalCode}]
          // 更新
          this.addOriginalCode2List(i, this.headInfo.detailList[i].originalCodeList);
          return;
        }
      }
      this.selectProduct([{...data}]);
    },
    // 商品组件选中商品后，回调显示
    selectProduct(productSkuList) {
      // 向detail中添加数据
      let arr = [...this.headInfo.detailList];
      console.log(arr)
      for (let i = 0; i < productSkuList.length; i++) {
        // 去重添加
        let contains = false;
        let repeatIndex = -1;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === productSkuList[i].id) {
            contains = true;
            repeatIndex = j;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: productSkuList[i].id,
            skuQty: productSkuList[i].skuQty,
            skuName: productSkuList[i].skuName,
            skuCode: productSkuList[i].skuCode,
            buyPrice: productSkuList[i].skuBuyPrice,
            salePrice: productSkuList[i].skuSalePrice,
            skuUnitId: productSkuList[i].skuUnitId,
            skuUnitName: productSkuList[i].skuUnitName,
            originalCodeList: [{originalCode: productSkuList[i].originalCode, inOriginalCode: productSkuList[i].inOriginalCode}],
          });
        }else{
          let repeatOriginal = false;
          for (let j = 0; j < arr.length; j++) {
            if (arr[j].skuId === productSkuList[i].id) {
              for (let k = 0; k < arr[j].originalCodeList.length; k++) {
                if(arr[j].originalCodeList[k].originalCode === productSkuList[i].originalCode){
                  repeatOriginal = true;
                  break;
                }
              }
            }
          }
          // 溯源码+1
          if(!repeatOriginal){
            arr[repeatIndex].originalCodeList = [...arr[repeatIndex].originalCodeList, {originalCode: productSkuList[i].originalCode, inOriginalCode: productSkuList[i].inOriginalCode}]
          }
        }
      }
      this.headInfo.detailList = arr;
      this.addOriginalCode2List(this.headInfo.detailList.length - 1, this.headInfo.detailList[this.headInfo.detailList.length - 1].originalCodeList);
    },

    changeDepart(data) {
      // 变更部门时，清空仓库
      this.headInfo.stockId = "";
      // 根据code获得部门id
      let dep = this.findDepartByCode(data, this.departList);
      if (dep.id) {
        // 部门有效
        this.queryAllStockList2(dep.id)
      }
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      commonApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 默认选中一个

        if (list.length > 0) {
          this.headInfo.stockId = list[0].id;
        }
      });

    },
    findDepartByCode(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].departCode) {
          return list[i];
        }
        if (list[i].children && list[i].children.length > 0) {
          let dep = this.findDepartByCode(code, list[i].children);
          if (dep.id) {
            /// 找到数据
            return dep;
          }
        }
      }
      return {};
    },
    clearDepart() {
      this.headInfo.departCode = "";
      this.headInfo.stockId = "";
      // 重新获取仓库
      this.queryStockList();
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.headInfo.createdName = user.userName;
          this.headInfo.employeeId = user.id;
          this.headInfo.departCode = user.mainDepartCode;
          this.headInfo.customerId = "10000";
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2(user.mainDepartId)
        }
      });
    },

    changeTargetDepart(data) {
      // 变更部门时，清空仓库
      this.headInfo.targetStockId = "";
      // 根据code获得部门id
      let dep = this.findTargetDepartByCode(data, this.departList);
      if (dep.id) {
        // 部门有效
        this.queryTargetAllStockList2(dep.id)
      }
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryTargetAllStockList2(departId) {
      commonApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        if (list.length > 0) {
          this.headInfo.targetStockId = list[0].id;
        }
      });
    },
    findTargetDepartByCode(code, list) {
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].departCode) {
          return list[i];
        }
        if (list[i].children && list[i].children.length > 0) {
          let dep = this.findTargetDepartByCode(code, list[i].children);
          if (dep.id) {
            /// 找到数据
            return dep;
          }
        }
      }
      return {};
    },


  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

//:deep(.el-dialog__title) {
//  font-size: 13px !important;
//}

:deep(.el-divider--horizontal) {
  margin: 10px 0 20px 0 !important;
}

.rotating-element {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.attachment-item {
  position: relative;
  color: #409eff;
  margin-right: 10px;

  .close {
    color: #F56C6C;
    margin-left: 5px;
    line-height: 31px;
  }
}

.attachment-item:last-child {
  margin-right: 0;
}

.table-all {
  height: calc(100% - 223px);
}

.table-all-suc {
  height: calc(100% - 185px);
}

.table-part {
  height: calc(100% - 97px);
}

.table-part-suc {
  height: calc(100% - 85px);
}


.top-all {
  height: 223px;
}

.top-all-suc {
  height: 185px;
}

.top-part {
  height: 97px;
}

.top-part-suc {
  height: 85px;
}


.el-input-text {
  :deep(textarea) {
    padding: 7px;
    box-sizing: border-box;
  }
}

.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}


</style>