<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header/>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="供应商名称" label-class-name="require">
            <client-select :disabled="dataDetail.status > 0" v-model="dataDetail.merchantId" :merchant-name="dataDetail.merchantName" :query-api="CommonApi.querySupplyMerchantList"/>
          </el-descriptions-item>
          <el-descriptions-item label="仓库名称" label-class-name="require">
            <el-select :disabled="dataDetail.status >= 10" v-model="dataDetail.clientStockId" @change="toSelectStockChange">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="部门" label-class-name="require">
            <dictionary v-model="dataDetail.clientDepartCode" :disabled="dataDetail.status !== 0"
                        @change-data="changeDepart" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>
          <el-descriptions-item label="业务人员">
            <employee-select v-model="dataDetail.clientEmployeeId" :disabled="dataDetail.status !== 0"/>
          </el-descriptions-item>
          <el-descriptions-item label="订单时间">
            <el-date-picker v-model="dataDetail.orderTime" type="datetime" style="width: 100%"  :disabled="dataDetail.status !== 0"/>
          </el-descriptions-item>
          <el-descriptions-item label="订单状态">
            {{ dataDetail.statusName }}
          </el-descriptions-item>
          <el-descriptions-item label="备注信息" :span="2">
            <el-input v-model="dataDetail.remark" type="textarea"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="display: flex;justify-content: flex-end; padding: 8px 0" v-if="dataDetail.status < 10">
          <div style="width: 400px;display: inline-block">
            <product-list-select :is-stock="true" :stock-id="dataDetail.clientStockId"  :before-query="beforeQuery" :query-params="{merchantId: dataDetail.merchantId,isMerBrade: true}" :selected-product-list="dataDetail.detail" @selectOne="addProductOne" @select="addProductList"/>
          </div>
        </div>
        <div style="height: calc(100% - 320px)">
          <el-table stripe :data="dataDetail.detail" height="100%">
            <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
            <el-table-column label="商品名称" prop="skuName" min-width="150px" show-overflow-tooltip/>
            <el-table-column align="center" label="商品编码" prop="skuCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column align="center" label="条码" prop="barCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="数量" prop="qty" width="100px">
              <template v-slot="{row}">
                <el-input v-model="row.qty" type="number" :disabled="dataDetail.status >= 10"/>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="unitName" width="100px" :align="'center'"/>
            <el-table-column label="采购价格" prop="salePrice" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice"/>
              </template>
            </el-table-column>
            <el-table-column label="订单金额" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice * row.qty"/>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60px" :align="'center'" fixed="right">
              <template v-slot="{row}">
                <table-button type="delete" el-type="danger" @click="deleteRow(row)">删除</table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false" v-if="dataDetail.status < 10">
        <page-button el-type="success" type="write" @click="saveDraft">保存草稿</page-button>
        <page-button el-type="primary" type="write" @click="saveSubmit">提交单据</page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import merchantPurchaseApi from "@/api/main/supplyChain/merchantPurchaseApi";
import Money from "@/components/h5/Money.vue";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import CommonApi from "@/api/commonApi";
import {ElMessageBox} from "element-plus";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue"
import stockInStorageApi from "@/api/main/stock/stockInStorageApi";
export default {
  name: "",
  computed: {
    stockInStorageApi() {
      return stockInStorageApi
    },
    CommonApi() {
      return CommonApi
    }
  },
  components: {
    PageHeader,
    TableButton,
    PageButton, EmployeeSelect,
    ListPage, TableList, FormPage, ProductListSelect, ClientSelect, Dictionary, Money
  },
  data() {
    return {
      dataDetail: {
        clientEmployeeId: "",
        detail: [],
        clientEmployeeName: "",
        status: 0,
        clientStockId: "",
        clientDepartCode: "",
      },
      departList: [],
      stockList: [],
      stockDepartSwitch: false,
    };
  },
  created() {
    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");
    // 查询商品时，查询供应商的商品
    // 查询部门
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询仓库
    this.queryAllStockList();
  },
  methods: {
    // 仓库变更，同步变更部门
    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.dataDetail.clientStockId === this.stockList[i].id) {
          // 部门
          this.dataDetail.clientDepartId = this.stockList[i].bankupId;
          break;
        }
      }
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.clientDepartId === this.departList[i].id) {
          this.dataDetail.clientDepartCode = this.departList[i].departCode;
        }
      }
    },
    deleteRow(row){
      ElMessageBox.confirm("确定删除该行数据？", "提示", {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let idx = this.dataDetail.detail.findIndex(item => item.id === row.id);
        if(idx !== -1){
          this.dataDetail.detail.splice(idx, 1);
          this.$message.success("移除成功");
        }
      }).catch(() => this.$message.info("已取消"));
    },
    findById(id) {
      merchantPurchaseApi.findById(id).then(data => this.dataDetail = data);
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.clientEmployeeName = user.userName;
          this.dataDetail.clientEmployeeId = user.id;
          this.dataDetail.clientDepartCode = user.mainDepartCode;
          this.dataDetail.statusName = "草稿";
          this.dataDetail.orderTime = new Date();
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2();
        } else {
          this.findById(this.$routerUtil.query.id);
          this.queryAllStockList2();
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      commonApi.queryAllStock(departId).then(list => {
        this.stockList = list;
        // 新建时默认选中仓库
        if (!this.$routerUtil.query.id && list.length > 0) {
          this.dataDetail.clientStockId = list[0].id;
          this.toSelectStockChange();
        }
      });
    },
    // 部门变更时,重新查询仓库
    changeDepart(data) {
      this.queryAllStockList2(data.dictCode);
    },
    // 根据商品，添加一行明细
    createDetailData(product) {
      return {
        skuId: product.id,
        skuName: product.skuName,
        skuCode: product.skuCode,
        barCode: product.barCode,
        salePrice: product.skuSalePrice,
        buyPrice: product.skuBuyPrice,
        unitName: product.skuUnitName,
        unitId: product.skuUnitId,
        qty: 1,
        rate: 1,
      }
    },
    // 查询商品前，确认已经选择了供应商
    beforeQuery() {
      if (!this.dataDetail.merchantId) {
        this.$message.error("请选择供应商")
        return false;
      }
      return true;
    },
    // 添加一个商品
    addProductOne(data) {
      // 如果没有，则添加
      let contains = false;
      for (let j = 0; j < this.dataDetail.detail.length; j++) {
        if (data.id === this.dataDetail.detail[j].skuId) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.detail.push(this.createDetailData(data));
      }
    },
    // 添加多个商品
    addProductList(list) {
      for (let i = 0; i < list.length; i++) {
        this.addProductOne(list[i])
      }
    },
    checkData() {
      if (!this.dataDetail.merchantId) {
        this.$message.error("请选择供应商")
        return false;
      }
      if (!this.dataDetail.clientStockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (!this.dataDetail.clientDepartCode) {
        this.$message.error("请选择部门");
        return false;
      }
      return true;
    },
    saveDraft() {
      if (!this.checkData()) {
        return;
      }
      if(!this.dataDetail.orderType){
        this.dataDetail.orderType = 1;
      }
      merchantPurchaseApi.saveDraft(this.dataDetail).then(data => this.findById(data.id));
    },
    saveSubmit() {
      if (!this.checkData()) {
        return;
      }
      if(!this.dataDetail.orderType){
        this.dataDetail.orderType = 1;
      }
      merchantPurchaseApi.saveSubmit(this.dataDetail).then(data => this.findById(data.id));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
:deep(.el-descriptions){
  margin-bottom: 0 !important;
}
</style>