<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="bindMerchantMode">绑定商家码</page-button>
          <page-button el-type="primary" @click="generate">生成溯源码</page-button>
          <page-button el-type="warning" @click="showBindNextTime = true">设置下次维保</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <el-input v-model="searchInfo.searchKey" placeholder="请扫描或输入溯源码" @keydown.enter="resetSearch"/>
          <el-input v-model="searchInfo.name" placeholder="请输入商品编号/名称/型号" @keydown.enter="resetSearch"/>
          <div style="display: inline-block; margin-right: 10px; width: 240px">
            <dictionary v-model="searchInfo.category" :dictionaries="categoryList" dict-code="dictCode"
                        dict-name="dictName" :place-holder="'请选择商品分类'"
                        @clear-data="clearCode" style="width: 100%"/>
          </div>
        </template>
        <template #moreCondition>
          <el-select v-model="searchInfo.stockStatus" clearable
                     placeholder="请选择状态" style="width: 240px">
            <el-option label="在库" :value="1" />
            <el-option label="维修" :value="2" />
            <el-option label="领用" :value="3" />
            <el-option label="报废" :value="4" />
          </el-select>
          <el-select v-model="searchInfo.isExist" clearable
                     placeholder="请选择是否添加溯源码" style="width: 240px">
            <el-option label="已添加" :value="1" />
            <el-option label="未添加" :value="2" />
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" ref="multipleTable" height="100%"  @row-click="clickRow">
          <el-table-column align="center" type="selection" width="50px" fixed/>
          <el-table-column align="center" type="index" label="序号" width="60px" fixed/>
          <el-table-column align="center" label="商品状态" min-width="160px">
            <template v-slot="{row}">
              <span v-if="row.wzType === 0">在库</span>
              <span v-if="row.wzType === 1">使用</span>
              <span v-if="row.wzType === 2">报废</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="维保状态" min-width="120px">
            <template v-slot="{row}">
              <span v-if="row.stockStatus === 1">正常</span>
              <span v-if="row.stockStatus === 2">维修</span>
              <span v-if="row.stockStatus === 3">正常</span>
              <span v-if="row.stockStatus === 4">报废</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品分类" prop="categoryName" min-width="160px" show-overflow-tooltip />
<!--          <el-table-column align="center" label="商品编号" prop="skuCode" min-width="120px" show-overflow-tooltip />-->
          <el-table-column align="center" label="商品名称" prop="skuName" min-width="180px" show-overflow-tooltip />
<!--          <el-table-column align="center" label="规格" prop="skuStandard" min-width="120px" show-overflow-tooltip>-->
<!--            <template v-slot="{row}">-->
<!--              <span v-if="row.skuStandard">{{row.skuStandard}}</span>-->
<!--              <span v-else>-</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column align="center" label="型号" prop="skuType" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.skuType">{{row.skuType}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="溯源码" prop="originalCode" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.originalCode">{{row.originalCode}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="商家码" prop="originalCode" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.inOriginalCode">{{row.inOriginalCode}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="仓库" prop="stockName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.stockName">{{row.stockName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="使用机构" prop="recipientDepartName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.recipientDepartName">{{row.recipientDepartName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="使用人" prop="recipientName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.recipientName">{{row.recipientName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="购入日期" prop="firstInstockTime" min-width="200px" show-overflow-tooltip />
          <el-table-column align="center" label="报废日期" prop="scrapTime" min-width="160px" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.scrapTime ? row.scrapTime.split(" ")[0] : "-" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="维保单位" prop="maintainUnitName" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.maintainUnitName">{{row.maintainUnitName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="上次维保日期" prop="lastMtTime" min-width="200px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-if="row.lastMtTime">{{row.lastMtTime}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="维保码" min-width="200px" show-overflow-tooltip>
            <template v-slot="{row}">
              <div style="display: flex;align-items: center;justify-content: center">
                <el-image style="width: 50px; height: 30px" :preview-src-list="[row.miniProgramCode]" :preview-teleported="true"
                          show-progress :src="row.miniProgramCode" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" fit="cover" :initial-index="1">
                  <template #error>
                    <div style="display: flex; align-items: center;justify-content: center">无</div>
                  </template>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" min-width="120px">
            <template v-slot="{row}">
              <table-button type="read" el-type="primary" @click="toView(row)">查看</table-button>
              <table-button type="read" el-type="primary" :disabled="!row.originalCode" @click="gen(row)">小程序码</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>

    <!-- 添加商家码绑定弹窗 -->
    <el-dialog v-model="showBindDialog" title="绑定商家码" width="680px" class="merchant-bind-dialog">
      <div class="bind-merchant-container">
        <!-- 新的商品选择部分 -->
        <div class="product-select-section">
          <!-- 搜索输入框 -->
          <div class="search-box"  v-if="!selectedProduct">
            <el-input
              v-model="searchKeyword"
              placeholder="搜索商品名称/编号"
              clearable
              @input="handleSearch"
            >
              <template #prefix>
                <el-icon><Search /></el-icon>
              </template>
            </el-input>
          </div>

          <div class="match-rules" v-if="!selectedProduct">
            <div class="rules-header">
              <el-icon><InfoFilled /></el-icon>
              <span>绑定规则</span>
            </div>
            <div class="rules-content">
              <div class="rule-item">
                <div class="rule-dot"></div>
                <span>绑定专属编码前,必须先生成系统编码</span>
              </div>
              <div class="rule-item">
                <div class="rule-dot"></div>
                <span>只能给在库商品进行编码绑定</span>
              </div>
              <div class="rule-item">
                <div class="rule-dot"></div>
                <span style="color: #9f0e0e;font-weight: 600">编码一经绑定后无法更换，请谨慎绑定！！！</span>
              </div>
            </div>
          </div>

          <!-- 商品卡片列表 -->
          <div class="product-cards" v-if="!selectedProduct">
            <div 
              v-for="item in filteredProducts" 
              :key="item.id"
              class="product-card"
              :class="{ 'has-unbind': getUnbindCount(item) > 0 }"
              @click="handleProductSelect(item)"
            >
              <div class="card-main">
                <div class="product-info">
                  <h3 class="name">{{ item.skuName }}</h3>
                  <p class="code">{{ item.skuCode }}</p>
                </div>
                <div class="unbind-tag">
                  <span class="count">{{ getUnbindCount(item) }}</span>
                  <span class="label">待绑定</span>
                </div>
              </div>
              <div class="card-footer">
                <span class="spec">规格：{{ item.skuStandard ? item.skuStandard : '无' }}</span>
                <span class="type">型号：{{ item.skuType ? item.skuType : '无' }}</span>
              </div>
            </div>
          </div>

          <!-- 已选商品详情 -->
          <div v-else class="selected-product">
            <div class="product-detail-card">
              <div class="card-header">
                <div class="header-left">
                  <el-button link class="back-btn" @click="selectedProduct = null">
                    <el-icon><Back /></el-icon>
                    返回选择
                  </el-button>
                </div>
              </div>
              <div class="card-content">
                <!-- 商品基本信息 -->
                <div class="product-basic-info">
                  <div class="product-title">
                    <h2>{{ selectedProduct.skuName }}</h2>
                    <el-tag type="warning" effect="dark" class="unbind-count" round>
                      {{ getUnbindCount(selectedProduct) }} 待绑定
                    </el-tag>
                  </div>
                  <!-- <p class="product-code">{{ selectedProduct.skuCode }}</p> -->
                </div>

                <!-- 商品详细信息 -->
                <div class="product-details">
                  <div class="detail-item">
                    <div class="icon-wrapper">
                      <el-icon><Document /></el-icon>
                    </div>
                    <div class="detail-content">
                      <span class="label">规格</span>
                      <span class="value">{{ selectedProduct.skuStandard || '-' }}</span>
                    </div>
                  </div>
                  <div class="detail-item">
                    <div class="icon-wrapper">
                      <el-icon><Box /></el-icon>
                    </div>
                    <div class="detail-content">
                      <span class="label">型号</span>
                      <span class="value">{{ selectedProduct.skuType || '-' }}</span>
                    </div>
                  </div>
                  <div class="detail-item">
                    <div class="icon-wrapper status">
                      <el-icon><Link /></el-icon>
                    </div>
                    <div class="detail-content">
                      <span class="label">待绑定数量</span>
                      <span class="value highlight">{{ getUnbindCount(selectedProduct) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 商家码输入部分 -->
        <div v-if="selectedProduct" class="merchant-code-section">
          <div class="section-header">
            <span class="title">商家码录入</span>
            <span class="subtitle">请输入商家码，按回车继续添加</span>
          </div>
          <div class="code-inputs">
            <div v-for="(code, index) in merchantCodes" :key="index" class="code-input-item">
              <el-input 
                v-model="merchantCodes[index]"
                :placeholder="`商家码 ${index + 1}`"
                @keyup.enter="handleEnterPress(index)"
              >
                <template #prefix>
                  <div class="input-prefix">{{ index + 1 }}</div>
                </template>
                <template #append>
                  <el-button 
                    @click="removeMerchantCode(index)" 
                    :disabled="index === 0"
                    class="remove-btn"
                  >
                    <el-icon><Delete /></el-icon>
                  </el-button>
                </template>
              </el-input>
            </div>
          </div>
        </div>
      </div>
      
      <template #footer>
        <div class="dialog-footer" v-if="selectedProduct" style="padding: 0 15px 15px 15px;"> 
          <page-button el-type="info" @click="showBindDialog = false">取消</page-button>
          <page-button el-type="primary" @click="bindMerchantCodes">确认绑定</page-button>
        </div>
      </template>
    </el-dialog>

    <yiqi-dialog v-model="showBindNextTime" title="设置维保时间" :warn-title-list="warnTitleList"  @save-data="bingNextTime">
      <template #title>
        注意：
      </template>
    </yiqi-dialog>
  </div>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import mtLegderApi from "@/api/main/material/mtLegderApi";
import commonApi from "@/api/commonApi";
import {Delete, Search, Back, Document, Box, Link, InfoFilled} from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import yiqiDialog from "@/components/h5/dialog/yiqi-dialog.vue";
export default {
  components: {
    InfoFilled, Dictionary, PageButton, FormPage, TableButton,
    ListPage, SearchCondition, TableList, DateRange,  PageHeader, ProductListSelect,
    Search, Back, Delete, Document, Box, Link, ElLoading, yiqiDialog
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      categoryList: [],
      showBindDialog: false,
      selectedProduct: null,
      merchantCodes: [''],
      uniqueProducts: [],
      searchKeyword: '',
      filteredProducts: [],

      bindCodeList: [],

      // 下次维保
      showBindNextTime: false,
      warnTitleList: [
        {warnTitle: '自动绑定规则需在商品档案中进行设置'},
        {warnTitle: '只会对已生成系统码且在库的商品进行绑定'},
        {warnTitle: '需要自动绑定的商品下次维保时间必须为空'},
        {warnTitle: '该操作消耗时间较长，请谨慎操作！！！'},
      ]
    };
  },
  created() {
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  methods: {
    bingNextTime(){
      const load = ElLoading.service({
        lock: true,
        text: '正在设置中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      mtLegderApi.saveNextMtTime2Batch().finally(() => load.close())
    },
    gen(row){
      // 生成二维码信息、返回一个zip文件
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = `${process.env.VUE_APP_BASE_URL}/order/base/getStoreCode?storeId=${row.originalCode}&storeName=${row.skuName}`;
      document.body.appendChild(link);
      link.click();
    },
    clickRow(data) {
      data.checked = !data.checked
      this.$refs.multipleTable.toggleRowSelection(data, data.checked);
    },
    generate(){
      // 生成
      let originalList = this.$refs.multipleTable.getSelectionRows();
      // 删除已添加溯源码的数据
      let list = originalList.filter(item => !item.originalCode);
      //
      if(list.length < originalList.length){
        this.$message.error("请选择未生成溯源码的台账信息！");
        return;
      }
      // 按skuId进行分类
      let map = new Map();
      if(list.length === 0){
        this.$message.error("请选择台账信息！");
        return;
      }
      for (let i = 0; i < list.length; i++) {
        if(map.has(list[i].id)){
          let sdList = map.get(list[i].id);
          sdList.push(list[i]);
          map.set(list[i].id, sdList);
        }else{
          map.set(list[i].id, [list[i]]);
        }
      }
      let resultList = [];
      // 遍历
      map.forEach((value, key) => {
        resultList.push({
          skuId: key,
          count: value.length,
          skuName: value[0].skuName
        })
      })
      mtLegderApi.toGenerate(resultList).then(data => {
        // mtLegderApi.exportData(data);
        // util.export3Excel("/order/material/ledger/exportData", data);
      });
    },
    queryList(){
      mtLegderApi.queryList(this.searchInfo).then(data => {
        this.dataList = data.records;
        this.dataList.forEach(it => it.miniProgramCode = process.env.VUE_APP_FILE_URL + it.miniProgramCode)
        this.searchInfo.totalCount = data.totalCount;
      });
    },
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    toView(data){
      this.$routerUtil.push(`/stock/ledger/detail?id=${data.productId}&code=${data.originalCode}`);
    },
    // 移除分类编码
    clearCode() {
      this.searchInfo.category = "";
    },
    // 打开绑定商家码弹窗
    bindMerchantMode() {
      const load = ElLoading.service({
        lock: true,
        text: '正在查询待绑定数据...',
        background: 'rgba(0, 0, 0, 0.7)',
      });


      mtLegderApi.queryAll(this.searchInfo).then(list => {
        this.bindCodeList = list;
        this.showBindDialog = true;
        this.selectedProduct = null;
        this.merchantCodes = [''];
        this.uniqueProducts = this.getUniqueProducts();
        this.filteredProducts = this.uniqueProducts;
        this.searchKeyword = '';
      }).finally(() => load.close())
    },
    // 获取唯一商品列表
    getUniqueProducts() {
      const productMap = new Map();
      this.bindCodeList.forEach(item => {
        if (!productMap.has(item.id)) {
          productMap.set(item.id, item);
        }
      });
      return Array.from(productMap.values());
    },
    // 获取未绑定数量
    getUnbindCount(product) {
      return this.bindCodeList.filter(item => 
        item.id === product.id && !item.inOriginalCode
      ).length;
    },
    // 处理回车按键
    handleEnterPress(index) {
      if (this.merchantCodes[index]) {
        if (index === this.merchantCodes.length - 1) {
          // 在最后一个输入框按回车，新增一个输入框
          this.merchantCodes.push('');
        }
        // 聚焦到下一个输入框
        this.$nextTick(() => {
          const inputs = document.querySelectorAll('.code-input-item input');
          if (inputs[index + 1]) {
            inputs[index + 1].focus();
          }
        });
      }
    },
    // 移除商家码输入框
    removeMerchantCode(index) {
      this.merchantCodes.splice(index, 1);
    },
    // 绑定所有商家码
    bindMerchantCodes() {
      const validCodes = this.merchantCodes.filter(code => code.trim());
      if (validCodes.length === 0) {
        this.$message.warning('请至少输入一个商家码');
        return;
      }
      
      const unbindCount = this.getUnbindCount(this.selectedProduct);
      if (validCodes.length > unbindCount) {
        this.$message.warning(`该商品只有 ${unbindCount} 个未绑定记录`);
        return;
      }
      
      // 获取未绑定记录
      // const unbindRecords = this.bindCodeList
      //   .filter(item => item.id === this.selectedProduct.id && !item.inOriginalCode)
      //   .slice(0, validCodes.length);

      // 调用API进行绑定
      mtLegderApi.bindCode(validCodes, this.selectedProduct.id).then(res => {
        this.$message.success('商家码绑定成功');
        this.queryList();
        this.showBindDialog = false;
      })
    },
    handleSearch(val) {
      if (!val) {
        this.filteredProducts = this.uniqueProducts;
        return;
      }
      const keyword = val.toLowerCase();
      this.filteredProducts = this.uniqueProducts.filter(item => 
        item.skuName.toLowerCase().includes(keyword) ||
        item.skuCode.toLowerCase().includes(keyword)
      );
    },
    handleProductSelect(item) {
      this.selectedProduct = item;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.merchant-bind-dialog {
  :deep(.el-dialog__body) {
    padding: 20px 24px;
  }
  :deep(.el-dialog__footer) {
    border-top: 1px solid #f0f2f5;
    padding-top: 0 !important;

    .dialog-footer {
      padding: 0 20px 20px 20px;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}

:deep(.el-dialog__footer) {
  padding-top: 0 !important;
}

.bind-merchant-container {
  .product-select-section {
    margin-bottom: 24px;


    .match-rules {
      background: #fff;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 20px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
      transition: all 0.4s ease;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
      }

      .rules-header {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;
        color: #1f2937;
        font-weight: 500;
        font-size: 14px;

        .el-icon {
          color: #60a5fa;
          font-size: 16px;
        }
      }

      .rules-content {
        padding: 0 4px;

        .rule-item {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 8px;
          color: #4b5563;
          font-size: 13px;

          &:last-child {
            margin-bottom: 0;
          }

          .rule-dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #60a5fa;
            flex-shrink: 0;
          }
        }
      }
    }
    
    .search-box {
      margin-bottom: 20px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);

      :deep(.el-input) {
        .el-input__wrapper {
          box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
        }

      }
    }

    
    .product-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 16px;
      max-height: 300px;
      overflow-y: auto;
      padding-right: 8px;
      margin-bottom: 20px;
      
      &::-webkit-scrollbar {
        width: 6px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #dcdfe6;
        border-radius: 3px;
      }
      
      .product-card {
        background: #fff;
        border-radius: 8px;
        padding: 16px;
        cursor: pointer;
        border: 1px solid #ebeef5;
        transition: all 0.3s ease;
        
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        }
        
        &.has-unbind {
          background: #fdf6ec;
          border-color: #faecd8;
        }
        
        .card-main {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 12px;
          
          .product-info {
            .name {
              font-size: 15px;
              font-weight: 500;
              color: #303133;
              margin: 0 0 4px;
            }
            
            .code {
              font-size: 13px;
              color: #909399;
              margin: 0;
            }
          }
          
          .unbind-tag {
            background: #e6a23c;
            color: #fff;
            padding: 4px 8px;
            border-radius: 12px;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .count {
              font-size: 14px;
              font-weight: bold;
            }
            
            .label {
              font-size: 11px;
              opacity: 0.9;
            }
          }
        }
        
        .card-footer {
          display: flex;
          gap: 12px;
          font-size: 12px;
          color: #606266;
          
          .spec, .type {
            background: #f5f7fa;
            padding: 2px 8px;
            border-radius: 4px;
          }
        }
      }
    }
  }
  
  .merchant-code-section {
    .section-header {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 12px;
      
      .title {
        font-size: 15px;
        font-weight: 500;
        color: #303133;
      }
      
      .subtitle {
        color: #909399;
        font-size: 13px;
      }
    }
    
    .code-inputs {
      .code-input-item {
        margin-bottom: 12px;
        
        &:last-child {
          margin-bottom: 0;
        }
        
        :deep(.el-input) {
          .el-input__wrapper {
            padding-left: 0;
            border-radius: 4px;
            box-shadow: 0 0 0 1px #dcdfe6 inset !important;
          }
          
          .input-prefix {
            width: 40px;
            text-align: center;
            background: #f5f7fa;
            color: #909399;
            margin-right: 8px;
            border-right: 1px solid #dcdfe6;
            padding: 0 8px;
            font-weight: 500;
            font-size: 13px;
          }
          
          .remove-btn {
            color: #f56c6c;
            border-color: #f56c6c;
            
            &:hover {
              background: #fef0f0;
            }
            
            &[disabled] {
              color: #c0c4cc;
              border-color: #e4e7ed;
              background: #fff;
            }
          }
        }
      }
    }
  }
}

.product-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  
  .product-info-brief {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .product-name {
      color: #303133;
      font-size: 14px;
    }
    
    .product-code {
      color: #909399;
      font-size: 12px;
    }
  }
}

:deep(.product-select-dropdown) {
  .el-select-dropdown__item {
    padding: 0 12px;
  }
}

.selected-product {
  .product-detail-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    
    .card-header {
      padding: 16px 24px;
      border-bottom: 1px solid #f0f2f5;
      
      .back-btn {
        font-size: 14px;
        color: #606266;
        
        &:hover {
          color: #409EFF;
        }
        
        .el-icon {
          margin-right: 4px;
        }
      }
    }
    
    .card-content {
      padding: 24px;
      
      .product-basic-info {
        margin-bottom: 32px;
        
        .product-title {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 8px;
          
          h2 {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            color: #303133;
          }
          
          .unbind-count {
            padding: 6px 16px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        
        .product-code {
          margin: 0;
          font-size: 15px;
          color: #909399;
        }
      }
      
      .product-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        
        .detail-item {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 16px;
          background: #f8fafc;
          border-radius: 8px;
          transition: all 0.3s ease;
          
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
          }
          
          .icon-wrapper {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background: #ecf5ff;
            color: #409EFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            
            &.status {
              background: #fdf6ec;
              color: #e6a23c;
            }
          }
          
          .detail-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
            
            .label {
              font-size: 13px;
              color: #909399;
            }
            
            .value {
              font-size: 15px;
              color: #303133;
              font-weight: 500;
              
              &.highlight {
                color: #e6a23c;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>