<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <!--        <page-button v-if="isDetail" @click="isDetail = false">返回</page-button>-->
        <div style="display: flex;align-items: center">
          <el-dropdown split-button type="primary" @click="toPrint" @command="handlerSelect" style="margin: 2px 10px 0 0">
            打印
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="Edit" command="setPrint">打印设置</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <page-button el-type="primary" type="print" @click="exportData">导出</page-button>

          <page-button el-type="primary" @click="resetSearch">查询</page-button>
        </div>

      </template>
    </page-header>
    <list-page v-if="isDetail">
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <div  v-if="readAccess === '2' || readAccess === '3'" class="customer-select" style="display: inline-block; width: 240px">
            <employee-select :placeholder-tit="'请选择收费员'" v-model="searchInfo.employeeId"
                             @selectOne="setEmployee" :access="readAccess"/>
          </div>

          <div style="display: inline-block;width: 240px" v-if="readAccess === '3'">
            <dictionary v-model="searchInfo.departCode" @clearData="clearDic" :place-holder="'请选择部门'"
                        :dictionaries="departList"
                        :is-multiple="true"
                        @change-data="setDepartCode"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" show-summary :summary-method="getSummary">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="账户名称" show-overflow-tooltip min-width="150px">
            <template v-slot="{row}">
              <span v-for="(p, i) in payTypeList" :key="i">
                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="收款金额" prop="saleTotalAmount" show-overflow-tooltip
                           min-width="100px"/>
          <el-table-column align="center" label="收款笔数" prop="saleTotalQty" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="退款金额" prop="reduceAmount" min-width="150px"/>
          <el-table-column align="center" label="退款笔数" prop="refundTotalQty" min-width="100px"/>
          <el-table-column align="center" label="交易笔数" prop="totalQty" min-width="100px"/>
          <el-table-column align="center" label="流水金额" prop="totalAmount" min-width="100px"/>
          <el-table-column label="操作" :align="'center'" min-width="200px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="toShowDetail(row)">查看明细</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 v-model:total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import util from "@/util/util";
import systemPayApi from "@/api/main/sys/systemPayApi";
import {Edit} from "@element-plus/icons-vue";
import {h} from "vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import localPrint from "@/util/new/LocalPrint";

export default {
  name: "DailySettle",
  computed: {
    Edit() {
      return Edit
    }
  },
  components: {
    Dictionary,
    FormPage, TableList, TableButton,
    PageHeader,
    EmployeeSelect,
    DateRange, SearchCondition,
    PageButton,
    ListPage
  },
  data() {
    return {
      isDetail: true,
      searchInfo: {},
      dataList: [],
      printRowData: {},
      payTypeList: [],
      departList: [],
      batchInfo:{},
      // 设置读的权限值
      readAccess: null,
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
    systemPayApi.queryAllList().then(res => this.payTypeList = res);
    // 初始化时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  mounted() {

    this.$nextTick(()=>{
      // 设置默认收费员为当前账号
      util.getUserInfo().then(res => {
        this.searchInfo.employeeId = res.id;
        this.searchInfo.employeeName = res.employeeName;
        setTimeout(()=>{
          this.queryList();
        },200)
      })
    })
  },
  methods: {
    setDepartCode(data) {
      // ;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          arr.push(data[i][j]);
        }
        // arr.push(data[i][0]);
      }
      this.batchInfo.departCodes = arr
    },
    clearDic() {
      this.batchInfo.departCode = "";
    },
    handlerSelect(command){
      if(command === "setPrint"){
        this.toSetPrint();
      }
    },
    handleBatchSearch() {
      if (this.batchInfo.departCodes && this.batchInfo.departCodes.length > 0) {
        this.searchInfo.departCode = this.batchInfo.departCodes.join(',');
      } else {
        this.searchInfo.departCode = "";
      }
    },
    queryList() {
      this.handleBatchSearch();
      orderStatisticApi.queryDailyList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
        // this.initData();
      })
    },
    initData() {
      for (let i = 0; i < this.payTypeList.length; i++) {
        let result = false;
        for (let j = 0; j < this.dataList.length; j++) {
          if (this.payTypeList[i].id === this.dataList[j].id) {
            result = true;
            break;
          }
        }
        if (!result) {
          this.dataList.push({
            payTypeId: this.payTypeList[i].id,
            payTypeName: this.payTypeList[i].payName,
            saleTotalAmount: 0,
            saleTotalQty: 0,
            reduceAmount: 0,
            refundTotalQty: 0,
            totalQty: 0,
            totalAmount: 0
          })
        }
      }
    },
    // 导出
    exportData() {
      util.exportExcel("/order/saleOrder/statistic/exportDailyList", this.searchInfo);
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    setEmployee(data) {
      this.searchInfo.employeeId = data.id;
      this.searchInfo.employeeName = data.employeeName;
    },
    getSummary(param){
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return (Number(prev) + Number(curr)).toFixed(2)
            } else {
              return Number(prev).toFixed(2)
            }
          }, 0)}`
        }
      })
      return sums;
    },
    toShowDetail(row) {
      this.$routerUtil.push({
        path: "/plat/dailySettle/detail",
        query: {
          // id: row.id,
          employeeId: this.searchInfo.employeeId,
          payTypeId: row.payTypeId,
          departCode: this.searchInfo.departCode,
          startTime: this.searchInfo.startTime,
          endTime: this.searchInfo.endTime,
          readAccess: this.readAccess,
        }
      })
    },
    // 打印
    toPrint() {
      // 组装打印数据
      let data = this.getPrintData("print");
      localPrint.print("日结报表",data);
    },
    // 打印设置
    toSetPrint() {
      localPrint.printDesign("日结报表",this.getFieldPrint());
    },
    // 打印字段参数
    getFieldPrint(){
      return {
        reportDate: "报表日期",
        payeeName: "收费人员",
        // saleTotalAmount: "订单金额合计",
        // saleTotalQty: "订单数量合计",
        // reduceAmount: "退单金额合计",
        // refundTotalQty: "退单数量合计",
        // totalQty: "交易笔数合计",
        totalAmount: "流水金额合计",
        detail: {
          payTypeName: "账户名称",
          // saleTotalAmount: "订单金额",
          // saleTotalQty: "订单数量",
          // reduceAmount: "退单金额",
          // refundTotalQty: "退单数量",
          totalQty: "交易笔数",
          totalAmount: "流水金额",
        }
      }
    },
    // 获取打印的参数
    getPrintData(type) {
      // 订单金额合计
      let saleTotalAmount = 0;
      // 订单数量合计
      let saleTotalQty = 0;
      // 退单金额合计
      let reduceAmount = 0;
      // 退单数量合计
      let refundTotalQty = 0;
      // 交易笔数合计
      let totalQty = 0;
      // 流水金额合计
      let totalAmount = 0;
      this.dataList.forEach(item => {
        saleTotalAmount += item.saleTotalAmount;
        saleTotalQty += item.saleTotalQty;
        reduceAmount += item.reduceAmount;
        refundTotalQty += item.refundTotalQty;

        for (let i = 0; i < this.payTypeList.length; i++) {
          if(item.payTypeId === this.payTypeList[i].id){
            item.payTypeName = this.payTypeList[i].payName;
            break;
          }
        }
      })
      totalQty = saleTotalQty + refundTotalQty;
      totalAmount = saleTotalAmount - reduceAmount;
      // 处理时间，只要日期，不要时间
      let startTime = this.searchInfo.startTime.substring(0, 10);
      let endTime = this.searchInfo.endTime.substring(0, 10);
      let data = {
        reportName: "日结报表",
        excuteType: type,
        // 订单金额合计
        saleTotalAmount: saleTotalAmount,
        // 订单数量合计
        saleTotalQty: saleTotalQty,
        // 退单金额合计
        reduceAmount: reduceAmount,
        // 退单数量合计
        refundTotalQty: refundTotalQty,
        // 交易笔数合计
        totalQty: totalQty,
        // 流水金额合计
        totalAmount: totalAmount,
        // 报表日期
        reportDate: startTime + " 至" +
            " " + endTime,
        // 收费人员
        payeeName: this.searchInfo.employeeName,
      }
      data.productList = this.dataList;
      return data;
    },
  }
};
</script>

<style lang="scss" scoped>
.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
@import "@/assets/css/el-tables";
</style>