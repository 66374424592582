<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header/>
        <el-descriptions :column="3" border>
          <el-descriptions-item label="供应商" label-class-name="require">
            <el-select v-model="dataDetail.supplierId" :disabled="!isDisabled">
              <el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="单据日期">
            <el-date-picker :disabled="!isDisabled" v-model="dataDetail.orderTime" type="datetime"
                            style="width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="单据编号">
            {{dataDetail.id}}
          </el-descriptions-item>
          <el-descriptions-item label="仓库名称" label-class-name="require">
            <el-select :disabled="!isDisabled" v-model="dataDetail.clientStockId"
                       @change="toSelectStockChange">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="部门" label-class-name="require">
            <dictionary v-model="dataDetail.clientDepartCode" :disabled="true"
                        @change-data="changeDepart" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>
          <el-descriptions-item label="经办人">
            <employee-select v-model="dataDetail.clientEmployeeId" :disabled="!isDisabled"/>
          </el-descriptions-item>
          <el-descriptions-item label="创建人">
            {{ dataDetail.createdName }}
          </el-descriptions-item>
          <el-descriptions-item label="订单状态" :span="2">
            {{ dataDetail.statusName }}
          </el-descriptions-item>
          <el-descriptions-item label="单据备注" :span="3">
            <el-input :disabled="!isDisabled" v-model="dataDetail.remark" type="textarea"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="display: flex; justify-content: flex-end;margin: 5px 0">
          <div style="width: 400px">
            <product-list-select :stock-type="true" :is-depart="true"
                                 :query-list-api="stockInStorageApi.queryProductAuthorizeList"
                                 :selected-product-list="dataDetail.detail"
                                 @selectOne="addProductOne" @select="addProductList"/>
          </div>
        </div>
        <div style="height: calc(100% - 320px)">
          <el-table stripe :data="dataDetail.detail" height="100%">
            <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
            <el-table-column align="center" label="商品编号" prop="skuCode" min-width="100px" show-overflow-tooltip fixed="left"/>
            <el-table-column label="商品名称" align="center" prop="skuName" min-width="120px" show-overflow-tooltip fixed="left"/>
            <el-table-column align="center" label="条码" prop="barCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="采购单位" prop="unitName" width="160px" :align="'center'">
              <template v-slot="{row}">
                <span v-if="dataDetail.status > 10">{{ row.unitName }}</span>
                <el-select v-else v-model="row.unitId" size="default"
                           @change="toSelectUnitChange(row)">
                  <el-option v-for="item in row.skuUnitList" :key="item.id" :label="item.unitName" :value="item.unitId"/>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="总数量" prop="qty" width="100px" v-if="dataDetail.status > 15">
              <template v-slot="{row, $index}">
                <span>{{ row.totalQty ? row.totalQty : row.qty }}</span>
              </template>
            </el-table-column>
            <el-table-column label="采购数量" prop="qty" width="100px">
              <template v-slot="{row, $index}">
                <el-input :disabled="!isDisabled" v-model="row.qty" type="number" @change="changeQty($index)"/>
              </template>
            </el-table-column>
            <el-table-column label="质检损坏数量" prop="damageQty" width="130px" v-if="dataDetail.status > 15">
              <template v-slot="{row, $index}">
                <span> {{ row.damageQty ? row.damageQty : 0 }} </span>
              </template>
            </el-table-column>
            <el-table-column label="预设进价" prop="originalBuyPrice" width="160px" :align="'center'"/>
            <el-table-column label="折扣" prop="rate" width="160px" :align="'center'"/>
            <el-table-column label="采购单价" prop="excludeTaxBuyPrice" width="160px" :align="'center'"/>
            <el-table-column label="采购金额" prop="salePrice" width="160px" :align="'center'">
              <template v-slot="{row}">
                <money :value="row.excludeTaxBuyPrice * row.qty" />
              </template>
            </el-table-column>
            <el-table-column label="税率" prop="taxRate" width="100px" :align="'center'">
              <template v-slot="{row}">
                <span v-if="!isDisabled">{{ row.taxRate }}%</span>
                <el-input v-else v-model="row.taxRate" class="my-input">
                  <template #suffix>
                    <span style="color: black">%</span>
                  </template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="税额" width="100px" :align="'center'">
              <template v-slot="{row}">
                <money :value="row.excludeTaxBuyPrice * row.qty * (Number(row.taxRate) / 100)" />
              </template>
            </el-table-column>
            <el-table-column label="含税单价" width="120px" :align="'center'" fixed="right">
              <template v-slot="{row, $index}">
                <span v-if="!isDisabled">{{ row.buyPrice }}</span>
                <el-input v-else v-model="row.buyPrice" @change="updateIncludeSalePrice($index)" />
              </template>
            </el-table-column>
            <el-table-column label="价税合计" width="120px" :align="'center'" fixed="right">
              <template v-slot="{row,$index}">
                <span v-if="!isDisabled">{{ row.rateTotalAmount }}</span>
                <el-input v-else v-model="row.rateTotalAmount" @change="updateRateTotalAmount($index)" />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60px" :align="'center'" fixed="right" v-if="isDisabled">
              <template v-slot="{row}">
                <table-button type="delete" el-type="danger" @click="deleteRow(row)">删除</table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </table-list>
      <form-page :show-page="false">
        <page-button v-if="dataDetail.status === 0" el-type="success" type="write" @click="saveDraft">保存草稿
        </page-button>
        <approval-comp v-if="dataDetail.status === 11" style="display:inline-block;"  :data-id="dataDetail.id" :save-api="MerchantPurchaseApi.approvalWZ"/>
        <page-button v-if="isDisabled" el-type="primary" type="write" @click="saveSubmit">提交单据
        </page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import merchantPurchaseApi from "@/api/main/supplyChain/merchantPurchaseApi";
import Money from "@/components/h5/Money.vue";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import CommonApi from "@/api/commonApi";
import {ElMessageBox} from "element-plus";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue"
import stockInStorageApi from "@/api/main/stock/stockInStorageApi";
import {WarningFilled} from "@element-plus/icons-vue";
import stockApi from "@/api/main/stock/stockApi";
import ApprovalComp from "@/components/h5/approval/ApprovalComp.vue";
import MerchantPurchaseDetail from "@/views/main/supplyChain/purchase/MerchantPurchaseDetail.vue";
import MerchantPurchaseApi from "@/api/main/supplyChain/merchantPurchaseApi";
export default {
  name: "",
  computed: {
    MerchantPurchaseApi() {
      return MerchantPurchaseApi
    },
    stockInStorageApi() {
      return stockInStorageApi
    },
    CommonApi() {
      return CommonApi
    },
  },
  components: {
    WarningFilled, PageHeader, TableButton, PageButton, EmployeeSelect, ListPage, TableList, FormPage, ProductListSelect, ClientSelect, Dictionary, Money, ApprovalComp
  },
  data() {
    return {
      // 供应商信息
      supplierList: [],
      dataDetail: {
        supplierId: "",
        clientEmployeeId: "",
        detail: [],
        clientEmployeeName: "",
        status: 0,
        clientStockId: "",
        clientDepartCode: "",
      },
      isDisabled: true,
      departList: [],
      stockList: [],
      purchaseType: true,
      stockDepartSwitch: false,
    };
  },
  created() {
    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");
    // 查询商品时，查询供应商的商品
    // 查询部门
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询仓库
    this.queryAllStockList();
    // 查询所有的供应商信息
    commonApi.querySupplierList().then(list => this.supplierList = list);
  },
  methods: {
    // 修改数量
    changeQty(idx){
      let data = this.dataDetail.detail[idx];
      data.rateTotalAmount = data.buyPrice * data.qty;
    },
    // 修改价税合计
    updateRateTotalAmount(idx){
      let data = this.dataDetail.detail[idx];
      data.buyPrice = (data.rateTotalAmount / data.qty).toFixed(2);
      this.updateIncludeSalePrice(idx);
    },
    // 修改含税单价
    updateIncludeSalePrice(idx){
      let data = this.dataDetail.detail[idx];
      data.excludeTaxBuyPrice = (data.buyPrice / (1 + (data.taxRate / 100))).toFixed(2);
      this.changeSalePrice(idx)
      data.rateTotalAmount = data.buyPrice * data.qty;
    },
    // 变更售价
    changeSalePrice(i) {
      // if(skuOriginalPrice)
      let data = this.dataDetail.detail[i];
      // 折扣
      data.rate = isNaN((data.buyPrice / data.originalBuyPrice)) ? 1 : (data.buyPrice / data.originalBuyPrice).toFixed(2);
    },
    toSelectUnitChange(row){
      // 获取当前单位的成本价格
      const skuUnitList = row.skuUnitList;
      for (let i = 0; i < skuUnitList.length; i++) {
        if (skuUnitList[i].unitId === row.unitId) {
          row.buyPrice = skuUnitList[i].buyPrice;
          row.salePrice = skuUnitList[i].salePrice;
          row.excludeTaxBuyPrice = skuUnitList[i].IncludeBuyPrice;
          row.excludeTaxSalePrice = skuUnitList[i].IncludeSalePrice;
          break;
        }
      }
    },
    // 仓库变更，同步变更部门
    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.dataDetail.clientStockId === this.stockList[i].id) {
          // 部门
          this.dataDetail.clientDepartId = this.stockList[i].bankupId;
          break;
        }
      }
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.clientDepartId === this.departList[i].id) {
          this.dataDetail.clientDepartCode = this.departList[i].departCode;
        }
      }
    },
    deleteRow(row) {
      ElMessageBox.confirm("确定删除该行数据？", "提示", {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let idx = this.dataDetail.detail.findIndex(item => item.id === row.id);
        if (idx !== -1) {
          this.dataDetail.detail.splice(idx, 1);
          this.$message.success("移除成功");
        }
      }).catch(() => this.$message.info("已取消"));
    },
    findById(id) {
      merchantPurchaseApi.findById(id).then(data => {
        // 判断状态
        if(data.status > 10 && data.status !== 13){
          this.isDisabled = false;
        }
        this.dataDetail = data;
        for (let i = 0; i < this.dataDetail.detail.length; i++) {
          this.changeQty(i);
          this.updateIncludeSalePrice(i);
        }
      });
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.dataDetail.clientEmployeeName = user.userName;
          this.dataDetail.createdName = user.userName;
          this.dataDetail.clientEmployeeId = user.id;
          this.dataDetail.clientDepartCode = user.mainDepartCode;
          this.dataDetail.statusName = "草稿";
          this.dataDetail.orderTime = new Date();
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2();
        } else {
          this.findById(this.$routerUtil.query.id);
          this.queryAllStockList2();
        }
      });
    },
    //  根据departId查询仓库，如果只有一个仓库，添加创建
    queryAllStockList2(departId) {
      stockApi.queryListByNotCondition().then(list => {
        this.stockList = list;
        // 新建时默认选中仓库
        if (!this.$routerUtil.query.id && list.length > 0) {
          this.dataDetail.clientStockId = list[0].id;
          this.toSelectStockChange();
        }
      })
      // commonApi.queryAllStock(departId).then(list => {
      //   this.stockList = list;
      //
      // });
    },
    // 部门变更时,重新查询仓库
    changeDepart(data) {
      this.queryAllStockList2(data.dictCode);
    },
    // 根据商品，添加一行明细
    createDetailData(product) {
      return {
        skuId: product.id,
        skuName: product.skuName,
        skuCode: product.skuCode,
        barCode: product.barCode ? product.barCode : '-',
        salePrice: product.skuSalePrice,
        buyPrice: product.skuBuyPrice,
        unitName: product.skuUnitName,
        unitId: product.skuUnitId,
        qty: 1,
        rate: 1,
        taxRate: product.stockTaxRate ? product.stockTaxRate * 100 : 0,
        rateTotalAmount: product.skuBuyPrice,
        skuUnitList: product.skuUnitList,
        originalBuyPrice: product.skuBuyPrice,
      }
    },
    // 查询商品前，确认已经选择了供应商
    beforeQuery() {
      if (!this.dataDetail.merchantId) {
        this.$message.error("请选择供应商")
        return false;
      }
      return true;
    },
    // 添加一个商品
    addProductOne(data) {
      // 如果没有，则添加
      let contains = false;
      for (let j = 0; j < this.dataDetail.detail.length; j++) {
        if (data.id === this.dataDetail.detail[j].skuId) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        this.dataDetail.detail.push(this.createDetailData(data));
        this.updateIncludeSalePrice(this.dataDetail.detail.length - 1);
      }
    },
    // 添加多个商品
    addProductList(list) {
      for (let i = 0; i < list.length; i++) {
        this.addProductOne(list[i])
      }
    },
    checkData() {
      if (!this.dataDetail.merchantId && !this.purchaseType) {
        this.$message.error("请选择供应商")
        return false;
      }
      if (!this.dataDetail.clientStockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (!this.dataDetail.clientDepartCode) {
        this.$message.error("请选择部门");
        return false;
      }
      return true;
    },
    saveDraft() {
      if (!this.checkData()) {
        return;
      }
      if (this.purchaseType) {
        this.dataDetail.orderType = 101;
      }
      if (!this.dataDetail.orderType) {
        this.dataDetail.orderType = 1;
      }
      merchantPurchaseApi.saveDraftWZ(this.dataDetail).then(data => this.findById(data.id));
    },
    saveSubmit() {
      if (!this.checkData()) {
        return;
      }
      if (this.purchaseType) {
        this.dataDetail.orderType = 101;
      }
      if (!this.dataDetail.orderType) {
        this.dataDetail.orderType = 1;
      }
      merchantPurchaseApi.saveSubmitWZ(this.dataDetail).then(data => this.findById(data.id));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
:deep(.el-descriptions) {
  margin-bottom: 0 !important;
}

.my-input{
  :deep(.el-input__inner){
    text-align: right !important;
  }


}
</style>