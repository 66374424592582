<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="showInputRemark = true">导出</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="write" el-type="success" @click="addNew">新建</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
          <el-input v-model="searchInfo.searchKey" placeholder="请扫描或输入溯源码" @keydown.enter="resetSearch"
                    clearable/>
          <el-input v-model="searchInfo.name" placeholder="请输入商品编号/名称/型号" @keydown.enter="resetSearch"
                    clearable/>
        </template>
        <template #moreCondition>
          <div class="customer-select" style="display: inline-block; width: 240px">
            <employee-select :placeholder-tit="'维保人'" v-model="searchInfo.employeeId"/>
          </div>

          <div class="customer-select" style="display: inline-block; width: 240px">
            <el-select v-model="searchInfo.mtResult" placeholder="请选择维保结果">
              <el-option label="正常" :value="0"/>
              <el-option label="维修" :value="1"/>
              <el-option label="报废" :value="2"/>
            </el-select>
          </div>

          <div class="customer-select" style="display: inline-block; width: 240px">
            <dictionary v-model="searchInfo.mtType" :place-holder="'请选择维保类型'" :dictionaries="mtTypeList"
                        dict-code="dictCode" dict-name="dictName"/>
          </div>
        </template>
      </search-condition>
      <table-list>
        <yq-table page-name="mtRecord" stripe :data="dataList" ref="multipleTable" height="100%">
          <yq-table-column align="center" label="维保时间" prop="mtTime" min-width="200"/>
          <yq-table-column align="center" label="商品分类" prop="categoryName" min-width="120"/>
          <yq-table-column align="center" label="商品编号" prop="skuCode" min-width="120"/>
          <yq-table-column align="center" label="商品名称" prop="skuName" min-width="160"/>
          <yq-table-column align="center" label="规格" prop="skuStandard" min-width="120">
            <template v-slot:default="{ row }">
              {{ row.skuStandard ? row.skuStandard : '-' }}
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="型号" prop="skuType" min-width="120">
            <template v-slot="{ row }">
              <span>{{ row.skuType ? row.skuType : '-' }}</span>
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="溯源码" prop="originalCode" min-width="150"/>
          <yq-table-column align="center" label="维保类型" prop="mtTypeName" min-width="100"/>
          <yq-table-column align="center" label="维保结果" pro="mtReuslt" min-width="120">
            <template v-slot="{ row }">
              <el-tag v-if="row.mtResult === 0" type="success">正常</el-tag>
              <el-tag v-else-if="row.mtResult === 1" type="warning">维修</el-tag>
              <!--              <el-tag v-else-if="row.mtResult === 1 && row.status === 99" type="success">维修完成</el-tag>-->
              <el-tag v-else-if="row.mtResult === 2" type="danger">报废</el-tag>
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="维保人" prop="mtEmployeeName" min-width="100"/>
          <yq-table-column align="center" label="状态" min-width="100" prop="status">
            <template v-slot="{ row }">
              <el-tag type="success" v-if="row.status === 99">已完成</el-tag>
              <el-tag type="danger" v-else-if="row.status === 20">待处理</el-tag>
              <el-tag type="danger" v-else-if="row.status === 10">待审批</el-tag>
              <el-tag type="warning" v-else-if="row.status === 0">草稿</el-tag>
            </template>
          </yq-table-column>
          <yq-table-column align="center" label="操作" prop="options" fixed="right" :required="true" min-width="180">
            <template v-slot="{ row }">
              <table-button el-type="primary" @click="toView(row)">查看</table-button>
              <table-button message="确定维修完成了吗?" el-type="warning" v-if="row.status === 20"
                            @click="toRepairFinish(row)">维修完成
              </table-button>
              <table-button el-type="danger" v-if="row.status === 20 && row.originalOrderId"
                            @click="toScrapOut(row)">报废出库
              </table-button>
            </template>
          </yq-table-column>
        </yq-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>

    <el-dialog v-model="showInputRemark" :show-close="false" width="550px" :close-on-click-modal="false"
               class="remark-dialog">
      <!-- 自定义header -->
      <template #header>
        <div class="dialog-header">
          <div class="header-left">
            <el-icon class="header-icon">
              <Document/>
            </el-icon>
            <span class="header-title">部门备注</span>
          </div>
          <el-button class="close-btn" link @click="showInputRemark = false">
            <el-icon>
              <Close/>
            </el-icon>
          </el-button>
        </div>
      </template>

      <div class="dialog-content">
        <div class="input-section">
          <!-- 输入区域标题 -->
          <div class="section-title">
            <div class="title-dot"></div>
            <span>备注信息</span>
          </div>

          <!-- 输入框区域 -->
          <div class="input-area">
            <el-input v-model="departRemark" type="textarea" :rows="5" placeholder="请输入部门备注信息..."
                      resize="none"/>
          </div>

          <!-- 提示信息区域 -->
          <div class="info-box">
            <div class="info-item">
              <el-icon>
                <InfoFilled/>
              </el-icon>
              <span>备注信息将显示在导出文件中</span>
            </div>
            <div class="info-item">
              <el-icon>
                <Warning/>
              </el-icon>
              <span>请确保信息准确无误(非必填)</span>
            </div>
          </div>
        </div>
      </div>


      <div style="display: flex; justify-content: flex-end;margin-top: 15px;">
        <page-button el-type="info" class="cancel-btn" @click="showInputRemark = false">
          <el-icon size="16">
            <Close/>
          </el-icon>
          <span>取 消</span>
        </page-button>
        <page-button el-type="primary" class="confirm-btn" @click="exportData">
          <el-icon size="16">
            <Check/>
          </el-icon>
          <span>确认并导出</span>
        </page-button>
      </div>


    </el-dialog>


  </div>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import mtRecordApi from "@/api/main/material/mtRecordApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import YqTable from "@/components/h5/column/YqTable.vue";
import YqTableColumn from "@/components/h5/column/YqTableColumn.vue";
import {ElLoading} from "element-plus";
import {Document, InfoFilled, Warning, Close, Check} from '@element-plus/icons-vue'

export default {
  computed: {},
  components: {
    Dictionary,
    PageButton,
    FormPage,
    YqTable,
    YqTableColumn,
    TableButton,
    EmployeeSelect,
    ListPage,
    SearchCondition,
    TableList,
    DateRange,
    PageHeader,
    ProductListSelect,
    Document,
    InfoFilled,
    Warning,
    Close,
    Check
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      mtTypeList: [],

      // 导出的部门备注信息(让客户自己手填)
      departRemark: "",
      showInputRemark: false,
    };
  },
  created() {
    // 查询字典
    util.getUserInfo().then((user) => {
      commonApi.queryDictionaryList("MATERIAL_TYPE", user.merchantId).then(list => this.mtTypeList = list);
    });
    // 初始化，默认查询一个月内的数据
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  methods: {
    exportData() {
      if (this.dataList.length === 0) {
        this.$message.error("没有数据可以导出！");
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: '正在导出...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      util.getUserInfo().then(user => {
        let roleName = user.roleName;
        if (user.roleType == "1") {
          // 导出word
          mtRecordApi.exportWord(this.searchInfo, this.departRemark).finally(() => loading.close());
        } else {
          // 导出pdf
          mtRecordApi.exportPdf(this.searchInfo, this.departRemark).finally(() => loading.close());
        }
      })
      // util.exportExcel("/order/material/maintenance/exportData",this.searchInfo);


    },
    toView(row) {
      this.$routerUtil.push(`/mt/control/record/detail?id=${row.id}&isUpdated=1`)
    },
    toRepairFinish(row) {
      mtRecordApi.toRepairFinish(row.originalCode, row.id).then(() => {
        this.$message.success("操作成功");
        this.queryList();
      });
    },
    toScrapOut(row) {
      if (row.originalOrderId) {
        // 有来源订单，归还单生成的检修，需要报废出库，需要走单独的逻辑
        mtRecordApi.toScrapOut(row.id).then(() => {
          this.$message.success("操作成功");
          this.queryList();
        })
      } else {
        this.$routerUtil.push(`/stock/outstock/detail-original?recordId=${row.id}&code=${row.originalCode}`);
      }
    },
    queryList() {
      mtRecordApi.queryList(this.searchInfo).then(data => {
        this.dataList = data.records;
        this.searchInfo.totalCount = data.totalCount;
      });
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    addNew() {
      this.$routerUtil.push(`/mt/control/record/detail`);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";

.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}


.remark-dialog {
  :deep(.el-dialog) {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);

    .el-dialog__header {
      margin: 0;
      padding: 0;
    }

    .el-dialog__body {
      padding: 0 24px 24px;
    }

    .el-dialog__footer {
      padding: 16px 24px;
      background: #f8fafc;
      border-top: 1px solid #e5e7eb;
    }
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #f0f7ff;
  border-bottom: 1px solid #e6f0fd;

  .header-left {
    display: flex;
    align-items: center;
    gap: 12px;

    .header-icon {
      font-size: 24px;
      color: #409EFF;
      background: white;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(64, 158, 255, 0.15);
    }

    .header-title {
      font-size: 18px;
      font-weight: 600;
      color: #1f2937;
      letter-spacing: 0.5px;
    }
  }

  .close-btn {
    font-size: 20px;
    color: #64748b;
    transition: all 0.3s ease;

    &:hover {
      color: #ef4444;
      transform: rotate(90deg);
    }
  }
}

.dialog-content {
  padding-top: 24px;

  .input-section {
    .section-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 8px 0;

      .title-dot {
        width: 4px;
        height: 16px;
        background: #409EFF;
        border-radius: 2px;
      }

      span {
        font-size: 15px;
        font-weight: 500;
        color: #1f2937;
      }
    }

    .input-area {
      :deep(.el-textarea) {
        .el-textarea__inner {
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #e5e7eb;
          font-size: 14px;
          line-height: 1.6;
          transition: all 0.3s ease;

          &:hover {
            border-color: #409EFF;
          }

          &:focus {
            border-color: #409EFF;
            box-shadow: 0 0 0 3px rgba(64, 158, 255, 0.1);
          }
        }
      }
    }

    .info-box {
      margin-top: 16px;
      padding: 12px 16px;
      background: #fafafa;
      border-radius: 8px;
      border-left: 4px solid #409EFF;

      .info-item {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .el-icon {
          font-size: 16px;
          color: #409EFF;
        }

        span {
          font-size: 13px;
          color: #64748b;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .el-button {
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.3s ease;

    .el-icon {
      font-size: 16px;
    }

    &.cancel-btn {
      border-color: #e5e7eb;
      color: #64748b;

      &:hover {
        background: #f1f5f9;
        border-color: #cbd5e1;
      }
    }

    &.confirm-btn {
      background: #409EFF;
      border-color: #409EFF;

      &:hover {
        background: #66b1ff;
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(64, 158, 255, 0.2);
      }
    }
  }
}
</style>