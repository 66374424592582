<template>
  <!--库存列表 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="showQrcode = true">生成二维码</page-button>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </page-header>
      <search-condition>
        <template #default>
          <el-input v-model="originalCode"  placeholder="请输入溯源码并点击查询按钮" @keydown.enter="resetSearch"/>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="list" height="100%">
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="商品名称" prop="skuName" show-overflow-tooltip/>
          <el-table-column label="机构名称" prop="merchantName" :align="'center'"/>
          <el-table-column label="仓库名称" prop="stockName" :align="'center'"/>
          <el-table-column label="变更类型" prop="" :align="'center'">
            <template v-slot="{row}">
              <span v-if="row.type === 1">入库</span>
              <span v-else-if="row.type === 2">出库</span>
              <span v-else-if="row.type === 3">调拨入库</span>
              <span v-else-if="row.type === 4">调拨出库</span>
              <span v-else-if="row.type === 5">库存盘点</span>
              <span v-else-if="row.type === 97">归还入库</span>
              <span v-else-if="row.type === 98">领用出库</span>
              <span v-else-if="row.type === 99">生成质保</span>
              <span v-else-if="row.type === 100">退货入库</span>
            </template>
          </el-table-column>
          <el-table-column label="变更时间" prop="changeTime" :align="'center'"/>
        </el-table>
      </table-list>
    </list-page>


    <el-dialog title="二维码生成" width="30%" v-model="showQrcode">
      <el-form label-width="70px" style="padding-top: 15px">
        <el-form-item label="文件名称">
          <el-input v-model="fileName" placeholder="请输入文件名称"/>
        </el-form-item>
        <el-form-item label="生成数量">
          <el-input v-model="count" placeholder="请输入生成数量"/>
        </el-form-item>
        <div style="display: flex;justify-content: flex-end">
          <page-button el-type="info" @click="showQrcode = false">取消</page-button>
          <page-button el-type="primary" @click="generateQrcode">确认</page-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog v-model="fullscreenLoading" width="30%" :show-close="false" :title="getPercentage > 0 ? currentTage + '/' + totalTage : '正在生成溯源码,请稍候...'" :close-on-click-modal="false" :close-on-press-escape="false">
<!--      <el-progress :percentage="percentage" :indeterminate="true" />-->
      <el-progress :text-inside="true"
                     :stroke-width="24"
                     :percentage="getPercentage" />
    </el-dialog>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import transProductApi from "@/api/alpine/trace/transProductApi";
import commonApi from "@/api/commonApi";
import axios from "axios";
import Util from "@/util/util";

export default {
  name: "",
  components: {
    ListPage, TableList, SearchCondition, FormPage, PageButton, TableButton, PageHeader
  },
  data() {
    return {
      list: [],
      originalCode: "",
      originalType: "",
      showQrcode: false,
      count: 0,
      fileName: "",
      fullscreenLoading: false,
      currentTage: 0,
      totalTage: 1,
    };
  },
  created() {
    // 查询溯源码类型
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.originalType = setting.settingValue)
  },
  computed: {
    getPercentage(){
      return (this.currentTage / this.totalTage * 100).toFixed(0);
    }
  },
  methods: {
    generateQrcode() {
      if(!this.count || this.count === "0"){
        this.$message.error("数量不能为空")
        return;
      }
      // 检查是否是数字
      if (!/^[0-9]*$/.test(this.count)) {
        this.$message.error("数量必须为数字");
        return;
      }
      this.showQrcode = false;
      this.fullscreenLoading = true;
      this.startDownload(this.count, this.fileName);
    },
    startDownload(count, fileName){
      this.currentTage = 0;
      this.totalTage = 1;
      let _this = this;
      // 发送请求
      axios({
        method: 'GET',
        url: process.env.VUE_APP_BASE_URL + "/order/common/generateCode",
        params: {count, fileName},
        responseType: 'blob',
        onDownloadProgress: (progress) => {
          _this.totalTage = progress.total;
          _this.currentTage = progress.loaded;
          if(progress.loaded === progress.total){
            setTimeout(() => _this.fullscreenLoading = false, 500)
          }
        }
      }).then(res => {
        if (res) {
          Util.downloadBinaryFile(res, fileName ? fileName : "溯源码生成", "text/plain");
        }
      }).catch(() => {
        _this.$message.error("网络异常,请稍后再试！")
      })
    },
    resetSearch() {
      if (!this.originalCode) {
        this.$message.error("溯源码不能为空");
        return;
      }
      if (!this.originalType) {
        this.$message.error("溯源码类型配置异常");
        return;
      }
      commonApi.transferUrl(this.originalCode, this.originalType).then(code => {
        this.originalCode = code;
        transProductApi.queryHistory(this.originalCode, this.originalType).then(list => this.list = list);
      })

      // transProductApi.queryHistorySingle(this.originalCode, this.originalType).then(list => this.list = list);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
@import "@/assets/css/components/dialog";

:deep(.el-dialog__header){
  padding: 15px 15px 0 15px;
}
:deep(.el-dialog__title){
  font-weight: 600;
  font-size: 16px;
}
  :deep(.el-dialog__body){
    padding-top: 5px !important;
    padding-bottom: 20px !important;
  }

:deep(.el-progress__text){
  min-width: 0 !important;
}
:deep(.el-progress-bar__innerText){
  color: #000000;
}
</style>