<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <page-button el-type="primary" @click="resetSearch">查询</page-button>
      <page-button el-type="success" @click="toEditData">新建</page-button>
    </page-header>
    <list-page>
      <search-condition>
        <employee-select v-model="searchInfo.employeeId" style="display: inline-block; width: 240px" />
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%" border>
          <el-table-column align="center" type="index" min-width="60px" label="序号" fixed />
          <el-table-column align="center" prop="year" min-width="120px" label="年/次">
            <template v-slot="{row}">
              {{row.year}}年 / {{row.frequency}}次
            </template>
          </el-table-column>
<!--          <el-table-column align="center" prop="frequency" min-width="120px" label="次" />-->
          <el-table-column align="center" width="150px" label="操作" fixed="right">
            <template v-slot="{row}">
              <table-button el-type="primary" type="write" @click="toEditData(row)">编辑</table-button>
              <table-button el-type="danger" type="delete" message="确定删除吗？" @click="deleteData(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>

    <el-dialog v-model="showEdit" :show-close="false" width="550px" :close-on-click-modal="false" @closed="editData = {}"
               class="remark-dialog">
      <!-- 自定义header -->
      <template #header>
        <div class="dialog-header">
          <div class="header-left">
            <el-icon class="header-icon">
              <Document/>
            </el-icon>
            <span class="header-title">维保设置</span>
          </div>
          <el-button class="close-btn" link @click="showEdit = false">
            <el-icon>
              <Close/>
            </el-icon>
          </el-button>
        </div>
      </template>

      <div class="dialog-content">
        <div class="input-section">
          <!-- 输入区域标题 -->
          <div class="section-title">
            <div class="title-dot"></div>
            <span>详情</span>
          </div>

          <!-- 输入框区域 -->
          <div class="input-area">
            <div class="input-group">
              <div class="input-item">
                <div class="input-label">
                  <el-icon><Calendar /></el-icon>
                  <span>维保年数</span>
                </div>
                <el-input 
                  v-model="editData.year" 
                  placeholder="请输入年数"
                  class="custom-input"
                >
                  <template #append>年</template>
                </el-input>
              </div>

              <div class="input-item">
                <div class="input-label">
                  <el-icon><Timer /></el-icon>
                  <span>维保频次</span>
                </div>
                <el-input
                    v-model="editData.frequency"
                    placeholder="请输入次数"
                    class="custom-input"
                >
                  <template #append>次/年</template>
                </el-input>
              </div>
            </div>
          </div>

          <!-- 提示信息区域 -->
          <div class="info-box">
            <div class="info-item">
              <el-icon>
                <InfoFilled/>
              </el-icon>
              <span>提示：维保频率为{{editData.year ? editData.year : 'X'}}年{{editData.frequency ? editData.frequency : 'X'}}次</span>
            </div>
          </div>
        </div>
      </div>


      <div style="display: flex; justify-content: flex-end;margin-top: 15px;">
        <page-button el-type="info" class="cancel-btn" @click="showEdit = false">
          <el-icon size="16"><Close/></el-icon>
          <span>取 消</span>
        </page-button>
        <page-button el-type="primary" class="confirm-btn" @click="save">
          <el-icon size="16">
            <Check/>
          </el-icon>
          <span>保 存</span>
        </page-button>
      </div>


    </el-dialog>

  </div>
</template>

<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import pageHeader from "@/components/h5/layout/PageHeader.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import mtSettingApi from "@/api/main/material/mtSettingApi";
import FormPage from "@/components/h5/layout/FormPage.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import yiqiDialog from "@/components/h5/dialog/yiqi-dialog.vue";
import {Document, InfoFilled, Warning, Close, Check, Calendar, Timer} from '@element-plus/icons-vue'
export default {
  name: "UnitByTab",
  components: {
    Calendar,
    Timer,
    EmployeeSelect,
    FormPage,
    TableButton,
    ListPage, SearchCondition, PageButton, TableList, pageHeader, yiqiDialog, Document, InfoFilled, Warning, Close, Check
  },
  data() {
    return {
      // 基础单位
      searchInfo: {},
      // 数据集合
      dataList: [],
      // 单位数据
      editData: {},
      // 编辑或新增
      showEdit: false,
      warnTileList: [{warnTitle: "本次维保为X年X次"}],
    };
  },
  created() {
  },
  methods: {
    // 重置查询条件
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询
    queryList(){
      mtSettingApi.queryList(this.searchInfo).then(res=>{
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 删除
    deleteData(row){
      mtSettingApi.deleteRow(row.id).then(()=>{
        this.queryList();
      })
    },
    // 编辑、新建
    toEditData(row){
      if(row){
        this.editData = row;
        this.editChange();
      }
      this.showEdit = true;
    },
    // 保存提交
    save(){
      if(!this.editData.year || Number(this.editData.year) <= 0){
        this.$message.error("请输入正确的维保年数");
        return;
      }
      if(!this.editData.frequency || Number(this.editData.frequency) <= 0){
        this.$message.error("请输入正确的维保频次");
        return;
      }
      mtSettingApi.saveSubmit(this.editData).then(()=>{
        this.showEdit = false;
        this.queryList();
      })
    },
    editChange(){
      this.warnTileList[0].warnTitle = `本次维保为${this.editData.year}年${this.editData.frequency}次`
    }
  }
};
</script>

<style lang="scss" scoped>
.remark-dialog {
  :deep(.el-dialog) {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);

    .el-dialog__header {
      margin: 0;
      padding: 0;
    }

    .el-dialog__body {
      padding: 0 24px 24px;
    }

    .el-dialog__footer {
      padding: 16px 24px;
      background: #f8fafc;
      border-top: 1px solid #e5e7eb;
    }
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #f0f7ff;
  border-bottom: 1px solid #e6f0fd;

  .header-left {
    display: flex;
    align-items: center;
    gap: 12px;

    .header-icon {
      font-size: 24px;
      color: #409EFF;
      background: white;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(64, 158, 255, 0.15);
    }

    .header-title {
      font-size: 18px;
      font-weight: 600;
      color: #1f2937;
      letter-spacing: 0.5px;
    }
  }

  .close-btn {
    font-size: 20px;
    color: #64748b;
    transition: all 0.3s ease;

    &:hover {
      color: #ef4444;
      transform: rotate(90deg);
    }
  }
}

.dialog-content {
  padding-top: 24px;

  .input-section {
    .section-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 8px 0;

      .title-dot {
        width: 4px;
        height: 16px;
        background: #409EFF;
        border-radius: 2px;
      }

      span {
        font-size: 15px;
        font-weight: 500;
        color: #1f2937;
      }
    }

    .input-area {
      padding: 20px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

      .input-group {
        display: flex;
        gap: 24px;

        .input-item {
          flex: 1;
          
          .input-label {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
            color: #606266;
            font-size: 14px;
            
            .el-icon {
              color: #409EFF;
              font-size: 16px;
            }
          }

          :deep(.custom-input) {
            .el-input__wrapper {
              box-shadow: 0 0 0 1px #dcdfe6 inset;
              transition: all 0.3s ease;
              
              &:hover {
                box-shadow: 0 0 0 1px #409EFF inset;
              }
              
              &.is-focus {
                box-shadow: 0 0 0 1px #409EFF inset;
              }
            }

            .el-input-group__append {
              background-color: #f5f7fa;
              color: #909399;
              font-size: 13px;
              padding: 0 12px;
              border-left: 1px solid #dcdfe6;
            }
            
            input {
              height: 36px;
              font-size: 14px;
              
              &::placeholder {
                color: #c0c4cc;
              }
            }
          }
        }
      }
    }

    .info-box {
      margin-top: 16px;
      padding: 12px 16px;
      background: #fafafa;
      border-radius: 8px;
      border-left: 4px solid #409EFF;

      .info-item {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .el-icon {
          font-size: 16px;
          color: #409EFF;
        }

        span {
          font-size: 13px;
          color: #64748b;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .el-button {
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.3s ease;

    .el-icon {
      font-size: 16px;
    }

    &.cancel-btn {
      border-color: #e5e7eb;
      color: #64748b;

      &:hover {
        background: #f1f5f9;
        border-color: #cbd5e1;
      }
    }

    &.confirm-btn {
      background: #409EFF;
      border-color: #409EFF;

      &:hover {
        background: #66b1ff;
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(64, 158, 255, 0.2);
      }
    }
  }
}
</style>