import {ElMessage} from 'element-plus';

const LocalPrint = {
    port: 8990,
    url: "ws://127.0.0.1",
    _websocket: null,
    reportName: "",
    excuteType: "preview, print,design",
    _initWebsocket: function () {
        var that = this;
        if ("WebSocket" in window) {
            if (this._websocket) {
                //激活一下 报错就提示
                this._websocket.send("get=Version");
                this._websocket.send("method=GetStylesInfo:" + this.reportName);
            }
            else {
                this._websocket = new WebSocket(this.url + ":" + this.port);
            }
        } else {
            ElMessage.error({
                message: '浏览器不支持WebSocket',
                type: 'error',
                duration: 1500,
            });
        }

        this._websocket.onopen = function (evt) {

            that._socketConnected = true;
            that._websocket.send("get=Version");
            that._websocket.send("set=DataDirName:data_erp");
            that._websocket.send("method=GetStylesInfo:" + that.reportName);
            if (callbackfun && that._websocket.readyState == 1)
                callbackfun();
        };

        this._websocket.onerror = function (evt) {
            if (evt.target.readyState === 3) {
                ElMessage.error({
                    message: '打印管理器未连接成功，或连接不可用！',
                    type: 'error',
                    duration: 1500,
                });
            }
        };

        this._websocket.onclose = function (evt) {
            ElMessage.error({
                message: '打印管理器连接已断开！',
                type: 'error',
                duration: 1500,
            });
        };

        this._websocket.onmessage = function (evt) {

            console.log("返回了", JSON.stringify(evt.data));
            var iPos = evt.data.indexOf(':');
            var methodName, value;
            if (iPos < 0) {
                methodName = evt.data;
            } else {
                methodName = evt.data.slice(0, iPos);
                value = evt.data.slice(iPos + 1);
            }
        };

    },
    new_doSend: function (message) {
        if (null==this._websocket) {
            this._initWebsocket();
        }
        var that = this;
        var sendPromise = new Promise(function (resolve, reject) {
            that._websocket.onmessage = function (evt) {
                console.log("返回了", JSON.stringify(evt.data));
                var iPos = evt.data.indexOf(':');
                if (iPos > -1) {
                    var resultMethodName = evt.data.slice(0, iPos);
                    var value = evt.data.slice(iPos + 1);
                    resolve(value);
                }
                else {
                    resolve('');
                };

                var methodName, value;
                if (iPos < 0) {
                    methodName = evt.data;
                } else {
                    methodName = evt.data.slice(0, iPos);
                    value = evt.data.slice(iPos + 1);
                }
            }
        });
        console.log("输入了:", message);
        this._websocket.send(message);
        return sendPromise;
    },
    Print: function (data) {
        var that = this;
        var printParam = {};
        printParam.reportName = data.reportName;
        printParam.allowPrint = true;
        printParam.allowExport = true;
        printParam.printTimesLimit = 999;
        // 这个鬼玩意儿用于设计使用
        printParam.baseTemplate = JSON.stringify(this.createDefaultStyle(data.reportName));
        printParam.excuteType = data.excuteType;//"preview 预览, print 打印,design 设计",
        // 这个是数据了
        if (!printParam.excuteType) {
            printParam.excuteType = "design";
        }
        printParam.printData = JSON.stringify(this.createDataInfo(data));
        //数据拼完了
        console.log(printParam);
        that.new_doSend("method=ExecuteByType:" + JSON.stringify(printParam));
    },
    createDefaultStyle: function (reportName) {
        let data = {
            "reportName": "销售订单",
            "pages": [
                {
                    "table": [
                        {
                            "cells": [{ "cellText": "`日期","fontName": "宋体","hAlign": 0,"lineVisible": false,"formatKind": 3},
                                {
                                    "cellText": "`总页码",
                                    "fontName": "宋体",
                                    "hAlign": 2,
                                    "lineVisible": false
                                }
                            ]
                        },


                        {
                            "cells": [
                                { "cellText": "订单编号", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@订单编号：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "预计归还时间", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@预计归还时间：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "领用人", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@领用人：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "领用部门", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@领用部门：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "出库仓库", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@出库仓库：", "fontName": "宋体", "hAlign": 0 },
                                { "cellText": "出库部门", "fontName": "宋体", "hAlign": 0, "lineVisible": false, "formatKind": 3 }, { "cellText": "@出库部门：", "fontName": "宋体", "hAlign": 0 },
                            ]
                        },


                        {
                            "cells": [
                                {"cellText": "行号","fontName": "宋体","hAlign": 0,"isBold": true},
                                {"cellText": "商品名称","fontName": "宋体","hAlign": 0,"isBold": true},
                                {"cellText": "商品数量","fontName": "宋体","hAlign": 0,"isBold": true},
                                {"cellText": "单位","fontName": "宋体","hAlign": 0,"isBold": true},
                                {"cellText": "维保码","fontName": "宋体","hAlign": 0,"isBold": true},
                            ]
                        },
                        {
                            "cells": [
                                {"cellText": "#行号","fontName": "宋体","hAlign": 0,"isBold": true,"formatKind": 0},
                                {"cellText": "#商品名称","fontName": "宋体","hAlign": 0,"isBold": true,"formatKind": 0},
                                {"cellText": "#商品数量","fontName": "宋体","hAlign": 0,"isBold": true,"formatKind": 0},
                                {"cellText": "#单位","fontName": "宋体","hAlign": 0,"isBold": true,"formatKind": 0},
                                {"cellText": "#维保码","fontName": "宋体","hAlign": 0,"isBold": true,"formatKind": 0},
                            ]
                        }
                        ],
                    "pageSetting": {
                        "paperOrientation": 1,
                        "marginTop": 10,
                        "marginLeft": 10,
                        "marginRight": 10,
                        "marginBottom": 10,
                        "showHeaderInPerPage": false,
                        "showFooterInPerPage": false
                    }
                }
            ]
        };
        data.reportName = reportName;
        return data;
    },
    createDataInfo: function (data) {
        //这里需要一个cookieId \"ASP.NET_SessionId=ovprujsru25nhyr2ou2cc0qi\" 后面按实际获取吧
        var cookid = "ASP.NET_SessionId=ovprujsru25nhyr2ou2cc0qi";
        var reportName =
            {
                "ReportUrl": null,
                "Token": null,
                "templatename": data.reportName,
                "Cookie": cookid,
                "CallBackUrl": null, //回调地址
                "ClientParams": {
                    "billtype": 999, //可能为了验证后面的格式 暂时传999 因为管家婆没有使用999这个单据类型
                    "billnumberid": 1, //单据唯一id 用于回调 这里自己看着办吧 不需要回调不管
                    "billstate": 0,
                    "billcode": data.orderNumber,
                    "etypeid": data.employeeName
                } //妈的这里的参数全是用于回调的  心累
            };
        return {
            "reportName": JSON.stringify(reportName),
            //    "callBack": { "token": "null", "callBack": "null" },
            "reportNumber": -1, //报表格式选择  同一个report下面有多个报表样式 换了样式自己换索引 用于后期不预览直接打印当然如果只有一个 这里传啥都一样
            "dataDirName": "data_erp", //报表样式存放位置
            "masterFields": [
                { "name": "订单编号", "dataField": "id", "value": data.id, "hasValue": true, "isFooter": false, },
                { "name": "预计归还时间", "dataField": "recipientDays", "value": data.recipientDays, "hasValue": true, "isFooter": false, },
                { "name": "领用人", "dataField": "recipientName", "value": data.recipientName, "hasValue": true, "isFooter": false, },
                { "name": "领用部门", "dataField": "recipientDepartName", "value": data.recipientDepartName, "hasValue": true, "isFooter": false, },
                { "name": "出库仓库", "dataField": "recipientDepartName", "value": data.stockName, "hasValue": true, "isFooter": false, },
                { "name": "出库部门", "dataField": "recipientDepartName", "value": data.departName, "hasValue": true, "isFooter": false, },
            ],
            "detailFields":
                        [
                            { "name": "行号", "caption": "", "dataField": "productIndex", "size": 0, "columnWidth": 40, "headerAlign": "center", "textAlign": "center" },
                            { "name": "商品名称", "caption": "商品名称", "dataField": "skuName", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "商品数量", "caption": "商品数量", "dataField": "skuQty", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "单位", "caption": "单位", "dataField": "skuUnitName", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                            { "name": "维保码", "caption": "维保码", "dataField": "originalCode", "size": 0, "columnWidth": 90, "headerAlign": "center", "textAlign": "", "footer": "", "replaceText": "", "groupName": "" },
                        ],
            "detailSumTexts": [
                // { "name": "@账面库存", "sumText": "0" },
                // { "name": "账面库存", "sumText": "0" },
                    { "name": "账面库存", "sumText": "0" },
                    { "name": "销售数量", "sumText": "40" },
                    { "name": "销售金额", "sumText": "1400" },
                    { "name": "锁库数量", "sumText": "0" },
                    { "name": "已发货数量", "sumText": "0" }],

            "cookie": cookid,
            "detailDataUrl": "null",//远程数据这里配置 restful可以直接获取到数据的链接可以直接放这里

            "detailData": {
                "itemCount": data.productList.length,
                "itemList": data.productList
            },
            "rowNoExpr": ""//数字公式列，用不到但是要传个空过去
        }
    }

}
export default LocalPrint;