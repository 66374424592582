<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header/>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="维保时间">
        <el-date-picker
            v-model="dataDetail.mtTime"
            type="datetime"
            placeholder="请选择维保时间"
            format="YYYY/MM/DD HH:mm:ss"
            style="width: 100%;"
            clearable
            :disabled="isDisabled"
        />
      </el-descriptions-item>
      <el-descriptions-item label="维保人">
        <employee-select :disabled="isDisabled" :placeholder-tit="'请选择维保人'" v-model="dataDetail.mtEmployeeId"/>
      </el-descriptions-item>
      <el-descriptions-item label="维保单位">
        <el-select :disabled="isDisabled" v-model="dataDetail.unitId" placeholder="请选择维保单位" filterable clearable>
          <el-option v-for="item in unitList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="维保地点">
        <el-input :disabled="isDisabled" v-model="dataDetail.mtAddress" placeholder="请输入维保地点"/>
      </el-descriptions-item>
      <el-descriptions-item label="是否在库"  :span="2">
        <el-select id="aaa" :disabled="isDisabled" v-model="dataDetail.isStock" placeholder="请选择是否在库">
          <el-option label="在库" :value="1"/>
          <el-option label="领用" :value="2"/>
        </el-select>
      </el-descriptions-item>
<!--      <el-descriptions-item label="下次维保">-->
<!--        <el-date-picker-->
<!--            v-model="dataDetail.nextMtTime"-->
<!--            type="datetime"-->
<!--            placeholder="请选择维保时间"-->
<!--            format="YYYY/MM/DD HH:mm:ss"-->
<!--            style="width: 100%;"-->
<!--            clearable-->
<!--            :disabled="isDisabled"-->
<!--        />-->
<!--      </el-descriptions-item>-->
      <el-descriptions-item label="备注">
        <el-input :disabled="isDisabled" v-model="dataDetail.remark" type="textarea" :rows="1"
                  placeholder="请输入备注信息"/>
      </el-descriptions-item>
    </el-descriptions>
    <div style="width: 100%;display: flex;justify-content: flex-end">
      <div style="display: inline-block;width: 400px;">
        <product-list-select v-if="!isDisabled" input-desc="请扫描维保编码/名称/型号/按下回车" :query-params="{enabledOriginal: 1}"
                             @select="selectProduct" @select-one="selectOne" :other-set="true" :show-input-search="false" :common-btn="false"
                             :exists="dataDetail.isStock" :add-exists="true"/>
      </div>
    </div>
    <div class="pro_content">
      <div class="left-content">
        <div>
          <el-form-item label="商品分类">
            <el-input v-model="dataDetail.skuDetail.categoryName" :disabled="true"/>
          </el-form-item>
          <el-form-item label="商品规格">
            <el-input v-model="dataDetail.skuDetail.skuStandard" :disabled="true"/>
          </el-form-item>
          <el-form-item label="商品状态">
            <el-input v-model="dataDetail.skuDetail.stockStatusName" :disabled="true"/>
          </el-form-item>
          <el-form-item label="使用人员">
            <el-input v-model="dataDetail.skuDetail.employeeName" :disabled="true"/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="商品编号">
            <el-input v-model="dataDetail.skuDetail.skuCode" :disabled="true"/>
          </el-form-item>
          <el-form-item label="商品型号">
            <el-input v-model="dataDetail.skuDetail.skuType" :disabled="true"/>
          </el-form-item>
          <el-form-item label="入库时间">
            <el-input v-model="dataDetail.skuDetail.inStockTime" :disabled="true"/>
          </el-form-item>
          <el-form-item label="使用机构">
            <el-input v-model="dataDetail.skuDetail.departName" :disabled="true"/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="商品名称">
            <el-input v-model="dataDetail.skuDetail.skuName" :disabled="true"/>
          </el-form-item>
          <el-form-item label="维保编码">
            <el-input v-model="dataDetail.skuDetail.originalCode" :disabled="true"/>
          </el-form-item>
          <el-form-item label="报废时间">
            <el-input v-model="dataDetail.skuDetail.scrapTime" :disabled="true"/>
          </el-form-item>
          <el-form-item label="领用时间">
            <el-input v-model="dataDetail.skuDetail.useTime" :disabled="true"/>
          </el-form-item>
        </div>
      </div>
      <!--      <div class="right-img">-->
      <!--        <img src="https://ww3.sinaimg.cn/mw690/006cSBLKly1greqe2tw2fj30j60ixdka.jpg" alt="" />-->
      <!--      </div>-->
    </div>
    <h4 style="margin: 10px 0;">维保内容</h4>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="维保类型">
        <dictionary :disabled="isDisabled" v-model="dataDetail.mtType" :place-holder="'请选择维保类型'"
                    :dictionaries="mtTypeList"
                    dict-code="dictCode"
                    dict-name="dictName"/>
      </el-descriptions-item>
      <el-descriptions-item label="维保结果">
        <el-select :disabled="isDisabled" v-model="dataDetail.mtResult" placeholder="请选择维保结果">
          <el-option label="正常" :value="0"/>
          <el-option label="维修" :value="1"/>
          <el-option label="报废" :value="2"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="维保描述" :span="2">
        <el-input :disabled="isDisabled" v-model="dataDetail.mtDescribe" type="textarea" :rows="1"
                  placeholder="请输入维保描述"/>
      </el-descriptions-item>
      <el-descriptions-item label="维保前照片">
        <image-upload :disabled="isDisabled" v-model="dataDetail.mtBeforeImg" :limit="10" width="80px" height="80px"/>
      </el-descriptions-item>
      <el-descriptions-item label="维保后照片">
        <image-upload :disabled="isDisabled" v-model="dataDetail.mtAfterImg" :limit="10" width="80px" height="80px"/>
      </el-descriptions-item>
      <el-descriptions-item label="处理情况" :span="2">
        <el-input :disabled="isDisabled" v-model="dataDetail.handleSituation" type="textarea" :rows="1"
                  placeholder="请输入处理情况"/>
      </el-descriptions-item>
    </el-descriptions>
    <div style="display: flex; justify-content: flex-end" v-if="!isDisabled || dataDetail.status === 10">
      <approval-comp style="display: inline-block" :data-id="dataDetail.id" :save-api="MtRecordApi.approval" v-if="dataDetail.status === 10" />
      <page-button el-type="primary" @click="saveSubmit" v-if="!isDisabled">保存</page-button>
    </div>
  </div>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import commonApi from "@/api/commonApi";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import mtRecordApi from "@/api/main/material/mtRecordApi";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import util from "@/util/util";
import Dictionary from "@/components/h5/Dictionary.vue";
import productApi from "@/api/main/product/productApi";
import mtUnitApi from "@/api/main/material/mtUnitApi";
import ApprovalComp from "@/components/h5/approval/ApprovalComp.vue";
import MtRecordApi from "@/api/main/material/mtRecordApi";

export default {
  name: "",
  computed: {
    MtRecordApi() {
      return MtRecordApi
    }
  },
  components: {
    ApprovalComp,
    PageHeader, EmployeeSelect, ProductListSelect, ImageUpload, Dictionary, PageButton
  },
  data() {
    return {
      dataDetail: {
        mtEmployeeId: "",
        mtAddress: "",
        mtTime: new Date(),
        skuDetail: {}
      },
      // 单位列表
      unitList: [],
      // 维保类型
      mtTypeList: [],
      // 控制查看
      isDisabled: false,
    };
  },
  created() {
    // 查询所有单位信息
    mtUnitApi.queryAll().then(list => this.unitList = list);
    // 查询字典
    util.getUserInfo().then((user) => {
      this.dataDetail.mtEmployeeId = user.id;
      commonApi.queryDictionaryList("MATERIAL_TYPE", user.merchantId).then(list => this.mtTypeList = list);
    });
    // 回显信息
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    }
  },
  methods: {
    findById(id) {
      mtRecordApi.findById(id).then(data => {
        this.dataDetail = data;
        if(this.$routerUtil.query.isUpdated){
          this.isDisabled = data.status !== 20;
        }
        // 查询商品详情
        this.findSkuDetail(data.skuId, data.isStock);
      })
    },
    findSkuDetail(skuId, enabledOriginal) {
      productApi.findByMtInfo(skuId, enabledOriginal).then(data => {
        this.dataDetail.skuDetail = data;
        this.otherInfo(data);
      })
    },
    selectOne(data) {
      if(this.dataDetail.status){
        this.$message.error("单据已完成，无法查询！");
        return;
      }
      if(data.stockStatus === 2){
        this.$message.error("该商品正在维修！");
        return;
      }
      this.dataDetail.skuId = data.id;
      this.dataDetail.originalCode = data.originalCode;
      this.dataDetail.category = data.category
      this.dataDetail.skuDetail = data;
      this.otherInfo(this.dataDetail.skuDetail);

    },
    selectProduct(list) {
      if(this.dataDetail.status){
        this.$message.error("单据已完成，无法查询！");
        return;
      }
      if (list.length > 1) {
        this.$message.error("只能选择一件商品！");
        return;
      }
      this.dataDetail.skuId = list[0].id;
      this.dataDetail.originalCode = data.originalCode;
      this.dataDetail.category = data.category;
      this.dataDetail.skuDetail = list[0];
      this.otherInfo(this.dataDetail.skuDetail);
    },
    // 其它信息
    otherInfo(data) {
      // 商品状态
      if (data.stockStatus === 1) {
        this.dataDetail.skuDetail.stockStatusName = "在库";
      } else if (data.stockStatus === 2) {
        this.dataDetail.skuDetail.stockStatusName = "维修";
        if(this.dataDetail.isStock === 2){
          this.findUser();
        }
      } else {
        this.dataDetail.skuDetail.stockStatusName = "领用";
        // 查询领用人的信息
        this.findUser();
      }
      // 第一次入库时间
      this.queryFirstInStock();
    },
    findUser(){
      mtRecordApi.findByCode(this.dataDetail.skuDetail.originalCode).then(res => {
        this.dataDetail.skuDetail.employeeName = res.recipientName;
        this.dataDetail.skuDetail.departName = res.recipientDepartName;
        this.dataDetail.skuDetail.useTime = res.createdTime;
      })
    },
    // 查询第一次入库时间
    queryFirstInStock() {
      commonApi.findInStock(this.dataDetail.skuDetail.originalCode).then(data => {
        this.dataDetail.skuDetail.inStockTime = data.changeTime;
      })
    },
    // 保存提交
    saveSubmit() {
      mtRecordApi.saveSubmit(this.dataDetail).then(data => {
        this.findById(data.id);
        this.isDisabled = true;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/form.scss";
@import "@/assets/css/theme/handle.scss";
@import "@/assets/css/components/description.scss";
@import "@/assets/css/components/table.scss";

.pro_content {
  height: 185px;
  border: 1px solid #dbdada;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;

  .left-content {
    width: 100%;
    display: flex;
    //justify-content: space-between;
    div {
      width: 90%;

      :deep(.el-input) {
        width: 100%;
      }

      :deep(.el-form-item__label) {
        font-size: 14px;
        color: #606266;
        font-weight: bold;
      }
    }
  }

  .right-img {
    width: 15%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      width: 184px;
      height: 184px;
    }
  }
}
</style>