<template>
  <!-- -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane name="store" label="门店管理" lazy>
        <list-page>
          <search-condition :show-search="true" @search="resetSearch">
            <el-input v-model="searchInfo.searchKey"/>
            <template #option>
              <page-button el-type="success" type="write" @click="$routerUtil.push(`/alpine/merchant/store/detail`)">添加</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table :data="dataList" height="100%" border>
              <el-table-column align="center" label="序号" width="60px" type="index" :align="'center'"/>
              <el-table-column align="center" label="门店名称" prop="storeName"/>
              <el-table-column align="center" label="联系电话" prop="storeMobile"/>
              <el-table-column align="center" label="门店地址" prop="storeAddress" show-overflow-tooltip/>
              <el-table-column align="center" label="操作"  width="200px" :align="'center'">
                <template v-slot="{row}">
                  <table-button @click="toShowStoreCode(row.miniProgramCode)">门店码</table-button>
                  <table-button @click="viewHistory(row)">历史订单</table-button>
                  <table-button type="write" @click="$routerUtil.push(`/alpine/merchant/store/detail?id=${row.id}`)">编辑</table-button>
                  <table-button @click="toDelete(row.id)" el-type="danger" type="delete" message="是否确认删除此数据">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
        </list-page>
      </el-tab-pane>
      <el-tab-pane name="user" label="门店职员" lazy>
        <list-page>
          <search-condition @search="resetEmployeeSearch" :show-search="true">
            <el-input v-model="employeeSearchInfo.searchKey" placeholder="职员名称/手机号" @keydown.enter="resetEmployeeSearch" clearable/>
            <el-select v-model="employeeSearchInfo.dataId" clearable filterable @change="resetEmployeeSearch" placeholder="请选择门店">
              <el-option v-for="item in allStoreList" :key="item.id" :label="item.storeName" :value="item.id"/>
            </el-select>
            <template #option>
              <page-button type="write" el-type="success" @click="toShowAddEmployee">添加</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table :data="employeeList" border height="100%">
              <el-table-column align="center" label="序号" type="index" width="60px" fixed/>
              <el-table-column align="center" label="职员名称" prop="employeeName"/>
              <el-table-column align="center" label="职员电话" prop="employeePhone"/>
              <el-table-column align="center" label="门店名称">
                <template v-slot="{row}">
                  <span v-for="(s,i) in allStoreList" :key="i"><span v-if="s.id === row.storeId">{{s.storeName}}</span></span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="60px" fixed="right" :align="'center'">
                <template v-slot="{row}">
                  <table-button :disabled="!row.storeUserId" type="delete" el-type="danger" message="是否确认删除数据，删除后可以重新添加" @click="deleteEmployee(row.storeUserId)">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-number="employeeSearchInfo.pageNumber" v-model:page-size="employeeSearchInfo.pageSize" :total-count="employeeSearchInfo.totalCount" @onLoad="queryEmployeeList"/>
        </list-page>
      </el-tab-pane>



      <el-tab-pane name="miniAccountCheck" label="账号审核">
        <list-page>
          <search-condition @search="resetAccountSearch" :show-search="true">
            <el-input v-model="accountCheckSearchInfo.searchKey" placeholder="账号昵称/手机号" @keydown.enter="resetAccountSearch" clearable/>
            <el-select v-model="accountCheckSearchInfo.status" clearable filterable @change="resetAccountSearch" placeholder="请选择审核状态">
              <el-option label="未审核" :value="3"/>
              <el-option label="审核通过" :value="2"/>
              <el-option label="未通过" :value="-3"/>
            </el-select>
          </search-condition>
          <table-list>
            <el-table :data="accountCheckList" border height="100%">
              <el-table-column align="center" label="序号" type="index" width="60px" fixed/>
              <el-table-column align="center" label="账号昵称" prop="employeeName"/>
              <el-table-column align="center" label="手机号" prop="employeePhone"/>
              <el-table-column align="center" label="所属门店" prop="storeName"/>
              <el-table-column align="center" label="审核状态">
                <template v-slot="{row}">
                  <el-tag type="success" v-if="row.status === 1">审核通过</el-tag>
                  <el-tag type="warning" v-if="row.status === 3">待审核</el-tag>
                  <el-tag type="error" v-if="row.status === -3">未通过</el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="120px" fixed="right" :align="'center'">
                <template v-slot="{row}">
                  <table-button type="write" el-type="primary" message="是否确认通过该注册申请？" @click="updateAccountCheckStatus(row,false)">通过</table-button>
                  <table-button type="write" el-type="danger" message="是否确认驳回该注册申请？" @click="updateAccountCheckStatus(row,true)">驳回</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-number="accountCheckSearchInfo.pageNumber" v-model:page-size="accountCheckSearchInfo.pageSize" :total-count="accountCheckSearchInfo.totalCount" @onLoad="queryAllAuditAccount"/>
        </list-page>
      </el-tab-pane>
    </el-tabs>
    <el-dialog v-model="showStoreEmployee" width="40%">
      <el-form label-width="70px">
        <el-form-item label="所属门店" class="require">
          <el-select v-model="currentStoreUser.storeId" clearable filterable @change="resetEmployeeSearch">
            <el-option v-for="item in allStoreList" :key="item.id" :label="item.storeName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="职员名称">
          <employee-select v-model="currentStoreUser.employeeId"/>
        </el-form-item>
      </el-form>
      <form-page :show-page="false">
        <page-button @click="showStoreEmployee = false">取消</page-button>
        <page-button type="write" el-type="primary" @click="saveEmployee">保存</page-button>
      </form-page>
    </el-dialog>

    <el-dialog v-model="showHistory" title="历史订单" width="50%">
        <search-condition @search="resetSearchOrder" :show-search="true">
          <template #default>
            <date-range v-model:start-time="orderSearchInfo.startTime" placeholder="请选择开始日期"
                        v-model:end-time="orderSearchInfo.endTime"/>
          </template>
          <template #moreCondition>
            <el-input style="width: 240px" ref="orderNumberInput" v-model="orderSearchInfo.searchKey"
                      placeholder="请输入订单编号"
                      @change="resetSearchOrder"/>
          </template>
        </search-condition>
        <dialog-table>
          <el-table :data="orderList" height="100%" border>
            <el-table-column align="center" label="序号" type="index" width="60px"/>
            <el-table-column align="center" label="订单编号" min-width="200px" prop="id" show-overflow-tooltip/>
            <el-table-column label="订单时间" property="orderTime" min-width="200px" :align="'center'"/>
            <el-table-column label="业务人员" property="clientEmployeeName" width="140px" show-overflow-tooltip/>
            <el-table-column label="操作" :align="'center'" width="100px">
              <template v-slot="{row}">
                <table-button el-type="primary" @click="$routerUtil.push(`/supplyChain/purchase/detail?id=${row.id}`)">查看</table-button>
              </template>
            </el-table-column>
          </el-table>
        </dialog-table>
        <form-page v-model:page-size="orderSearchInfo.pageSize" v-model:page-number="orderSearchInfo.pageNumber"
                   :total-count="orderSearchInfo.totalCount" :default-load="false"></form-page>
      </el-dialog>


    <el-dialog v-model="showStoreCode"  width="30%" title="门店码">
      <div style="width: 100%; display: flex; justify-content: center">
        <el-image style="width: 300px; height: 300px" :preview-src-list="[miniProgramCode]" :preview-teleported="true"
                  show-progress :src="miniProgramCode" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" fit="cover" :initial-index="1">
          <template #error>
            <div style="display: flex; align-items: center">暂未生成</div>
          </template>
        </el-image>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import storeApi from "@/api/alpine/merchant/storeApi";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import merchantPurchaseApi from "@/api/main/supplyChain/merchantPurchaseApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import baseApi from "@/api/baseApi";
export default {
  name: "",
  components: {
    Dictionary,
    DateRange, CustomerSelect, DialogTable,
    TableButton,
    PageButton,
    SearchCondition,
    ListPage, TableList, FormPage, PageHeader, SeparatePage, EmployeeSelect
  },
  data() {
    return {
      showHistory: false,
      searchInfo: {},
      orderSearchInfo: {},
      dataList: [],
      activeName: "store",
      employeeSearchInfo: {},
      employeeList: [],
      allStoreList: [],
      showStoreEmployee: false,
      currentStoreUser: {},
      accountCheckList: [],
      accountCheckSearchInfo: {
        status: 3,
      },
      orderList: [],
      currentStore: {},
      miniProgramCode: "",
      showStoreCode: false,
    };
  },
  created() {
    // 查询全部门店
    storeApi.queryAll().then(list => {
      this.allStoreList = list
    });
    this.orderSearchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.orderSearchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  methods: {
    // 删除一个门店
    toDelete(id){
      storeApi.delete(id).then(() => this.resetSearch())
    },
    toShowStoreCode(code){
      this.miniProgramCode = process.env.VUE_APP_FILE_URL + code;
      this.showStoreCode = true;
    },
    getStoreCode(storeId, storeName){
      // 生成二维码信息、返回一个zip文件
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = `${process.env.VUE_APP_BASE_URL}/order/base/getStoreCode?storeId=${storeId}&storeName=${storeName}`;
      document.body.appendChild(link);
      link.click();
    },
    resetSearchOrder(){
      this.orderSearchInfo.pageNumber = 1;
      this.viewHistory(this.currentStore);
    },
    viewHistory(data){
      this.currentStore = data;
      // 查询历史订单信息
      this.orderSearchInfo.clientId = data.id;
      merchantPurchaseApi.queryList(this.orderSearchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.orderList = res.records;
        this.showHistory = true;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    resetAccountSearch(){
      this.accountCheckSearchInfo.pageNumber = 1;
      this.queryAllAuditAccount();
    },
    // 查询所有账号
    queryAllAuditAccount(){
      storeApi.queryAllAuditAccount(this.accountCheckSearchInfo).then(res =>{
        this.accountCheckList = res.records;
        this.accountCheckSearchInfo.totalCount = res.totalCount;
      })
    },
    updateAccountCheckStatus(row,isFail){
      let data = {...row};
      if(isFail){
        // 驳回
        data.status = -3;
      }else{
        // 通过
        data.status = 1;
      }
      storeApi.updateAccountCheckStatus(data).then(()=>{
        this.$message.success("操作成功");
        this.queryAllAuditAccount();
      })
    },
    queryList() {
      storeApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    resetEmployeeSearch() {
      this.employeeSearchInfo.pageNumber = 1;
      this.queryEmployeeList();
    },
    queryEmployeeList() {
      storeApi.queryEmployees(this.employeeSearchInfo).then(res => {
        this.employeeSearchInfo.totalCount = res.totalCount;
        this.employeeList = res.records;
      })
    },
    toShowAddEmployee() {
      if (this.employeeSearchInfo.dataId) {
        this.currentStoreUser.storeId = this.employeeSearchInfo.dataId;
      }
      this.showStoreEmployee = true;
    },
    saveEmployee() {
      if (!this.currentStoreUser.storeId) {
        this.$message.error("请选择门店");
        return;
      }
      if (!this.currentStoreUser.employeeId) {
        this.$message.error("请选择客户");
        return;
      }
      storeApi.addStoreEmployee(this.currentStoreUser).then(() => {
        this.$message.success("保存成功")
        this.queryEmployeeList();
        this.showStoreEmployee = false;
      })
    },
    deleteEmployee(id) {
      storeApi.deleteEmployee(id).then(() => {
        this.queryEmployeeList()
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>