<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="ex" el-type="primary" @click="exportList">导出</page-button>
          <page-button type="write" el-type="success" @click="toGo">新建</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
          <el-select v-model="searchInfo.stockId" placeholder="选择仓库" size="default" clearable>
            <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
          </el-select>
<!--          <el-input v-model="searchInfo.searchKey" placeholder="请输入备注信息"/>-->
        </template>
      </search-condition>
      <table-list>
        <el-table stripe :data="inOrderList" ref="multipleTable" height="100%">
          <el-table-column align="center" type="index" label="序号" width="60px" fixed/>
          <el-table-column align="center" label="订单编号" width="250" fixed>
            <template v-slot="{row}">
              <table-button class="add" el-type="primary" type="write"
                            @click="viewDetail(row.id)">{{ row.id }}
              </table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdTime" label="单据日期" show-overflow-tooltip fixed/>
          <el-table-column align="center" prop="stockName" label="仓库名称" show-overflow-tooltip />
          <!--        <el-table-column align="center" prop="departName" label="部门名称"/>-->
          <el-table-column align="center" label="状态" show-overflow-tooltip width="100px">
            <template v-slot="{row}">
              <el-tag :type="row.status === 99 ? 'success' : 'warning'">
                {{ row.status === 99 ? '已完成' : '草稿' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createdName" label="创建人员" show-overflow-tooltip/>

          <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip/>
          <el-table-column align="center" label="操作 " fixed="right">
            <template #default="scope">
              <table-button :disabled="scope.row.status !== 0" type="write" el-type="danger" message="确定删除该条草稿?" @click="deleteRow(scope.row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false"/>
    </list-page>
  </div>

</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import ShowSpecific from "@/components/h5/product/ShowSpecific.vue";
import commonApi from "@/api/commonApi";
import PageHeader from "@/components/h5/layout/PageHeader";
import stockInventoryApi from "@/api/main/stock/stockInventoryApi";
import util from "@/util/util";

export default {
  components: {
    PageButton,
    FormPage,
    ListPage, SearchCondition, TableList, DateRange, ShowSpecific, PageHeader
  },
  data() {
    return {
      stockId: "",
      stockList: [], //仓库列表
      inOrderList: [], // 入库订单列表
      searchInfo: {},
      // 溯源码功能
      originalSwitch: false,
      originalType: "", // 溯源码类型
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  created() {
    // 初始化，默认查询一个月内的数据
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 获取溯源码开关
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    // 查询溯源码类型
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.originalType = setting.settingValue);
    //获取仓库列表
    stockApi.queryListByNotCondition().then(res =>this.stockList = res);
  },
  methods: {
    viewDetail(id){
      if (this.originalSwitch) {
        // 溯源码
        if(this.originalType === "MATERIAL"){
          // 如果是商品的话走这里
          this.$routerUtil.push(`/stock/inventory/detail-originalWz?inoutStorage=${id}`);
        }else{
          this.$routerUtil.push(`/stock/inventory/detail-original?inoutStorage=${id}`);
        }
      } else {
        this.$routerUtil.push(`/stock/inventory/detail?inoutStorage=${id}`);
      }

    },
    exportList(){
      util.exportExcel("/order/stock/inventory/exportList", this.searchInfo);
    },
    deleteRow(row){
      if(row.status !== 0){
        this.$message.error("只能删除草稿！！！")
      }
      stockInventoryApi.delete(row.id).then(()=>{
        this.queryList();
      });
    },
    toGo() {
      if (this.originalSwitch) {
        // 溯源码
        if(this.originalType === "MATERIAL"){
          // 如果是商品的话走这里
          this.$routerUtil.push("/stock/inventory/detail-originalWz");
        }else{
          this.$routerUtil.push("/stock/inventory/detail-original");
        }
      } else {
        this.$routerUtil.push("/stock/inventory/detail");
      }
    },
    queryList() {
      stockInventoryApi.queryList(this.searchInfo,this.stockId).then(res=>{
        this.inOrderList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // viewDetail(row){
    //   let path = this.originalSwitch? "/stock/instock/detail-original": "/stock/inventory/detail";
    //   this.$routerUtil.push({
    //     path,
    //     query: {
    //       inoutStorage: row.id,
    //     }
    //   });
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
.add:hover {
  text-decoration: underline;
}
</style>