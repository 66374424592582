<template>
  <list-page>
    <!--      <page-header>-->
    <!--        <template #default>-->

    <!--&lt;!&ndash;          <page-button v-if="originalSwitch" :el-type=" showEnabled ? 'danger' : 'warning'" @click="showEnabled = !showEnabled">{{ showEnabled ? '关闭激活码' : '启用激活码' }}</page-button>&ndash;&gt;-->
    <!--        </template>-->
    <!--      </page-header>-->
    <search-condition @search="resetSearch">
      <template #default>
        <div class="condition" style="display: inline-block;width: 240px" v-if="userInfo.merchantId === '10000'">
          <client-select v-model="searchInfo.merchantId" placeholder="我的" :query-api="queryClientApi"/>
        </div>
        <el-select v-model="searchInfo.stockId" placeholder="全部仓库" size="default" clearable>
          <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
        </el-select>
        <div style="display: inline-block; margin-right: 10px; width: 240px">
          <dictionary v-model="searchInfo.category" :dictionaries="categoryList" dict-code="dictCode"
                      dict-name="dictName" :place-holder="'请选择商品分类'"
                      @clear-data="clearCode" style="width: 100%"/>
        </div>
        <el-select v-model="searchInfo.exists" placeholder="请选择库存状态" clearable>
          <el-option label="有库存" :value="1"/>
          <el-option label="无库存" :value="-1"/>
        </el-select>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入商品名称" clearable/>
      </template>
      <template #option>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="primary" @click="exportList">导出</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table stripe :data="stockProductList" height="100%" show-summary :summary-method="getSummary"
                ref="multipleTable" @row-click="clickRow">
        <el-table-column v-if="showEnabled" type="selection" align="center" width="60px"/>
        <el-table-column align="center" type="index" label="序号" width="80px"/>
        <el-table-column align="center" prop="skuName" label="商品名称" show-overflow-tooltip min-width="150px"/>
        <el-table-column align="center" prop="skuUnitName" label="基本单位" min-width="100px"/>
<!--        <el-table-column align="center" prop="skuCode" label="商品条码" min-width="150px"/>-->
        <el-table-column align="center" prop="categoryName" label="商品分类" min-width="120px" />
        <el-table-column align="center" label="仓库名称" prop="stockName" min-width="120px"/>
        <el-table-column align="center" prop="stockQty" label="库存余量" width="100px">
          <template v-slot="{row}">
            <!-- 溯源码 -->
            <table-button v-if="originalSwitch" @click="toShowOriginalCode(row)">{{ row.stockQty }}</table-button>
            <span v-else>{{ row.stockQty }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="averagePrice" label="成本均价" win100px/>
        <el-table-column align="center" prop="totalPrice" label="库存金额" width="100px">
          <template v-slot="{row}">
            <money :value="row.totalPrice" :notation="false"/>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="100px">
          <template v-slot="{row}">
            <table-button @click="toShowChangeList(row)">变更记录</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
               :total-count="searchInfo.totalCount" @onLoad="queryList">
      <page-button el-type="success" v-if="showEnabled" @click="generateEnableCode">生成激活码</page-button>
    </form-page>
  </list-page>

  <el-dialog v-model="showHistory" title="库存变更记录">
    <dialog-table>
      <el-table stripe :data="historyList" height="100%">
        <el-table-column label="序号" width="60px" type="index"/>
        <el-table-column label="变更时间" prop="changeTime" show-overflow-tooltip/>
        <el-table-column label="变更类型" align="center" show-overflow-tooltip>
          <template v-slot="{row}">
            <span v-if="row.type === 1">入库</span>
            <span v-else-if="row.type === 2">出库</span>
            <span v-else-if="row.type === 3">调拨入库</span>
            <span v-else-if="row.type === 4">调拨出库</span>
            <span v-else-if="row.type === 51">库存盘点 > 报溢单</span>
            <span v-else-if="row.type === 52">库存盘点 > 报损单</span>
            <span v-else-if="row.type === 98">领用出库</span>
            <span v-else-if="row.type === 99">零售质保</span>
          </template>
        </el-table-column>
        <el-table-column label="变更数量" width="100px" :align="'center'">
          <template v-slot="{row}">
            <span v-if="row.type === 2 || row.type === 4 || row.type === 52 || row.type === 98">-</span>
            <span v-if="row.type === 1 || row.type === 3 || row.type === 51">+</span>
            <span>{{ row.changeQty }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单据编号" prop="orderId" :align="'center'" show-overflow-tooltip>
          <template v-slot="{row}">
            <table-button @click="checkoutChangeOrder(row)">{{ row.orderId }}</table-button>
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
    <form-page v-model:page-size="historySearchInfo.pageSize" v-model:page-number="historySearchInfo.pageNumber"
               :total-count="historySearchInfo.totalCount" @onLoad="queryHistoryList"></form-page>
  </el-dialog>
  <el-dialog v-model="showOriginalList" title="溯源码详情" width="40%">
    <dialog-table>
      <el-table stripe :data="originalCodeList" height="100%">
        <el-table-column type="index" label="序号" width="60px" :align="'center'"/>
        <el-table-column align="center" label="商品名称" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">{{ currentProduct.skuName }}</template>
        </el-table-column>
        <el-table-column label="基本单位" width="100px" :align="'center'">
          <template v-slot="{row}">{{ currentProduct.skuUnitName }}</template>
        </el-table-column>
        <el-table-column label="外码" align="center" prop="originalCode" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">
            <span v-if="row.originalCode">{{ row.originalCode }}</span>
            <span v-else>暂未添加</span>
          </template>
        </el-table-column>
        <el-table-column label="内码" align="center" prop="originalCode" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">
            <span v-if="row.inOriginalCode">{{ row.inOriginalCode }}</span>
            <span v-else>暂未添加</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="货位" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">
            <dictionary :disabled="true" v-model="row.storageId" :dictionaries="storageList" dict-code="id"
                        dict-name="name"/>
          </template>
        </el-table-column>
        <!--        <el-table-column label="状态" show-overflow-tooltip min-width="150px">-->
        <!--          <template v-slot="{row}">-->
        <!--            <span v-if="row.status === 1">在库</span>-->
        <!--            <span v-if="row.status === 2">维修</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
    </dialog-table>
    <form-page @onLoad="queryOriginalCodeList" v-model:page-number="originalSearchInfo.pageNumber"
               v-model:page-size="originalSearchInfo.pageSize" :total-count="originalSearchInfo.totalCount"
               :show-simple="true" :default-load="false">
      <page-button el-type="primary" @click="showOriginalList = false">确定</page-button>
    </form-page>
  </el-dialog>
</template>

<script>
import ListPage from '@/components/h5/layout/ListPage.vue';
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import stockApi from '@/api/main/stock/stockApi';
import stockProductApi from "@/api/main/stock/stockProductApi";
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import ShowSpecific from "@/components/h5/product/ShowSpecific.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import commonApi from "@/api/commonApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import util from "@/util/util";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import {h, watch} from "vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import Money from "@/components/h5/Money.vue";

export default {
  name: "ProductInventory",
  props: {
    stockType: {default: 1},
    stockId: {default: ""}
  },
  components: {
    Money,
    PageButton,
    DialogTable,
    FormPage,
    TableButton, Dictionary,
    ListPage, SearchCondition, TableList, ShowSpecific, PageHeader, ClientSelect
  },
  data() {
    return {
      stockList: [], //仓库列表
      searchInfo: {
        exists: 1,
        // stockType: 1, // 只查询有库存的商品
      },
      stockProductList: [],
      showStockProductDetail: false,
      inOutOrderList: [], //当前库存商品的历史出入库记录
      // 当前正在查看的商品库存信息
      currentStockInfo: {},
      // 商品库存记录
      historyList: [],
      showHistory: false,
      historySearchInfo: {},
      departList: [],
      originalSwitch: false,
      showOriginalList: false,// 显示溯源码详情
      originalCodeList: [], // 溯源码列表
      currentProduct: {}, // 当前商品
      originalSearchInfo: {}, // 溯源码查询条件
      userInfo: {},
      queryClientApi: null,
      // 统计信息
      statistic: {},
      stockId: "",
      // 生成激活码按钮
      showEnabled: false,
      storageList: [],
      categoryList: [],
    };
  },
  created() {
    // 查询商品分类字典
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.categoryList = list);
    util.getUserInfo().then(user => {
      this.userInfo = user;
      this.queryClientApi = commonApi.queryMerchantList;
    })
    commonApi.queryDepartList().then(res => this.departList = res);
    // 初始化，默认查询一个月内的数据
    this.searchInfo.startTime = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";

    this.historySearchInfo.startTime = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).format("yyyy-MM-dd") + " 00:00:00";
    this.historySearchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    //获取仓库列表
    this.queryStockList();
    //获取商品库存列表
    this.queryList();
    // 是否开启了溯源码功能
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
  },
  mounted() {


    watch(() => this.stockId, () => {
      if (this.stockId) {
        console.log("111111111111111");
        this.searchInfo.stockId = this.stockId;
        this.queryList();
      }
    })
  },
  methods: {
    generateEnableCode() {
      let dataList = this.$refs.multipleTable.getSelectionRows();

    },
    clickRow(data) {
      if (!this.showEnabled) {
        return;
      }
      data.checked = !data.checked;
      this.$refs.multipleTable.toggleRowSelection(data, data.checked);
    },
    exportList() {
      util.exportExcel("/order/stock/product/exportList", this.searchInfo);
    },
    clearDic() {
      this.searchInfo.departCode = "";
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },
    //获取商品库存
    queryList() {
      this.searchInfo.stockType = this.stockType;
      stockProductApi.queryProductStockList(this.searchInfo).then(res => {
        this.stockProductList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
      this.stockStatistic();
    },
    //库存统计
    stockStatistic() {
      stockProductApi.queryProductStockStatistic(this.searchInfo).then(res => {
        this.statistic = res;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    clearCode() {
      this.searchInfo.category = "";
    },
    // 库存变更记录
    toShowChangeList(data) {
      if (!data.id) {
        this.$message.error("暂无此商品的库存及变更记录")
        return;
      }
      this.currentStockInfo = data;
      this.showHistory = true;
      // 查询列表信息
      this.restartHistorySearch();
    },
    restartHistorySearch() {
      this.historySearchInfo.pageNumber = 1;
      this.historySearchInfo.dataId = this.currentStockInfo.id;
      this.queryHistoryList();
    },
    queryHistoryList() {
      stockProductApi.queryChangeHistory(this.historySearchInfo).then(res => {
        this.historyList = res.records;
        this.historySearchInfo.totalCount = res.totalCount;
      })
    },
    // 查看库存详情
    checkoutChangeOrder(row) {
      if (row.type === 1) {
        // 入库单
        if (this.originalSwitch) {
          // 溯源码
          this.$routerUtil.push(`/stock/instock/detail-original?inoutStorage=${row.orderId}`);
        } else {
          this.$routerUtil.push(`/stock/instock/detail-simple?inoutStorage=${row.orderId}`);
        }
      } else if (row.type === 2) {
        // 出库单
        if (this.originalSwitch) {
          // 溯源码
          this.$routerUtil.push(`/stock/outstock/detail-original?inoutStorage=${row.orderId}`);
        } else {
          this.$routerUtil.push(`/stock/outstock/detail?inoutStorage=${row.orderId}`);
        }
      } else if (row.type === 3) {
        // 调拨入库
        this.$routerUtil.push(``);
      } else if (row.type === 4) {
        // 调拨出库
        this.$routerUtil.push(``);
      } else if (row.type === 51 || row.type === 52) {
        // 盘点
        this.$routerUtil.push(`/stock/inventory/detail?inoutStorage=${row.orderId}`);
      } else if (row.type === 99) {
        // 零售质保
        this.$routerUtil.push(`/report/offline-order/detail?id=${row.orderId}`)
      } else if (row.type === 98) {
        // 领用出库
        this.$routerUtil.push(`/stock/requisite/detail?inoutStorage=${row.orderId}`)
      }
    },
    toShowOriginalCode(data) {
      if (!data.stockQty) {
        this.$message.error("暂无库存");
        return;
      }
      this.currentProduct = {...data};
      this.resetOriginalCodeList();
    },
    resetOriginalCodeList() {
      this.originalSearchInfo.pageNumber = 1;
      this.queryOriginalCodeList();
    },
    queryOriginalCodeList() {
      stockApi.queryStorage(this.currentProduct.stockId).then(list => this.storageList = list);
      let param = {
        ...this.originalSearchInfo,
        merchantId: this.currentProduct.merchantId,
        stockId: this.currentProduct.stockId,
        skuId: this.currentProduct.skuId,
      }
      stockProductApi.queryOriginal2Code(param).then(res => {
        this.originalCodeList = res.records;
        this.originalSearchInfo.totalCount = res.totalCount;
        this.showOriginalList = true;
      })
    },
    getSummary(param) {
      const {columns} = param;
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }

        if (index === (this.showEnabled ? 7 : 6)) {
          sums[index] = h('div', [Number(this.statistic.stockQty).toFixed(2)])
        }

        if (index === (this.showEnabled ? 8 : 7)) {
          sums[index] = h('div', [Number(this.statistic.averagePrice).toFixed(2)])
        }

        if (index === (this.showEnabled ? 9 : 8)) {
          sums[index] = h('div', [Number(this.statistic.totalPrice).toFixed(2)])
        }

      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>

