import axios from "../../../util/axios";

export default {

// 查询
    queryList: (searchInfo) => axios.get({url: `/order/material/setting/queryList`, data: searchInfo}),
    // 保存提交
    saveSubmit: (data) => axios.post({url: `/order/material/setting/saveSubmit`, data: data}),
    // 删除
    deleteRow: (id) => axios.del({url: `/order/material/setting/delete`,data:{id}}),
    // 查询全部
    queryAll: () => axios.get({url: `/order/material/setting/queryAll`}),


}