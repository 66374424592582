<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <page-header>
          <template #default> </template>
        </page-header>
        <div>
          <el-descriptions :column="2" size="small" border>
            <el-descriptions-item label="单据日期" label-class-name="require">
              <el-date-picker
                style="width: 100%"
                :disabled="isDisabled"
                v-model="headInfo.createdTime"
                type="date"
                placeholder="选择日期时间"
              />
            </el-descriptions-item>

            <el-descriptions-item label="领用人" label-class-name="require">
              <el-select
                v-model="headInfo.recipientId"
                :disabled="isDisabled"
                placeholder="请选择领用人"
                size="default"
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.id"
                  :label="item.employeeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item label="领用部门" label-class-name="require">
              <el-select
                v-model="headInfo.recipientDepartId"
                :disabled="isDisabled"
                placeholder="请选择领用部门"
                size="default"
              >
                <el-option
                  v-for="item in departList"
                  :key="item.id"
                  :label="item.departName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item
              label="预计归还时间"
              label-class-name="require"
            >
              <el-date-picker
                style="width: 100%"
                :disabled="isDisabled"
                v-model="headInfo.recipientDays"
                type="date"
                placeholder="选择日期时间"
              />
            </el-descriptions-item>

            <el-descriptions-item label="出库仓库" label-class-name="require">
              <el-select
                v-model="headInfo.stockId"
                :disabled="isDisabled"
                placeholder="请选择仓库"
                size="default"
                @change="toSelectStockChange"
              >
                <el-option
                  v-for="item in stockList"
                  :key="item.id"
                  :label="item.stockName"
                  :value="item.id"
                />
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item
              label="出库部门"
              :span="2"
              label-class-name="require"
            >
              <dictionary
                :disabled="true"
                v-model="headInfo.departCode"
                :dictionaries="departList"
                dict-code="departCode"
                dict-name="departName"
              />
            </el-descriptions-item>

            <el-descriptions-item label="备注" :span="2">
              <el-input
                :disabled="isDisabled"
                v-model="headInfo.remark"
                type="textarea"
                :rows="1"
                class="el-input-text"
              />
            </el-descriptions-item>
          </el-descriptions>
          <div
            v-if="!isDisabled"
            style="display: flex; justify-content: flex-end; margin-bottom: 5px"
          >
            <div style="width: 400px">
              <product-list-select
                :query-list-api="queryListApi"
                :selected-product-list="headInfo.detailList"
                :query-params="{ enabledOriginal: 1 }"
                :show-stock="true"
                :is-stock="true"
                :stock-id="headInfo.stockId"
                @select-one="selectOne"
                :exists="1"
                product-id-column="originalCode"
                @select="selectProduct"
                @clear="deleteBatch"
              />
            </div>
          </div>
        </div>

        <el-table stripe :data="headInfo.detailList" ref="finalTable">
          <el-table-column type="selection" width="55" />
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="80"
          />
          <el-table-column
            align="center"
            prop="skuName"
            label="商品名称"
            min-width="150px"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            prop="skuUnitName"
            label="基本单位"
            width="120px"
          />
          <el-table-column
            align="center"
            prop="skuCode"
            label="商品条码"
            min-width="150px"
          />
          <el-table-column
            align="center"
            label="数量"
            width="200"
            prop="skuQty"
          />
          <!--          <el-table-column align="center" prop="buyPrice" label="成本单价">-->
          <!--            <template v-slot="{row}">-->
          <!--              <el-input :disabled="isDisabled" v-model="row.buyPrice" type="number"/>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column align="center" label="成本金额">-->
          <!--            <template v-slot="{row}">-->
          <!--              <money :value="row.skuQty * row.buyPrice"/>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{ row, $index }">
              <order-original-code
                :show-merchant-code="true"
                :enabled-select="false"
                :disabled="isDisabled"
                @addOriginalCode="addOriginalCode2List($index, $event)"
                :code-list="row.originalCodeList"
                :sku-name="row.skuName"
              />
              <table-button
                type="danger"
                el-type="danger"
                v-if="!isDisabled"
                @click="deletedetailList(row, $index)"
              >
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <page-button
          v-if="!isDisabled"
          type="write"
          el-type="success"
          @click="saveDraft"
          >保存草稿</page-button
        >
        <page-button
          v-if="!isDisabled"
          type="write"
          el-type="primary"
          @click="saveSubmit"
          >保存单据</page-button
        >
        <approval-comp
          style="display: inline-block"
          :data-id="headInfo.id"
          :save-api="RecipientBackApi.approval"
          v-if="headInfo.status === 10"
        />
        <el-dropdown
          split-button
          type="primary"
          @click="toPrint"
          @command="handlerSelect"
          style="margin-top: 1px"
        >
          打印
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Edit" command="setPrint"
                >打印设置</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </form-page>
    </list-page>
  </div>
  <el-dialog v-model="showOriginalDialog" title="添加溯源码" width="500px">
    <div>{{ currentProduct.skuName }}溯源码</div>
    <dialog-table>
      <el-table
        stripe
        id="skuOriginalTable"
        :data="skuOriginalList"
        height="100%"
      >
        <el-table-column
          label="序号"
          type="index"
          width="60px"
          :align="'center'"
        />
        <el-table-column label="溯源码">
          <template v-slot="{ row }">
            <el-input
              v-model="row.skuOriginalCode"
              placeholder="请输入溯源码"
              @change="addTableOriginalCode"
            />
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
    <div style="text-align: right">
      <page-button>取消</page-button>
      <page-button el-type="primary">确定</page-button>
    </div>
  </el-dialog>

  <el-dialog v-model="showOther" width="30%" height="310px" title="保存草稿">
    <div>
      <div style="height: 238px; overflow: auto">
        <el-form label-width="70px" style="">
          <div style="height: 198px">
            <el-form-item label="创建人">
              <el-input disabled v-model="headInfo.createdName" />
            </el-form-item>
            <el-form-item label="金额合计">
              <el-input disabled v-model="headInfo.amount" />
            </el-form-item>
            <el-form-item label="数量合计">
              <el-input disabled v-model="headInfo.totalQty" />
            </el-form-item>
            <el-form-item label="备注信息">
              <el-input disabled v-model="headInfo.remark" />
            </el-form-item>
          </div>
        </el-form>
        <div style="display: flex; justify-content: space-between">
          <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large" />
          <div>
            <page-button el-type="info" @click="showOther = false"
              >取消</page-button
            >
            <page-button
              el-type="primary"
              v-if="headInfo.isDraft"
              @click="saveDraft"
              >确认</page-button
            >
            <page-button el-type="primary" v-else @click="saveSubmit"
              >确认</page-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import elementElMessage from "@/util/elementElMessage";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import commonApi from "@/api/commonApi";
import Money from "@/components/h5/Money.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import DialogTable from "@/components/h5/layout/DialogTable";
import PageHeader from "@/components/h5/layout/PageHeader";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import stockOutStorageApi from "@/api/main/stock/stockOutStorageApi";
import { Edit } from "@element-plus/icons-vue";
import OrderOriginalCode from "@/views/main/supplyChain/merchantOrder/components/OrderOriginalCode.vue";
import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import recipientBackApi from "@/api/main/stock/recipientBackApi";
import GjpLoaclPrint_jywz from "@/util/GjpLoaclPrint_jywz";
import ApprovalComp from "@/components/h5/approval/ApprovalComp.vue";
import RecipientBackApi from "@/api/main/stock/recipientBackApi";
export default {
  name: "InStorageRecordDetail",
  components: {
    OrderOriginalCode,
    FormPage,
    TableButton,
    PageButton,
    ApprovalComp,
    ListPage,
    TableList,
    ProductListSelect,
    Money,
    Dictionary,
    DialogTable,
    PageHeader,
  },
  data() {
    return {
      showOther: false,
      isAutoPrint: true,
      isDisabled: false, //是否禁用部分组件
      searchInfo: {},
      showSaveProduct: false,
      productList: [], //该商户可以入库的所有商品
      skuList: [], //入库商品对应的所有sku
      stockList: [], //仓库列表
      stockProduct: {}, //入库商品信息
      headInfo: {
        remark: "", //备注
        createdName: "", //创建
        createdTime: new Date(), //时间
        stockId: "", //选择仓库
        status: null,
        depart: "",
        detailList: [], //需要入库的商品
        totalQty: "",
        amount: "",
        isDraft: true,
        inoutType: "LYCK",
        recipientBack: 0,
      },
      queryListApi: {},
      allUnits: [],
      // 是否开启仓库挂载部门功能
      stockDepartSwitch: false,
      // 领用方所有信息
      recipientList: [],
      // 部门
      departList: [],
      // 职员
      employeeList: [],
      // 当前选中的商品
      currentProduct: {
        skuOriginalCode: "",
      },
      showOriginalDialog: false,
      skuOriginalList: [],
      dictionaryList: [],
    };
  },
  created() {
    // 获取字典列表
    // commonApi.queryDictionaryList("OUTSTOCK_TYPE").then(res => this.dictionaryList = res);
    // 获取字典列表
    commonApi.queryAllTypeList(2).then((res) => (this.dictionaryList = res));
    // 查询单位
    commonApi.queryUnits().then((list) => (this.allUnits = list));
    // 查询配置项
    commonApi
      .findSetting("STOCK_BANKUP")
      .then(
        (setting) => (this.stockDepartSwitch = setting.settingValue === "1")
      );
    // 查询部门，查询成树结构返回
    commonApi.queryDepart().then((list) => {
      this.departList = list;
      // 查询所有职员信息
      commonApi.queryAllEmployee().then((list2) => {
        this.employeeList = list2;
        this.recipientList.push(...list, ...list2);
      });
    });

    this.queryListApi = stockInoutApi.queryProductAuthorizeList;
    //获取仓库列表
    this.queryStockList();
    if (this.$routerUtil.query.inoutStorage) {
      this.echoInoutProduct(this.$routerUtil.query.inoutStorage);
    } else {
      // 新建
      commonApi
        .findSetting("STOCK_ORIGINAL_TYPE")
        .then(
          (setting) => (this.headInfo.originalCodeType = setting.settingValue)
        );
      util.getUserInfo().then((user) => {
        this.headInfo.createdName = user.userName;
      });
    }
  },
  computed: {
    RecipientBackApi() {
      return RecipientBackApi;
    },
    Edit() {
      return Edit;
    },
    // 获取入库商品列表
    getTime() {
      return this.headInfo.createdTime.format("yyyy-MM-dd");
    },
  },
  methods: {
    handlerSelect(command) {
      if (command === "setPrint") {
        this.toSetPrint();
      }
    },
    toShow(targetInfo) {
      if (!this.checkData()) return;
      this.headInfo.isDraft = targetInfo;
      this.showOther = true;
      // 商品总金额
      let list = this.headInfo.detailList;
      let countQty = 0;
      let amount = 0;
      for (let i = 0; i < list.length; i++) {
        countQty += list[i].skuQty;
        amount += list[i].skuQty * list[i].buyPrice;
      }
      this.headInfo.totalQty = countQty;
      this.headInfo.amount = amount;
    },
    //商品信息回显
    echoInoutProduct(id) {
      //判断该页面是否有参数传入
      stockOutStorageApi.findByInOrderId(id).then((res) => {
        this.headInfo = res;
        if (res.status !== 0 && res.status !== 13) {
          this.isDisabled = true;
        }
      });
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryProductList();
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then((res) => {
        this.stockList = res;
      });
    },

    deleteBatch() {
      let rows = this.$refs.finalTable.getSelectionRows();
      for (let i = 0; i < rows.length; i++) {
        this.headInfo.detailList.splice(
          this.headInfo.detailList.indexOf(rows[i]),
          1
        );
      }
    },

    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          // 部门
          this.headInfo.departId = this.stockList[i].bankupId;
          break;
        }
      }
      for (let i = 0; i < this.departList.length; i++) {
        if (this.headInfo.departId === this.departList[i].id) {
          this.headInfo.departCode = this.departList[i].departCode;
        }
      }
    },
    deletedetailList(row, index) {
      this.headInfo.detailList.splice(index, 1);
    },
    saveDraft() {
      this.headInfo.status = 0;
      let idx = this.departList.findIndex(
        (item) => item.id === this.headInfo.recipientId
      );
      if (idx !== -1) {
        this.headInfo.recipientType = 2;
      } else {
        this.headInfo.recipientType = 1;
      }
      if (this.checkData()) {
        recipientBackApi.saveDraft(this.headInfo).then((res) => {
          this.headInfo = res;
          if (this.isAutoPrint) {
            this.toPrint();
          }
          this.showOther = false;
        });
        // this.$routerUtil.push("/stock/instock");
      }
    },
    saveSubmit() {
      this.headInfo.status = 99;
      let idx = this.departList.findIndex(
        (item) => item.id === this.headInfo.recipientId
      );
      if (idx !== -1) {
        this.headInfo.recipientType = 2;
      } else {
        this.headInfo.recipientType = 1;
      }
      if (this.checkData()) {
        recipientBackApi.saveSubmit(this.headInfo).then((res) => {
          this.headInfo = res;
          this.isDisabled = true;
          if (this.isAutoPrint) {
            this.toPrint();
          }
          this.showOther = false;
          // this.$routerUtil.push("/stock/instock");
        });
      }
    },
    checkData() {
      if (!this.headInfo.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      if (!this.headInfo.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (!this.headInfo.inoutType) {
        this.$message.error("请选择入库类型");
        return false;
      }
      if (this.headInfo.detailList.length === 0) {
        elementElMessage.toShowMessage("入库商品不能为空", "warning");
        return false;
      }
      let totalQty = 0;
      this.headInfo.detailList.forEach((item) => {
        totalQty += item.skuQty;
      });
      if (totalQty <= 0) {
        this.$message.error("商品数量不能为空");
        return false;
      }
      return true;
    },
    selectOne(data) {
      // 判断是否已经存在
      if (this.headInfo.detailList.length > 0) {
        for (let i = 0; i < this.headInfo.detailList.length; i++) {
          if (this.headInfo.detailList[i].skuId === data.id) {
            for (
              let j = 0;
              j < this.headInfo.detailList[i].originalCodeList.length;
              j++
            ) {
              if (
                this.headInfo.detailList[i].originalCodeList[j].originalCode ===
                data.originalCode
              ) {
                this.$message.error("该商品已经添加过了！");
                return;
              }
            }
          }
        }
      }
      if (!this.headInfo.detailList || this.headInfo.detailList === 0) {
        this.selectProduct([{ ...data }]);
        return;
      }
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        if (this.headInfo.detailList[i].skuId === data.id) {
          // 溯源码+1
          this.headInfo.detailList[i].originalCodeList.push({
            originalCode: data.originalCode,
            inOriginalCode: data.inOriginalCode,
          });
          // 更新
          this.addOriginalCode2List(
            i,
            this.headInfo.detailList[i].originalCodeList
          );
          return;
        }
      }
      this.selectProduct([{ ...data }]);
    },
    // 商品组件选中商品后，回调显示
    selectProduct(productSkuList) {
      // 向detail中添加数据
      let arr = [...this.headInfo.detailList];
      console.log(arr);
      for (let i = 0; i < productSkuList.length; i++) {
        // 去重添加
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === productSkuList[i].id) {
            contains = true;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: productSkuList[i].id,
            skuQty: 0,
            qtyPackage: 0,
            qty: 0,
            skuName: productSkuList[i].skuName,
            skuCode: productSkuList[i].skuCode,
            buyPrice: productSkuList[i].skuBuyPrice,
            buyPricePackage: productSkuList[i].skuBuyPrice,
            salePrice: productSkuList[i].skuSalePrice,
            salePricePackage: productSkuList[i].skuSalePrice,
            skuUnitId: productSkuList[i].skuUnitId,
            skuUnitName: productSkuList[i].skuUnitName,
            stockId: productSkuList[i].stockId,
            originalCodeList: [
              {
                originalCode: productSkuList[i].originalCode,
                inOriginalCode: productSkuList[i].inOriginalCode,
              },
            ],
          });
        }
      }
      this.headInfo.detailList = arr;
      this.addOriginalCode2List(
        this.headInfo.detailList.length - 1,
        this.headInfo.detailList[this.headInfo.detailList.length - 1]
          .originalCodeList
      );
    },
    toAddOriginalCode(data) {
      this.currentProduct = { ...data };
      this.showOriginalDialog = true;
      this.skuOriginalList = [{}];
    },
    addTableOriginalCode() {
      // 添加一个溯源码
      this.skuOriginalList.push({});
      this.$nextTick().then(() => {
        // 让新增的input进行聚焦
        let input = document.querySelector(
          "#skuOriginalTable tr:last-child input:last-child"
        );
        input.focus();
      });
    },

    toPrint() {
      // 组装打印数据
      let data = this.getPrintData("print");
      GjpLoaclPrint_jywz.Print(data);
    },
    // 打印设置
    toSetPrint() {
      let data = this.getPrintData("design");
      GjpLoaclPrint_jywz.Print(data);
    },
    // 获取打印的参数
    getPrintData(type) {
      let data = {
        reportName: "领用单",
        excuteType: type,
      };
      // 订单编号
      data.id = this.headInfo.id;
      // 预计归还时间
      data.recipientDays = this.headInfo.recipientDays;
      // 领用人
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.headInfo.recipientId === this.employeeList[i].id) {
          data.recipientName = this.employeeList[i].employeeName;
          break;
        }
      }

      for (let i = 0; i < this.departList.length; i++) {
        // 领用部门
        if (this.headInfo.recipientDepartId === this.departList[i].id) {
          data.recipientDepartName = this.departList[i].departName;
        }
        // 出库部门
        if (this.headInfo.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }

      // 仓库名称
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          data.stockName = this.stockList[i].stockName;
          break;
        }
      }

      // 商品列表
      let arr = [];
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        for (
          let j = 0;
          j < this.headInfo.detailList.originalCodeList.length;
          j++
        ) {
          arr.push({
            skuName: this.headInfo.detailList[i].skuName,
            skuQty: 1,
            skuUnitName: this.headInfo.detailList[i].skuUnitName,
            originalCode:
              this.headInfo.detailList.originalCodeList[j].originalCode,
          });
        }
      }
      data.productList = arr;
      return data;
    },
    addOriginalCode2List(idx, resList) {
      if (!this.headInfo.stockId) {
        this.$message.error("请先选择出库仓库！");
        return;
      }
      let arr = [...this.headInfo.detailList];
      // 此时进行检查提示
      recipientBackApi
        .checkoutSkuOriginalCode(resList, this.headInfo.stockId, arr[idx].skuId)
        .then(() => {
          // console.log(this.skuOriginalList)
          // 没有码的数据不要
          // 找到商品
          arr[idx].originalCodeList = resList;
          arr[idx].qty = resList.length;
          arr[idx].skuQty = resList.length;
          this.headInfo.detailList = arr;
          this.showOriginalDialog = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";

.head-sty {
  display: flex;
  justify-content: space-between;
}

.el-input-text {
  :deep(textarea) {
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>