import axios from "@/util/axios";

export default {
    queryList: (data) => axios.get({url: "/order/material/ledger/queryList", data: data}),

    queryAll: (data) => axios.get({url: "/order/material/ledger/queryAll", data: data}),

    findLastUseDate: (code) => axios.get({url: "/order/material/ledger/findLastUseDate", data: {code}}),

    toGenerate: (data) => axios.post({url: "/order/material/ledger/generate", data: data}),

    saveNextMtTime: (data) => axios.post({url: "/order/material/ledger/saveNextMtTime", data: data}),

    bindCode: (data,skuId) => axios.post({url: "/order/material/ledger/bindCode?skuId=" + skuId, data: data}),

    convertCode: (code) => axios.get({url: "/order/material/ledger/convertCode", data: {code}}),

    saveNextMtTime2Batch: () => axios.get({url: "/order/material/ledger/saveNextMtTime2Batch"}),
}