import axios from "../util/axios";
export default {
    /**查询列显示字段*/
    queryColumnSetting: () => axios.get({url: "/basedata/common/queryColumnSetting", }),
    /**保存*/
    saveColumnSetting: (data) => axios.post({url: "/basedata/common/saveColumnSetting", data: data}),
    /** 退出登录 */
    logout: () => axios.del({url: "/basedata/common/logout"}),
    /** 查询角色可用菜单 */
    getMenu: (systemType) => axios.get({url: "/basedata/common/getMenu", data: {systemType}}),
    /** 查询配置项 */
    findSetting: (code, merchantId = "") => axios.get({url: "/basedata/common/findSetting", data: {code, merchantId}}),
    /** 查询全部部门树形 */
    queryDepartList: () => axios.get({url: "/basedata/common/queryDepartList"}),
    /** 查询全部部门列表 */
    queryDepart: () => axios.get({url: "/basedata/common/queryDepart"}),
    /**通过编号查询客户信息*/
    queryCustomerByCode: (code) => axios.get({url: "/basedata/common/queryCustomerByCode", data: {code}}),
    /**分页查询客户信息*/
    queryCustomerList: (data) => axios.get({url: "/basedata/common/queryCustomerList", data: data}),
    /**查询全部的职员*/
    queryAllEmployee: (departCode = "") => axios.get({url: "/basedata/common/queryAllEmployee",data: {departCode}}),
    /**查询当天天气*/
    findWeather: () => axios.get({url: "/basedata/common/findWeather"}),
    /**查询客户列表*/
    queryClientList: (searchInfo) => axios.get({url: "/basedata/common/queryClientList", data: searchInfo}),
    /**查询商户列表*/
    querySupplyMerchantList: (searchInfo) => axios.get({url: "/basedata/common/querySupplyMerchantList", data: searchInfo}),
    /**查询客户列表*/
    queryMerchantList: (searchInfo) => axios.get({url: "/basedata/common/queryMerchantList", data: searchInfo}),
    // 根据职员id获取部门编码
    findByEmployeeId: (employeeId) => axios.get({url: "/basedata/common/findByEmployeeId", data: {employeeId}}),
    /**
     * alpine
     */
    /** 查询全部门店 */
    queryAll: () => axios.get({url: "/alpine/common/queryAll"}),
    // 查询商店信息
    queryStoreList:() => axios.get({url: "/alpine/common/queryStoreList"}),
    // 无条件查询所有门店列表
    queryStoreListNotCondition: () => axios.get({url: "/alpine/common/queryStoreListNotCondition"}),
    /**
     * 以下是order接口
     */
    // 生成code
    generateCode: (count, fileName) => axios.getBlob({url: "/order/common/generateCode", data: {count,fileName}, responseType: "blob", blobType: "text/plain", fileName: fileName}),
    // 查询审批失败原因
    findApprovalReason: (dataId) => axios.get({url: "/order/common/findApprovalReason", data: {dataId}}),
    // 查看一条附件
    findAttachment: (orderId) => axios.get({url: "/order/common/findAttachment", data: {orderId}}),
    // 根据skuid查询
    findBySkuId: (skuId) => axios.get({url: "/order/product/findBySkuId", data: {skuId}}),
    // 根据一个码查询另一个溯源码
    findCode: (stockId, code) => axios.get({url: "/order/common/findCode", data: {stockId, code}}),
    // 查询第一次入库时间
    findInStock: (originalCode) => axios.get({url: "/order/common/findInStock", data: {originalCode}}),
    // 首页查询预计归还信息
    queryReturnList: (data) => axios.get({url: "/order/common/queryReturnList", data: data}),
    // 审核通过
    examine: (id) => axios.get({url: "/order/common/examine", data: {id}}),
    /** 查询所有单位 */
    queryAllUnit: () => axios.get({url: "/order/common/queryAllUnit"}),
    // 查询全部供应商信息
    querySupplierList: (type = 1) => axios.get({url: "/order/common/querySupplierList", data:{type}}),
    // 根据部门id查询仓库信息
    findByDepartId: (departId) => axios.get({url: "/order/common/findByDepartId", data: {departId}}),
    // 无条件查询当前商户的所有商品
    queryProductList: (searchInfo) => axios.get({url: "/order/common/queryProductList", data: searchInfo}),
    /**查询全部商品，分页查询*/
    querySkuList: (searchInfo) => axios.get({url: "/order/product/queryProductAuthorizeList", data: searchInfo}),
    /** 按商品条码查询*/
    querySkuListByCode: (code, enabledOriginal) => axios.get({url: "/order/common/findSkuListByCode", data: {code,enabledOriginal}}),
    /**查询商品单位*/
    queryUnits: () => axios.get({url: "/order/common/queryUnits"}),
    /**导出溯源码模板*/
    exportOriginalTemp: () => axios.get({url: "/order/common/downOriginalCodeTemp"}),
    /**读取溯源码模板数据*/
    readOriginalCodeTempData: (data) => axios.post({url: "/order/common/readOriginalCode", data: data}),
    // /**树形字典*/
    queryDictionaryTree: (type, merchantId) => axios.get({url: "/basedata/common/queryDictionaryTree", data: {type, merchantId}}),
    // 字典列表
    queryDictionaryList: (type, merchantId) => axios.get({url: "/basedata/common/queryDictionaryList", data: {type, merchantId}}),
    // 首页--查询预警消息列表
    queryWarningList: (data)=> axios.get({url: `/order/common/queryWarningList`, data: data}),
    // 首页--查询预订单统计数据
    querySaleStatisticInfo: (data)=> axios.get({url: `/order/common/querySaleStatisticInfo`, data: data}),
    // 查询仓库
    queryAllStock: (bankupId) => axios.get({url: "/order/common/queryAllStock", data: {bankupId}}),
    // 头像查询
    findAvatar: () => axios.get({url: "/basedata/common/findAvatar"}),
    // 查询版本更新记录
    queryVersionInfo: () => axios.get({url: "/basedata/common/queryVersionInfo"}),
    // 查询所有出入库类型
    queryAllTypeList: (type)=> axios.get({url: `/order/common/queryAllTypeList?type=` + type}),
    // 解析url为溯源码
    transferUrl: (url, originalCodeType) => axios.get({url: "/order/common/transferUrl", data: {url, originalCodeType}}),



    /** 企业微信注入流程 signature */
    qywxSignature: (ref) => axios.get({url: `/basedata/common/getSignature?refer=` + ref}),
    /** 企业微信注入 agentSignature */
    qywxAgentSignature: (ref) => axios.get({url: `/basedata/common/getAgentSignature?refer=` + ref}),
    /** 获取系统信息 */
    queryAllService: () => axios.get({url: "/basedata/common/queryAllService"}),
    /**客户目录字典*/
    queryClientCategories: () => axios.get({url: "/basedata/common/queryClientCategories"}),
    /**商品目录字典*/
    queryProductCategories: () => axios.get({url: "/basedata/common/queryProductCategories"}),

    /**查询客户详情*/
    findClientById: (id) => axios.get({url: "/basedata/common/findClientById", data: {id}}),
    /**查询客户详情*/
    findClientByErpId: (erpId) => axios.get({url: "/basedata/common/findClientByErpId", data: {erpId}}),
    /**修改密码*/
    changePassword: (passwordForm) => axios.post({url: "/basedata/common/changePassword", data: passwordForm}),
    /**查询全部角色*/
    queryAllRoles: () => axios.get({url: "/basedata/common/queryAllRoles"}),
    /** 查询全部商品，只返回基础信息*/
    queryAllProductSimple: () => axios.get({url: "/basedata/common/queryAllProductSimple"}),
    /** 分页查询商品，只返回基础信息*/
    queryProductSkuSimple: (searchInfo) => axios.get({url: "/basedata/common/queryProductSkuSimple", data: searchInfo}),

    /** 查询全部客户，只返回基础信息*/
    queryAllClients: () => axios.get({url: "/basedata/common/queryAllClients"}),
    /** 获取erp关联配置*/
    databaseConfig: () => axios.get({url: "/basedata/common/databaseConfig"}),
    /** 获取erp关联配置*/
    getUserInfo: () => axios.get({url: "/basedata/common/getUserInfo"}),
    /** 切换角色 */
    switchRole: (roleId) => axios.get({url: "/basedata/common/switchRole", data: {roleId}}),

    // /** 行政区划字典 */
    // queryAdministrativeArea: () => axios.get({url: "/basedata/common/queryAdministrativeArea"}),
    // /** 查询所有职员 */
    // queryAllEmployees: () => axios.get({url: "/basedata/common/queryAllEmployees"}),
    // /** 查询职员，分页*/
    // querySimpleEmployees: (data) => axios.get({url: "/basedata/common/querySimpleEmployees", data: data}),
    // /**查询系统配置*/
    // getSetting: () => axios.get({url: "/basedata/sys/setting/getSetting"}),
    //
    // /** 企业微信注入流程 signature */
    // qywxSignature: (ref) => axios.get({url: `/basedata/common/getSignature?refer=` + ref}),
    // /** 企业微信注入 agentSignature */
    // qywxAgentSignature: (ref) => axios.get({url: `/basedata/common/getAgentSignature?refer=` + ref}),
    // /** 获取系统信息 */
    // queryAllService: () => axios.get({url: "/basedata/common/queryAllService"}),
    // /**客户目录字典*/
    // queryClientCategories: () => axios.get({url: "/basedata/common/queryClientCategories"}),
    // /**商品目录字典*/
    // queryProductCategories: () => axios.get({url: "/basedata/common/queryProductCategories"}),
    // /**查询客户列表*/
    // queryClientList: (searchInfo) => axios.get({url: "/basedata/common/queryClientList", data: searchInfo}),
    // /**查询客户详情*/
    // findClientById: (id) => axios.get({url: "/basedata/common/findClientById", data: {id}}),
    // /**查询客户详情*/
    // findClientByErpId: (erpId) => axios.get({url: "/basedata/common/findClientByErpId", data: {erpId}}),
    // /**修改密码*/
    // changePassword: (passwordForm) => axios.post({url: "/basedata/common/changePassword", data: passwordForm}),
    // /**查询全部角色*/
    // queryAllRoles: () => axios.get({url: "/basedata/common/queryAllRoles"}),
    // /**查询全部部门*/
    // queryDeparts: () => axios.get({url: "/basedata/common/queryDeparts"}),
    // /** 查询全部商品，只返回基础信息*/
    // queryAllProductSimple: () => axios.get({url: "/basedata/common/queryAllProductSimple"}),
    // /** 分页查询商品，只返回基础信息*/
    // queryProductSkuSimple: (searchInfo) => axios.get({url: "/basedata/common/queryProductSkuSimple", data: searchInfo}),
    // /**查询商品单位*/
    // queryUnits: () => axios.get({url: "/basedata/common/queryUnits"}),
    // /** 查询全部客户，只返回基础信息*/
    // queryAllClients: () => axios.get({url: "/basedata/common/queryAllClients"}),
    // /** 获取erp关联配置*/
    // databaseConfig: () => axios.get({url: "/basedata/common/databaseConfig"}),
    //
    // /**获取自定义流程*/
    // queryAllFlows: () => axios.get({url: "/basedata/common/process/queryAllFlows"}),

    // /** 查询职员*/
    // findEmployee: (id) => axios.get({url: "/basedata/common/findEmployee", data: {id}}),
    // /** 通过erpId查询职员*/
    // findEmployeeByErpId: (id) => axios.get({url: "/basedata/common/findEmployeeByErpId", data: {id}}),

}

