<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="write" @click="toEditStock({})" el-type="success">新建</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <el-input v-model="searchInfo.searchKey" placeholder="仓库名称"/>
      </search-condition>
      <table-list>
        <el-table stripe :data="dataList" height="100%">
          <el-table-column align="center" label="序号" width="60px" type="index"/>
          <el-table-column align="center" label="仓库名称" prop="stockName">
            <template v-slot="{row}">
              <table-button el-type="primary" @click="toShowViewStockPro(row.id)">{{ row.stockName }}</table-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="仓库地址" prop="stockAddress"/>
          <el-table-column align="center" label="所属部门" v-if="needDepartId">
            <template v-slot="{row}">
              <dictionary :disabled="true" :dictionaries="departList" v-model="row.bankupId" dict-code="id" dict-name="departName"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="150px">
            <template v-slot="{row}">
              <table-button v-if="originalSwitch" el-type="primary" @click="toShowStorage(row)">货位管理</table-button>
              <table-button type="write" @click="toEditStock(row)">编辑</table-button>
              <table-button type="delete" el-type="danger" message="是否确认删除此数据" @click="deleteData(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
    </list-page>
  </div>
  <el-dialog v-model="showDialog" title="仓库档案" width="40%">
    <el-form label-width="70px">
      <el-form-item label="仓库名称">
        <el-input v-model="currentStock.stockName"/>
      </el-form-item>
      <el-form-item label="仓库地址">
        <el-input v-model="currentStock.stockAddress"/>
      </el-form-item>
      <el-form-item label="所属部门" v-if="needDepartId">
        <el-tree-select v-model="currentStock.bankupId" :data="departList"  check-strictly :render-after-expand="false" placeholder="请选择所在部门" :props="{children: 'children', label: 'departName'}" node-key="id"/>
      </el-form-item>
<!--      <el-form-item label="货架管理" v-if="originalSwitch">-->
<!--        -->
<!--      </el-form-item>-->
    </el-form>
    <div style="text-align: right">
      <page-button @click="showDialog = false">取消</page-button>
      <page-button type="write" @click="saveStock" el-type="primary">保存</page-button>
    </div>
  </el-dialog>
  <el-dialog v-model="showStorage" width="40%" title="货位管理">
    <div style="display: flex;justify-content: flex-end">
      <div style="margin-bottom: 10px">
        <page-button el-type="primary" @click="showAddStorage = true">添加</page-button>
      </div>
    </div>
    <dialog-table>
      <el-table stripe :data="storageList" height="100%">
        <el-table-column align="center" label="序号" width="60px" type="index"/>
        <el-table-column align="center" label="货位名称" prop="name"/>
        <el-table-column label="操作" :align="'center'">
          <template v-slot="{row}">
            <table-button el-type="primary" @click="toEditStorage(row)">编辑</table-button>
            <table-button type="delete" el-type="danger" message="是否确认删除此数据" @click="deleteStorage(row)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
  </el-dialog>
  <el-dialog v-model="showAddStorage" @closed="storageRow = {}" width="40%" title="添加货位">
    <el-form label-width="70px">
      <el-form-item label="货位名称">
        <el-input v-model="storageRow.name"/>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <page-button @click="showDialog = false">取消</page-button>
      <page-button type="write" @click="saveStorage" el-type="primary">保存</page-button>
    </div>
  </el-dialog>


  <el-dialog width="60%" v-model="showStockPro" title="仓库明细">
    <dialog-table>
      <product-stock-component :stock-id="toShowStockProId" />
    </dialog-table>
  </el-dialog>
</template>

<script>
import ProductStockComponent from "@/views/main/stock/stockManage/ProductStockComponent.vue";
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import commonApi from "@/api/commonApi";
import stockApi from "@/api/main/stock/stockApi";
import Dictionary from "@/components/h5/Dictionary";
import PageHeader from "@/components/h5/layout/PageHeader";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
export default {
  name: "StockList",
  components: {
    TableButton,
    PageButton,
    SearchCondition, DialogTable,
    ListPage, TableList, FormPage, Dictionary, PageHeader, ProductStockComponent
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      showAddStorage: false,
      currentStock: {
        stockName: "",
        stockAddress: "",
        bankupId: "",
      },
      storageList: [],
      showDialog: false,
      // 是否需要部门id
      needDepartId: false,
      departList: [],
      originalSwitch: false,
      showStorage: false,
      storageRow: {},
      stockId: "",
      toShowStockProId: "", // 也是stockId
      showStockPro: false,
    };
  },
  created() {
    commonApi.findSetting("STOCK_BANKUP").then(data => {
      this.needDepartId = data.settingValue === "1";
      if (this.needDepartId) {
        // 查询部门
        stockApi.queryDepartListTree().then(list => this.departList = list);
      }
    });
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
  },
  methods: {
    toShowViewStockPro(stockId){
      this.toShowStockProId = stockId;
      console.log(this.toShowStockProId)
      this.showStockPro = true;
    },
    saveStorage(){
      this.storageRow.stockId = this.stockId;
      stockApi.saveStorage(this.storageRow).then(() =>{
        this.queryStorage(this.stockId);
        this.showAddStorage = false;
      });
    },
    toEditStorage(data){
      this.storageRow = data;
      this.showAddStorage = true;
    },
    deleteStorage(row){
      stockApi.deleteStorage(row.id).then(() => this.queryStorage(this.stockId));
    },
    queryStorage(id){
      stockApi.queryStorage(id).then(list => {
        this.storageList = list;
      })
    },
    toShowStorage(row){
      this.showStorage = true;
      this.queryStorage(row.id);
      this.stockId = row.id;
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      stockApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toEditStock(data) {
      this.currentStock = {...data};
      this.showDialog = true;
    },
    deleteData(data) {
      stockApi.delete(data.id).then(() => this.queryList());
    },
    saveStock() {
      stockApi.save(this.currentStock).then(() => {
        this.queryList();
        this.showDialog = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/el-tables";
</style>