<template>
  <!-- dialog封装 -->
  <div>
    <el-dialog :append-to-body="true" v-model="showEdit" :show-close="false" width="550px" :close-on-click-modal="false"
               class="remark-dialog">
      <!-- 自定义header -->
      <template #header>
        <div class="dialog-header">
          <div class="header-left">
            <el-icon class="header-icon">
              <Document />
            </el-icon>
            <span class="header-title">{{ title }}</span>
          </div>
          <el-button class="close-btn" link @click="showEdit = false">
            <el-icon>
              <Close />
            </el-icon>
          </el-button>
        </div>
      </template>

      <div class="dialog-content">
        <div class="input-section">
          <!-- 输入区域标题 -->
          <div class="section-title" v-if="showContent">
            <div class="title-dot"></div>
            <span><slot name="title" /></span>
          </div>

          <slot name="content" />

          <!-- 提示信息区域 -->
          <div class="info-box" v-if="warnTitleList.length > 0">
            <div class="info-item" v-for="(it,idx) in warnTitleList" :key="idx">
              <el-icon>
                <InfoFilled />
              </el-icon>
              <span>{{ it.warnTitle }}</span>
            </div>
          </div>
        </div>
      </div>


      <div style="display: flex; justify-content: flex-end;margin-top: 15px;">
        <page-button el-type="info" class="cancel-btn" @click="showEdit = false">
          <el-icon size="16">
            <Close />
          </el-icon>
          <span>取 消</span>
        </page-button>
        <page-button el-type="primary" class="confirm-btn" @click="saveData">
          <el-icon size="16">
            <Check />
          </el-icon>
          <span>确认并开始</span>
        </page-button>
      </div>


    </el-dialog>

  </div>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import { Document, InfoFilled, Warning, Close, Check } from '@element-plus/icons-vue'
import TableButton from "@/components/h5/button/TableButton.vue";
import {watch} from "vue";

export default {
  props: {
    modelValue: {default: false},
    // 头部信息
    title: {default: ""},
    // 提示信息
    warnTitleList: {default: () => []},
    // 核心内容
    showContent: {default: true},
  },
  emits: ["saveData","update:modelValue"],
  name: "",
  components: {
    TableButton,
    PageButton, Document, InfoFilled, Warning, Close, Check
  },
  data() {
    return {
      showEdit: false,
    };
  },
  created() {
    watch(() => this.modelValue, () => {
      this.showEdit = this.modelValue
      this.$emit("update:modelValue", "")
    })
  },
  methods: {
    saveData(){
      this.$emit("saveData")
    },
  }
};
</script>

<style lang="scss" scoped>
.remark-dialog {
  :deep(.el-dialog) {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);

    .el-dialog__header {
      margin: 0;
      padding: 0;
    }

    .el-dialog__body {
      padding: 0 24px 24px;
    }

    .el-dialog__footer {
      padding: 16px 24px;
      background: #f8fafc;
      border-top: 1px solid #e5e7eb;
    }
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #f0f7ff;
  border-bottom: 1px solid #e6f0fd;

  .header-left {
    display: flex;
    align-items: center;
    gap: 12px;

    .header-icon {
      font-size: 24px;
      color: #409EFF;
      background: white;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(64, 158, 255, 0.15);
    }

    .header-title {
      font-size: 18px;
      font-weight: 600;
      color: #1f2937;
      letter-spacing: 0.5px;
    }
  }

  .close-btn {
    font-size: 20px;
    color: #64748b;
    transition: all 0.3s ease;

    &:hover {
      color: #ef4444;
      transform: rotate(90deg);
    }
  }
}

.dialog-content {
  padding-top: 24px;

  .input-section {
    .section-title {
      display: flex;
      align-items: center;
      gap: 8px;
      //margin: 8px 0;
      margin: 17px 0 -10px 0;

      .title-dot {
        width: 4px;
        height: 16px;
        background: #409EFF;
        border-radius: 2px;
      }

      span {
        font-size: 15px;
        font-weight: 500;
        color: #1f2937;
      }
    }

    .input-area {
      :deep(.el-textarea) {
        .el-textarea__inner {
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #e5e7eb;
          font-size: 14px;
          line-height: 1.6;
          transition: all 0.3s ease;

          &:hover {
            border-color: #409EFF;
          }

          &:focus {
            border-color: #409EFF;
            box-shadow: 0 0 0 3px rgba(64, 158, 255, 0.1);
          }
        }
      }
    }

    .info-box {
      margin-top: 16px;
      padding: 12px 16px;
      background: #fafafa;
      border-radius: 8px;
      border-left: 4px solid #409EFF;

      .info-item {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .el-icon {
          font-size: 16px;
          color: #409EFF;
        }

        span {
          font-size: 13px;
          color: #64748b;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .el-button {
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.3s ease;

    .el-icon {
      font-size: 16px;
    }

    &.cancel-btn {
      border-color: #e5e7eb;
      color: #64748b;

      &:hover {
        background: #f1f5f9;
        border-color: #cbd5e1;
      }
    }

    &.confirm-btn {
      background: #409EFF;
      border-color: #409EFF;

      &:hover {
        background: #66b1ff;
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(64, 158, 255, 0.2);
      }
    }
  }
}
</style>